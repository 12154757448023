import axios from "axios";
import { Auth } from "aws-amplify";

export const isUserAuthenticated = async () => {
  try {
    await Auth.currentAuthenticatedUser();
  } catch (err) {
    sessionStorage.clear();
    window.location.href = "/";
  }
};

export const getAccountData = async (session) => {
  sessionStorage.setItem("jwt", session.signInUserSession.idToken.jwtToken);

  try {
    let response = await axios({
      method: "get",
      url: process.env.REACT_APP_ACCOUNT_ENDPOINT,
      headers: {
        Authorization: session.signInUserSession.idToken.jwtToken,
      },
    });

    try {
      sessionStorage.setItem(
        "company",
        JSON.stringify(JSON.parse(response.data.body).company)
      );
      sessionStorage.setItem(
        "user",
        JSON.stringify(JSON.parse(response.data.body).user)
      );
      sessionStorage.setItem(
        "permissions",
        JSON.stringify(JSON.parse(response.data.body).permissions)
      );
    } catch (err) {
      isUserAuthenticated();
    }
  } catch (err) {
    console.log(err);
  }
};

export const getWarehouseData = async (params = {}) => {
  const func = async () => {
    let warehouses;
    const response = await axios({
      method: "get",
      url: process.env.REACT_APP_WAREHOUSE_ENDPOINT,
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });

    try {
      warehouses = JSON.parse(response.data.body).warehouses;
      sessionStorage.setItem("warehouses", JSON.stringify(warehouses));
    } catch (err) {
      isUserAuthenticated();
    }
    return warehouses;
  };

  if (params.force) {
    return await func();
  } else if (sessionStorage.getItem("warehouses")) {
    return JSON.parse(sessionStorage.getItem("warehouses"));
  } else {
    return await func();
  }
};

export const getEmployeeData = async (params = {}) => {
  const func = async () => {
    let employees;
    const response = await axios({
      method: "get",
      url: process.env.REACT_APP_EMPLOYEE_ENDPOINT,
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });

    try {
      employees = JSON.parse(response.data.body).employees;
      sessionStorage.setItem("employees", JSON.stringify(employees));
    } catch (err) {
      isUserAuthenticated();
    }
    return employees;
  };

  if (params.force) {
    return await func();
  } else if (sessionStorage.getItem("employees")) {
    return JSON.parse(sessionStorage.getItem("employees"));
  } else {
    return await func();
  }
};

export const getArticleData = async (params = {}) => {
  const func = async () => {
    let articles;
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_ARTICLE_ENDPOINT}${
        params.query ? params.query : ""
      }`,
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });

    try {
      articles = JSON.parse(response.data.body).articles;
      articles.map((e) => (e.price = parseFloat(e.price).toFixed(2)));
      sessionStorage.setItem(
        params.query ? "articleSelected" : "articles",
        params.query ? JSON.stringify(articles[0]) : JSON.stringify(articles)
      );
    } catch (err) {
      isUserAuthenticated();
    }

    return articles;
  };

  if (params.force || params.query) {
    return await func();
  } else if (sessionStorage.getItem("articles")) {
    return JSON.parse(sessionStorage.getItem("articles"));
  } else {
    return await func();
  }
};

export const getMovementData = async (params = {}) => {
  const func = async () => {
    let movements;
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_MOVEMENT_ENDPOINT}${
        params.query ? params.query : ""
      }`,
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });

    try {
      movements = JSON.parse(response.data.body).movements;
      sessionStorage.setItem("movements", JSON.stringify(movements));
    } catch (err) {
      isUserAuthenticated();
    }
    return movements;
  };

  if (params.force || params.query) {
    return await func();
  } else if (sessionStorage.getItem("movements")) {
    return JSON.parse(sessionStorage.getItem("movements"));
  } else {
    return await func();
  }
};

export const getPOSData = async (params = {}) => {
  const func = async () => {
    let poss;
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_POS_ENDPOINT}?returnAllPOS=true`,
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });

    try {
      poss = JSON.parse(response.data.body).pos;
      sessionStorage.setItem("poss", JSON.stringify(poss));
    } catch (err) {
      isUserAuthenticated();
    }
    return poss;
  };

  if (params.force) {
    return await func();
  } else if (sessionStorage.getItem("poss")) {
    return JSON.parse(sessionStorage.getItem("poss"));
  } else {
    return await func();
  }
};

export const getOrderData = async (params = {}) => {
  const func = async () => {
    let orders;
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_ORDER_ENDPOINT}${
        params.query ? params.query : ""
      }`,
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });

    try {
      orders = JSON.parse(response.data.body).orders;
      sessionStorage.setItem("orders", JSON.stringify(orders));
    } catch (err) {
      isUserAuthenticated();
    }
    return orders;
  };

  if (params.force || params.query) {
    return await func();
  } else if (sessionStorage.getItem("orders")) {
    return JSON.parse(sessionStorage.getItem("orders"));
  } else {
    return await func();
  }
};
