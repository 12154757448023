import React, { useEffect, useState } from "react";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Transition } from "react-transition-group";

// Components
import Warehouse from "./Warehouse";
import WarehouseEdition from "./WarehouseEdition";

// Helpers
import { perfectSpaceForWarehouse } from "../_utils/helper";
import { getWarehouseData } from "../_utils/data";
import IncentivateWarehouseCreation from "./_IncentivateWarehouseCreation";

const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 115px;
  align-items: center;
  justify-content: flex-end;
  padding: 0 40px;
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
`;

const WarehousesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 100vh;
`;

const WarehouseContainer = styled.div`
  cursor: pointer;
  width: 41%;
  max-width: 550px;
  height: 395px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  & figure {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 300px;
    background: #dfe2e6;
  }
  & figure img {
    object-fit: cover;
  }
  & div {
    padding: 15px;
  }
  @media (max-width: 890px) {
    width: 90%;
    max-width: 100%;
  }
  @media (min-width: 1250px) {
    width: 30%;
  }
  margin: 10px;
  transition: margin 0.4s ease-in;
  transition: opacity 0.4s ease-in;
  transition-delay: 0.7s;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
`;

const WarehouseName = styled.p`
  font-size: 15px;
  color: black;
  font-weight: 500;
`;

const WarehouseAddress = styled.p`
  color: #707070;
  padding: 5px 0;
  font-size: 14px; ;
`;

export default function WarehouseController() {
  const [selectedWarehouseData, setSelectedWarehouseStatus] = useState(null);
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:warehouseId`}>
        <Warehouse data={selectedWarehouseData}></Warehouse>
      </Route>
      <Route path={process.env.REACT_APP_WAREHOUSES_ROUTE}>
        <Warehouses setWarehouseData={setSelectedWarehouseStatus}></Warehouses>
      </Route>
    </Switch>
  );
}

function Warehouses(props) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const warehousesPermissions =
    JSON.parse(sessionStorage.getItem("permissions")).find(
      (e) => e.area === "Bodegas"
    ) !== undefined;
  const warehousesToRender = [];
  const warehouseClassName = "warehouse";
  const parentId = "warehouses_container";

  const [newWarehouseActivated, setNewWarehouseStatus] = useState(false);
  const [newWarehouseCreated, setWarehouseCreationState] = useState(false);
  const [warehousesExecution, setWarehousesExecutionState] = useState(0);
  const [appear, setAppearState] = useState(false);
  const [warehouses, setWarehousesState] = useState([]);

  const listener = () => {
    perfectSpaceForWarehouse(warehouseClassName, parentId);
  };

  useEffect(() => {
    perfectSpaceForWarehouse(warehouseClassName, parentId);
    window.addEventListener("resize", listener);
    setAppearState(true);

    if (
      warehousesPermissions &&
      (warehousesExecution === 0 || newWarehouseCreated)
    ) {
      getWarehouseData({ force: true }).then((response) => {
        setWarehousesState(response);
        setWarehousesExecutionState(warehousesExecution + 1);
      });

      setWarehouseCreationState(false, perfectSpaceForWarehouse);
    }

    if (!warehousesPermissions) {
      history.push({
        pathname: process.env.REACT_APP_MOVEMENTS_ROUTE,
      });
    }

    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [
    history,
    newWarehouseCreated,
    warehousesExecution,
    warehousesPermissions,
  ]);

  const warehouseGenerator = (warehouse, i) => {
    warehousesToRender.push(
      <Transition key={i} timeout={0} in={appear}>
        {(state) => {
          return (
            <WarehouseContainer
              state={state}
              className="warehouse"
              onClick={() => {
                props.setWarehouseData(warehouse);
                history.push({
                  pathname: `${url}/${warehouse.id}`,
                });
              }}
            >
              <figure>
                <img
                  alt="Static mapbox map"
                  src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+323946(${warehouse.longitude},${warehouse.latitude})/${warehouse.longitude},${warehouse.latitude},15,0.00,0.00/500x500@2x?access_token=pk.eyJ1IjoicmxlZ29ycmV0YSIsImEiOiJjaXYzZDBwY2YwMHprMnpxbm93aW5ubDkxIn0.XvwIpNBJK-ZS3VsiTRNv4w`}
                />
              </figure>
              <div>
                <WarehouseName>{warehouse.name}</WarehouseName>
                <WarehouseAddress>{warehouse.address}</WarehouseAddress>
              </div>
            </WarehouseContainer>
          );
        }}
      </Transition>
    );
  };

  for (let i = 0; i < warehouses.length; i++) {
    warehouseGenerator(warehouses[i], i);
  }

  if (warehouses.length === 0) {
    warehousesToRender.push(
      <IncentivateWarehouseCreation
        key={0}
        newWarehouse={() => setNewWarehouseStatus(!newWarehouseActivated)}
      />
    );
  }

  return (
    <MainContainer>
      {newWarehouseActivated && (
        <WarehouseEdition
          title="Nueva bodega"
          close={() => {
            setNewWarehouseStatus(!newWarehouseActivated);
          }}
          newWarehouseCreated={() => setWarehouseCreationState(true)}
          setWarehousesState={setWarehousesState}
        ></WarehouseEdition>
      )}
      <HeaderContainer>
        <button onClick={() => setNewWarehouseStatus(!newWarehouseActivated)}>
          Añadir
        </button>
      </HeaderContainer>
      <WarehousesContainer id="warehouses_container">
        {warehousesToRender}
      </WarehousesContainer>
    </MainContainer>
  );
}
