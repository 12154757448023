import React from "react";
import styled from "styled-components";

const MainContainer = styled.div`
  width: 100%;
  border: 1px solid #e9eef5;
  background: #fbfdff;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 15px;
`;

const TableTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #0e2c5e;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
`;

const TableCell = styled.p`
  color: ${(props) => (props.color ? props.color : "#8894ac")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "500")};
  display: flex;
  flex-direction: row;
  width: ${(props) => props.width};
`;

const TableCellTypeImage = styled.figure`
  width: ${(props) => props.width};
  overflow: hidden;
  img {
    border-radius: 5px;
    background: #e9eef5;
    border: 1px solid #e9eef5;
    width: 70%;
    height: 70%;
  }
`;

const TableCellTypeColumn = styled.div`
  width: ${(props) => props.width};
  display: flex;
  flex-direction: column;
`;

function HeaderRow() {
  return (
    <TableRow>
      <TableCell width="15%">Foto</TableCell>
      <TableCell width="60%">Nombre</TableCell>
      <TableCell width="25%">Ventas</TableCell>
    </TableRow>
  );
}

function ProductsRows(props) {
  const rows = [];
  for (let i = 0; i < props.sellers?.length; i++) {
    rows.push(
      <TableRow>
        <TableCellTypeImage width="15%">
          <img src="/profile.svg" alt="Icon"></img>
        </TableCellTypeImage>
        <TableCellTypeColumn width="60%">
          <TableCell color="#0E2C5E">{props.sellers[i].fullname}</TableCell>
          <TableCell fontSize="12px">
            {props.sellers[i].orders
              ? `${props.sellers[i].orders} ventas`
              : "--"}
          </TableCell>
        </TableCellTypeColumn>
        <TableCell width="25%" fontWeight="500" color="#5590ff">
          {props.sellers[i].totalSales
            ? `${props.sellers[i].totalSales.toFixed(2)}`
            : "--"}
        </TableCell>
      </TableRow>
    );
  }

  return rows;
}

export default function Sellers(props) {
  return (
    <MainContainer>
      <TableTitle>Reporte de vendedores</TableTitle>
      <HeaderRow></HeaderRow>
      <ProductsRows sellers={props.sellers}></ProductsRows>
    </MainContainer>
  );
}
