const NavMenuItems = [
  {
    name: "Inventario",
    items: [
      {
        path: process.env.REACT_APP_ARTICLES_ROUTE,
        name: "Articulos",
      },
      {
        path: process.env.REACT_APP_WAREHOUSES_ROUTE,
        name: "Bodegas",
      },
      {
        path: process.env.REACT_APP_MOVEMENTS_ROUTE,
        name: "Movimientos",
      },
    ],
  },
  {
    name: "Venta física",
    items: [
      {
        path: process.env.REACT_APP_POSS_ROUTE,
        name: "Puntos de venta",
      },
      {
        path: process.env.REACT_APP_ORDERS_ROUTE,
        name: "Órdenes",
      },
    ],
  },
];

export default NavMenuItems;
