import React, { useEffect, useState, useCallback } from "react";
import styled, { css } from "styled-components";
import axios from "axios";
import { listenerEnter } from "./_utils/helper";

import CustomInput from "./CustomInput";

const MainContainer = styled.div`
  width: 435px;
  max-width: 100%;
  padding: 35px;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => (props.color ? props.color : "white")};
  position: relative;
  overflow: hidden;

  ${(props) =>
    props.error === true &&
    css`
      animation-name: errorAlert;
      animation-duration: 0.3s;
      animation-iteration-count: 3;
    `}
`;

const SectionTitle = styled.p`
  font-size: ${(props) => (props.type === "SUCCESS" ? "20px" : "17px")};
  font-weight: ${(props) => (props.type === "SUCCESS" ? 600 : 500)};
  text-align: center;
  margin-bottom: 20px;
  color: ${(props) => (props.color ? props.color : null)};
  color: ${(props) => (props.type === "SUCCESS" ? "white" : null)};
`;

const SubmitButton = styled.input`
  align-self: flex-start;
  margin-top: 15px;
  padding: 11px 20px;
  border-radius: 100px;
  background: ${(props) => (props.cssType === "ERROR" ? "white" : "#323946")};
  color: ${(props) => (props.cssType === "ERROR" ? "#323946" : "white")};
  border: none;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  outline: none;
  cursor: pointer;
`;

const Loading = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.35);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingImage = styled.figure`
  width: 50px;
  height: 50px;
  & > img {
    width: 100%;
  }
`;

const Description = styled.p`
  font-size: 16px;
  color: white;
  font-weight: 400;
  max-width: 350px;
  text-align: center;
  margin-bottom: 15px;
`;

export default function PromotionalCode(props) {
  let URLCode;
  if (props.location.search.includes("code=")) {
    URLCode = props.location.search.replace("?code=", "");
  }

  const [stepSelected, setSectionSelected] = useState(1);
  const [coupon, setCouponState] = useState(null);

  let render;
  switch (stepSelected) {
    case 1:
      render = (
        <InsertCode
          setSectionSelected={(step) => setSectionSelected(step)}
          setCouponState={setCouponState}
          saveCode={props.saveCode}
          code={URLCode}
        ></InsertCode>
      );
      break;
    case 2:
      render = <Error setSectionSelected={() => setSectionSelected(1)}></Error>;
      break;
    case 3:
      render = <Success coupon={coupon}></Success>;
      break;
    default:
      break;
  }

  return <React.Fragment>{render}</React.Fragment>;
}

export function InsertCode(props) {
  const [loadingIsActive, setLoadingState] = useState(false);

  const submitForm = useCallback(
    async (event) => {
      if (event) {
        event.preventDefault();
      }
      setLoadingState(true);

      let response = await axios.get(
        `${process.env.REACT_APP_COUPON_ENDPOINT}?code=${
          document.getElementById("promotional_code").value
        }`
      );
      if (response.data.statusCode === 200) {
        props.setCouponState(JSON.parse(response.data.body));
        try {
          props.saveCode(document.getElementById("promotional_code").value);
        } catch (err) {
          // TODO: Fix problem (right now it throws this error when code was implemented via query string parameters)
          props.saveCode(props.code);
        }
        props.setSectionSelected(3);
      } else {
        props.setSectionSelected(2);
      }
    },
    [props]
  );

  useEffect(() => {
    if (props.code) {
      document.getElementById("promotional_code").value = props.code;
      submitForm();
    }
  });

  const [error, setError] = useState(false);
  useEffect(() => {
    setTimeout(() => setError(false), 1000);
  }, [error]);

  useEffect(() => {
    const listener = (event) => {
      listenerEnter({
        event: event,
        array: [document.getElementById("promotional_code").value],
        actionSuccess: () => submitForm(event),
        actionFailure: () => setError(true),
      });
    };
    document
      .getElementById("container_code")
      .addEventListener("keydown", listener);
  }, [submitForm]);

  return (
    <MainContainer id="container_code" error={error}>
      <SectionTitle>Código promocional (Opcional)</SectionTitle>
      <form onSubmit={async (event) => await submitForm(event)}>
        <CustomInput
          id="promotional_code"
          placeholder="Código"
          type="text"
          required={true}
        ></CustomInput>
        <SubmitButton type="submit" value="Validar"></SubmitButton>
      </form>
      {loadingIsActive && (
        <Loading>
          <LoadingImage>
            <img src="/loading.gif" alt="Loading"></img>
          </LoadingImage>
        </Loading>
      )}
    </MainContainer>
  );
}

export function Error(props) {
  return (
    <MainContainer color="#323946">
      <SectionTitle color="white">
        Lo sentimos, <br></br>el código ingresado es inválido
      </SectionTitle>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          props.setSectionSelected();
        }}
      >
        <SubmitButton
          cssType="ERROR"
          type="submit"
          value="Reintentar"
        ></SubmitButton>
      </form>
    </MainContainer>
  );
}

export function Success(props) {
  return (
    <MainContainer color="#5590FF">
      <SectionTitle type="SUCCESS">
        <span role="img" aria-label="Congrats">
          🎉
        </span>{" "}
        {props.coupon.coupon.description}{" "}
        <span role="img" aria-label="Congrats">
          🎉
        </span>
      </SectionTitle>
      <Description>
        Completa tu registro para hacer válida tu promoción.
      </Description>
    </MainContainer>
  );
}
