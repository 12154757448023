import React, { useState } from "react";
import styled from "styled-components";

import TableInput from "../../SmartInputs/TableInput.js";
import { ContinueButton, Description } from "./styles";

// Helpers
import { getUnitMeasure } from "../helper";
import { getArticleData } from "../../_utils/data";

const Table = styled.div`
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 30px 0;
  padding: 10px 0;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
`;

const TableRow = styled.div`
  position: relative;
  width: 100%;
  height: 54px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-left: 10px;
  flex-shrink: 0;
  &:hover {
    & button {
      display: block;
    }
  }
  & p {
    white-space: nowrap;
    overflow: auto;
  }
`;

const Delete = styled.figure`
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin: 0 15px 0 25px;
`;

const InputContainer = styled.div`
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90%;
  position: relative;
  & input {
    width: 50px;
    height: 90%;
    padding-left: 15px;
    border: 1px solid #99a7bf;
  }
`;

const TableRowRight = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
`;

export default (props) => {
  const [relatedArticles, setRelatedArtiles] = useState(
    props.data.related_articles ? props.data.related_articles : []
  );
  const render = [];

  if (relatedArticles.length !== 0) {
    getArticleData().then((articles) => {
      if (relatedArticles[0].article === undefined) {
        // get related articles
        let articleData = JSON.parse(sessionStorage.getItem("articleSelected"));
        let temp = [];
        relatedArticles.forEach((e, i) => {
          if (
            articles.filter((el) => el.id === e.related_article_id).length !== 0
          ) {
            temp.push({
              article: articles.find(
                (element) =>
                  element.id ===
                  articleData.related_articles[i].related_article_id
              ),
              quantity: e.quantity,
            });
          }
        });
        setRelatedArtiles(temp);
      }
    });
  }
  props.data.related_articles = relatedArticles;

  relatedArticles.forEach((element, index) => {
    if (element) {
      render.push(
        <TableRow key={index}>
          <p>{element.article ? element.article.name : element.name}</p>
          <TableRowRight>
            <InputContainer>
              <input
                type="number"
                min="0"
                placeholder="0"
                value={element.quantity}
                onChange={(event) => {
                  let related_articles = props.data.related_articles;
                  for (let i = 0; i < related_articles.length; i++) {
                    let relArticleID = related_articles[i].article
                      ? related_articles[i].article.id
                      : related_articles[i].related_article_id;
                    let elementID = element.article
                      ? element.article.id
                      : element.related_article_id;
                    if (relArticleID === elementID) {
                      related_articles[i].quantity = event.target.value;
                    }
                  }
                  props.saveData({ ...props.data, related_articles });
                }}
              ></input>
              <p>
                {element !== undefined
                  ? getUnitMeasure(element.unit_measure)
                  : getUnitMeasure(props.data.name)}
              </p>
            </InputContainer>
            <Delete
              onClick={() => {
                let related_articles = props.data.related_articles;
                let related_articles_eliminated =
                  props.data.related_articles_eliminated;
                let filteredArticles = [];

                for (let i = 0; i < related_articles.length; i++) {
                  if (related_articles[i].article.id !== element.article.id) {
                    filteredArticles.push(related_articles[i]);
                  } else {
                    related_articles_eliminated.push(related_articles[i]);
                  }
                }
                props.saveData({
                  ...props.data,
                  related_articles: filteredArticles,
                });
              }}
            >
              <img src="/quit.svg" alt="Delete"></img>
            </Delete>
          </TableRowRight>
        </TableRow>
      );
    }
  });

  return (
    <React.Fragment>
      <Description>
        Por cada <span>{getUnitMeasure(props.data.unit_measure)}</span> de{" "}
        <span>{props.data.name ? props.data.name : "---"}</span> que se registre
        como {props.fictional} {props.fictional ? "salida" : "entrada"}, serán
        extraidos los siguientes artículos.
      </Description>
      <TableInput
        type="text"
        popupType="PRODUCT_SEARCH"
        design={"v2"}
        allBorders
        textCenter
        inputValue={"Buscar artículo por nombre o SKU"}
        saveValue={(value) => {
          const articleData = {
            article: value,
            quantity: 0,
          };
          let related_articles = props.data.related_articles;
          if (Array.isArray(related_articles)) {
            let flag = true;
            related_articles.forEach((element) => {
              if (element.id === articleData.article.id) {
                flag = false;
              }
            });
            if (flag && articleData.article.type !== "FICTIONAL") {
              if (
                relatedArticles.filter((e) =>
                  e.related_article_id
                    ? e.related_article_id === articleData.article.id
                    : e.article.id === articleData.article.id
                ).length === 0
              ) {
                related_articles.push(articleData);
              }
            }
          } else {
            related_articles = [articleData];
          }
          props.saveData({ ...props.data, related_articles });
          document.getElementById("button").focus();
        }}
        width="100%"
      ></TableInput>
      {render.length >= 1 && (
        <React.Fragment>
          <Table id="Table">{render}</Table>
        </React.Fragment>
      )}
      <ContinueButton
        id="button"
        onClick={() => {
          props.callback();
        }}
        type="submit"
      >
        Registrar
      </ContinueButton>
    </React.Fragment>
  );
};
