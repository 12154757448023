import React, { useState, useEffect } from "react";
import { Loading, Completed } from "../../GenericPopup";
import FirstSection from "./FirstSection";
import styled from "styled-components";
import { validateInputValues } from "../../_utils/helper";

const FullScreenContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  z-index: 1;
`;

export default function EmployeeEdition(props) {
  const [stepSelected, setSectionSelected] = useState(1);
  const [status, setStatus] = useState(null);
  const [statusMessage, setStatusMessage] = useState(
    "Colaborador registrado exitosamente"
  );
  const [error, setError] = useState(false);

  let render;
  switch (stepSelected) {
    case 1:
      render = (
        <FirstSection
          {...props}
          changeSection={setSectionSelected}
          setStatus={setStatus}
          setStatusMessage={setStatusMessage}
          error={error}
        ></FirstSection>
      );
      break;
    case 2:
      render = <Loading close={props.close}></Loading>;
      break;
    case 3:
      render = (
        <Completed
          type={status}
          message={statusMessage}
          close={props.close}
        ></Completed>
      );
      break;
    default:
      break;
  }

  useEffect(() => {
    setTimeout(() => setError(false), 1000);
  }, [error]);

  return (
    <FullScreenContainer
      id="fullscreen_container"
      onClick={(event) => {
        if (event.target !== document.getElementById("fullscreen_container")) {
          return;
        }
        if (
          validateInputValues([
            document.getElementById("fullname").value,
            document.getElementById("email").value,
          ]).length === 2
        ) {
          props.close();
        } else {
          setError(true);
        }
      }}
      children={render}
    ></FullScreenContainer>
  );
}
