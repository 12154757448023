import React from "react";
import styled from "styled-components";

import emptyWarehouses from "../../assets/icons/emptyWarehouses.svg";

const EmptyContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.figure`
  width: 80px;
  height: 80px;
  margin-bottom: 30px;
`;

const EmptyActionButton = styled.button`
  padding: 15px 30px;
  border-radius: 5px;
  color: white;
  background: #5590ff;
  margin-top: 30px;
`;

export default (props) => {
  return (
    <EmptyContent>
      <Icon>
        <img src={emptyWarehouses} alt="Sin bodegas" />
      </Icon>
      <p>No hay bodegas registradas</p>
      <EmptyActionButton onClick={() => props.newWarehouse()}>
        Registrar bodega
      </EmptyActionButton>
    </EmptyContent>
  );
};
