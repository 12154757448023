import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

import Counters from "./Counters";
import IncomesGraph from "./IncomesGraph";
import { ProductsSold } from "./ProductsTable";
import Sellers from "./Sellers";

import bg from "../../assets/icons/backgroundIncome.svg";

const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  height: 115px;
  padding: 0 17px;
  align-items: flex-start;
  justify-content: center;
`;

const Time = styled.p`
  font-size: 32px;
  color: #0e2c5e;
  font-weight: 600;
`;

const DetailedTime = styled.p`
  font-size: 13px;
  color: #8894ac;
  font-weight: 500;
  margin-top: 10px;
`;

const MetricsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const LeftSection = styled.div`
  width: 70%;
  padding: 15px;
  box-sizing: border-box;
`;

const RightSection = styled.div`
  width: 30%;
  padding: 15px;
  box-sizing: border-box;
`;

const IncomesCounter = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5590ff;
  background-size: contain;
  background-image: url(${bg});
  border-radius: 10px;
  height: 240px;
  margin-bottom: 15px;
  p {
    color: white;
  }
`;

const TotalIncome = styled.p`
  font-weight: 600;
  font-size: 26px;
`;

const IncomesLabel = styled.p`
  font-weight: 500;
  font-size: 16px;
  position: absolute;
  left: 20px;
  bottom: 20px;
`;

export default () => {
  const [metrics, setMetrics] = useState(null);
  const [firstExecution, setExecutionState] = useState(true);

  const today = new Date();
  const date = today.getDate();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;

  useEffect(() => {
    if (firstExecution) {
      axios({
        method: "get",
        url: process.env.REACT_APP_METRICS_ENDPOINT,
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      }).then((response) => setMetrics(JSON.parse(response.data.body).data));
      setExecutionState(false);
    }
  }, [firstExecution]);

  switch (month) {
    case 1:
      month = "Enero";
      break;
    case 2:
      month = "Febrero";
      break;
    case 3:
      month = "Marzo";
      break;
    case 4:
      month = "Abril";
      break;
    case 5:
      month = "Mayo";
      break;
    case 6:
      month = "Junio";
      break;
    case 7:
      month = "Julio";
      break;
    case 8:
      month = "Agosto";
      break;
    case 9:
      month = "Septiembre";
      break;
    case 10:
      month = "Octubre";
      break;
    case 11:
      month = "Noviembre";
      break;
    case 12:
      month = "Diciembre";
      break;
    default:
      break;
  }

  const formattedDate = `${date} de ${month} de ${year}`;

  return (
    <MainContainer>
      <Header>
        <Time>{formattedDate}</Time>
        <DetailedTime>Visualizando métricas acumulativas</DetailedTime>
      </Header>
      <MetricsContainer>
        <LeftSection>
          <Counters metrics={metrics}></Counters>
          <IncomesGraph products={metrics?.incomePerTime}></IncomesGraph>
          <ProductsSold
            title="Productos más vendidos"
            products={metrics?.mostSoldProducts}
          ></ProductsSold>
        </LeftSection>
        <RightSection>
          <IncomesCounter>
            <TotalIncome>
              {metrics?.incomeAmount
                ? `$${metrics.incomeAmount.toFixed(2)} MXN`
                : "--"}
            </TotalIncome>
            <IncomesLabel>Ingresos</IncomesLabel>
          </IncomesCounter>
          <Sellers sellers={metrics?.sellers}></Sellers>
        </RightSection>
      </MetricsContainer>
    </MainContainer>
  );
};
