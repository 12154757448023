import React from "react";
import MainLoggedIn from "./MainLoggedIn";
import LogoutBasicSctructure from "./LogoutBasicStructure";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function App(props) {
  return (
    <Router>
      <Switch>
        <AuthDetection path="/" {...props}></AuthDetection>
      </Switch>
    </Router>
  );
}

function AuthDetection(props) {
  // TODO: Improve this mechanism to detect authentication
  const authenticated =
    sessionStorage.getItem("auth") === "true" ? true : false;

  return (
    <Route
      render={() =>
        authenticated ? (
          <MainLoggedIn {...props}></MainLoggedIn>
        ) : (
          <LogoutBasicSctructure {...props}></LogoutBasicSctructure>
        )
      }
    />
  );
}
