import React from "react";
import styled from "styled-components";
import { Auth } from "aws-amplify";

import CustomInput from "./CustomInput";

const FullScreenContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  z-index: 1;
`;

const CenteredContainer = styled.div`
  position: fixed;
  overflow-y: auto;
  max-height: 600px;
  width: 400px;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  background: white;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
`;

const Title = styled.p`
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px; ;
`;

const ContinueButton = styled.button`
  padding: 12px 25px 14px 25px;
  color: white;
  background: #323946;
  border-radius: 100px;
  font-weight: 500;
  margin-top: 50px;
  align-self: flex-start;
  outline: none;
  border: none;
`;

const Description = styled.p`
  font-size: 14px;
  color: #323946;
  font-weight: 300;
  max-width: 350px;
  text-align: center;
  margin-bottom: 15px;
`;

export default function ChangePassword(props) {
  return (
    <FullScreenContainer
      id="fullscreen_container"
      onClick={(event) => {
        if (event.target !== document.getElementById("fullscreen_container")) {
          return;
        }

        props.close();
      }}
    >
      <CenteredContainer>
        <Title>{props.title}</Title>
        <Description>
          Por tu seguridad, ingresa tu contraseña actual para posteriormente
          registrar tu nueva contraseña.
        </Description>
        <CustomInput
          id="current_password"
          placeholder="Contraseña actual"
          type="password"
        ></CustomInput>
        <CustomInput
          id="new_password"
          placeholder="Nueva contraseña"
          type="password"
        ></CustomInput>
        <CustomInput
          id="repeat_new_password"
          placeholder="Repetir contraseña"
          type="password"
        ></CustomInput>
        <div></div>
        <ContinueButton
          onClick={async () => {
            // document.getElementById('fspup').style.display = 'none';
            // props.setLoading(true);

            let user = await Auth.currentAuthenticatedUser();
            try {
              await Auth.changePassword(
                user,
                document.getElementById("ipt_old_pass").value,
                document.getElementById("ipt_new_pass").value
              );
              // props.setLoading(false);
              // props.setProcessStatus(true);
            } catch (err) {
              console.log(err);
              // props.setLoading(false);
              // props.setFailureStatus(true);
            }
          }}
        >
          Cambiar
        </ContinueButton>
      </CenteredContainer>
    </FullScreenContainer>
  );
}
