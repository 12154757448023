import React from "react";
import styled from "styled-components";

import CustomInput from "./CustomInput";

const FullScreenContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  z-index: 1;
`;

const CenteredContainer = styled.div`
  position: fixed;
  overflow-y: auto;
  max-height: 600px;
  width: 400px;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  background: white;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
`;

const Title = styled.div`
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px; ;
`;

const ContinueButton = styled.button`
  padding: 12px 25px 14px 25px;
  color: white;
  background: #323946;
  border-radius: 100px;
  font-weight: 500;
  margin-top: 50px;
  align-self: flex-start;
  outline: none;
  border: none;
`;

const Description = styled.p`
  font-size: 14px;
  color: #323946;
  font-weight: 300;
  max-width: 350px;
  text-align: center;
  margin-bottom: 15px;
`;

export default function ChangeEmail(props) {
  return (
    <FullScreenContainer
      id="fullscreen_container"
      onClick={(event) => {
        if (event.target !== document.getElementById("fullscreen_container")) {
          return;
        }

        props.close();
      }}
    >
      <CenteredContainer>
        <Title>{props.title}</Title>
        <Description>
          Te haremos llegar un código de seguridad a tu nuevo correo para
          validarlo.
        </Description>
        <CustomInput
          id="change_email"
          placeholder="Nuevo correo electrónico"
          type="text"
        ></CustomInput>
        <ContinueButton>Enviar código</ContinueButton>
      </CenteredContainer>
    </FullScreenContainer>
  );
}
