import styled, { css } from "styled-components";

export const FullScreenContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  z-index: 100;
`;

export const CenteredContainer = styled.div`
  position: fixed;
  width: 400px;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  background: white;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;

  ${(props) =>
    props.error === true &&
    css`
      animation-name: errorAlertFixed;
      animation-duration: 0.3s;
      animation-iteration-count: 3;
    `}
`;

export const Title = styled.p`
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  text-align: center;
  margin-bottom: 40px;
  & span {
    color: #5590ff;
    font-weight: 500;
  }
`;

export const EmptySpace = styled.div`
  width: 25px;
  flex-shrink: 0;
`;

export const ContinueButton = styled.button`
  padding: 12px 25px 14px 25px;
  color: white;
  background: #323946;
  border-radius: 100px;
  font-weight: 500;
  margin-top: 50px;
  align-self: flex-start;
  outline: none;
  border: none;
`;
