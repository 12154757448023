import React from "react";
import styled from "styled-components";

const MainContainer = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  height: ${(props) => (props.height ? props.height : "65px")};
  margin-bottom: 15px;
  box-sizing: border-box;
  background: ${(props) =>
    props.selected ? "rgba(225, 229, 237, 1)" : "#fbfdff"};
  border: 1px solid #e9eef5;
  border-radius: 10px;
  flex-shrink: 0;
  span {
    width: fit-content;
    padding: 8px 20px;
    color: ${(props) => (props.selected ? "white" : "#0e2c5e")};
    font-weight: ${(props) => (props.selected ? "600" : "500")};
    background: ${(props) => (props.selected ? "#323946" : null)};
    border-radius: 10px;
  }
  :hover {
    background: ${(props) =>
      props.selected ? null : "rgba(225, 229, 237, .5)"};
  }
`;

const RowField = styled.p`
  color: ${(props) => (props.color ? props.color : "#0E2C5E")};
  width: ${(props) => (props.width ? props.width : "25%")};
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
`;

const Orders = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
`;

const DailyCash = styled.div`
  width: 100%;
  height: 131px;
  border-radius: 10px;
  background: #fbfdff;
  border: 1px solid #e9eef5;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 15px;
  .firstSection {
    width: 40%;
    background: white;
    border-radius: 10px;
    border: 1px solid #e9eef5;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding-left: 15px;
    .first {
      font-size: 17px;
      color: #0e2c5e;
      font-weight: 500;
      margin-bottom: 5px;
    }
    .second {
      font-size: 14px;
      font-weight: 500;
      color: #707070;
    }
  }
  .secondSection {
    width: 26%;
    padding-left: 30px;
    .first {
      font-size: 14px;
      color: #8894ac;
      margin-bottom: 5px;
    }
    .second {
      font-size: 22px;
      font-weight: 500;
      color: #0e2c5e;
    }
  }
  .thirdSection {
    width: 34%;
    padding-left: 15px;
    .first {
      font-size: 14px;
      color: #8894ac;
      margin-bottom: 5px;
    }
    .second {
      font-size: 22px;
      font-weight: 500;
      color: #0e2c5e;
    }
  }
`;

const EmptySpace = styled.hr`
  width: 40%;
  margin: 40px auto;
  border: 1px solid #e9eef5;
`;

export default (props) => {
  const detailedOrders = Object.keys(props.groupedOrders)
    .sort((x, y) => {
      return y - x;
    })
    .map((element) => {
      const currentGroup = props.groupedOrders[element];
      const rows = [];
      Object.keys(props.groupedOrders[element].orders)
        .sort((x, y) => {
          return +new Date(y) - +new Date(x);
        })
        .reverse()
        .forEach((order) => {
          const currentOrder = props.groupedOrders[element].orders[order];
          rows.push(
            <Row
              onClick={() => {
                props.setOrderSelected({ ...currentOrder });
              }}
              selected={
                props.orderSelected.id === currentOrder.id ? "true" : null
              }
            >
              <RowField width="15%">{currentOrder.uuid}</RowField>
              <RowField width="35%">{currentOrder.responsable}</RowField>
              <RowField width="25%" formatPrice="true">
                <span>${currentOrder.total.toFixed(2)} MXN</span>
              </RowField>
              <RowField width="25%">{currentOrder.date.timeFormatted}</RowField>
            </Row>
          );
        });

      return (
        <>
          <DailyCash>
            <div className="firstSection">
              <p className="first">Corte de caja</p>
              <p className="second">{currentGroup.date}</p>
            </div>
            <div className="secondSection">
              <p className="first">Ventas totales</p>
              <p className="second">
                {Object.keys(currentGroup.orders).length}
              </p>
            </div>
            <div className="thirdSection">
              <p className="first">Ingresos totales</p>
              <p className="second">
                ${currentGroup.totalIncome.toFixed(2)} MXN
              </p>
            </div>
          </DailyCash>
          {rows}
          <EmptySpace></EmptySpace>
        </>
      );
    });

  return (
    <MainContainer>
      <Row height="37px">
        <RowField color="#8894AC" width="15%">
          ID de Orden
        </RowField>
        <RowField color="#8894AC" width="40%">
          Vendedor
        </RowField>
        <RowField color="#8894AC" width="20%">
          Ingresos
        </RowField>
        <RowField color="#8894AC" width="25%">
          Fecha
        </RowField>
      </Row>
      <Orders>{detailedOrders}</Orders>
    </MainContainer>
  );
};
