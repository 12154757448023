import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useHistory } from "react-router-dom";

import EmployeeRegistration from "./Update";
import TableCell from "../SmartInputs/TableCell.js";
import { getEmployeeData } from "../_utils/data";
import DeletePopup from "../DeletePopup";
import { Loading, Completed } from "../GenericPopup";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 115px;
  align-items: center;
  justify-content: flex-end;
  padding: 0 40px;
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
`;

const Table = styled.div`
  width: 100%;
  padding: 15px 30px;
  box-sizing: border-box;
  overflow-y: auto;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  &:hover {
    & button {
      display: block;
    }
  }
`;

const LabelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  & p {
    font-size: 14px;
    font-weight: 500;
    padding: 15px 20px;
    margin: 0;
    box-sizing: border-box;
  }
`;

const EmployeesContainer = styled.div`
  border-bottom: 1px solid #e9eef5;
`;

const DeleteEmployee = styled.button`
  border-radius: 100px;
  text-align: center;
  padding: 10px 20px;
  background: #fb5b5a;
  color: white;
  position: absolute;
  top: 8px;
  right: 10px;
  font-weight: 500;
  font-size: 14px;
  display: none;
  height: 40px;
`;

const EditEmployee = styled.button`
  border-radius: 100px;
  text-align: center;
  padding: 10px 20px;
  background: #323946;
  color: white;
  position: absolute;
  top: 8px;
  left: 10px;
  font-weight: 500;
  font-size: 14px;
  display: none;
  height: 40px;
`;

export default function Employees() {
  const history = useHistory();
  const permissionToAccess =
    JSON.parse(sessionStorage.getItem("permissions")).find(
      (e) => e.area === "Colaboradores"
    ) !== undefined;
  const [loading, setLoading] = useState(false);
  const [completed, setProcessStatus] = useState(false);
  const [newEmployeeActivated, setNewEmployeeState] = useState(false);
  const [newEmployeeCreated, setNewEmployeeCreatedState] = useState(false);
  const [deleteEmployeeActivated, setDeleteEmployeeState] = useState(false);
  const [employeeSelected, setEmployeeSelectedState] = useState(null);
  const currentUser = JSON.parse(sessionStorage.getItem("user"));
  const [employees, setEmployeesState] = useState([]);
  const [firstEmployeesExecution, setEmployeesExecutionState] = useState(true);
  const [editEmployee, setEditEmployee] = useState(false);

  useEffect(() => {
    if (permissionToAccess && (newEmployeeCreated || firstEmployeesExecution)) {
      const response = getEmployeeData({ force: true });
      response.then((response) => {
        setEmployeesState(response);
        setEmployeesExecutionState(false);
        setNewEmployeeCreatedState(false);
      });
    }

    if (!permissionToAccess) {
      history.push({
        pathname: "profile",
      });
    }
  }, [
    firstEmployeesExecution,
    history,
    newEmployeeCreated,
    permissionToAccess,
  ]);

  const cells = [];
  for (let i = 0; i < employees.length; i++) {
    cells.push(
      <TableRow key={i}>
        {employees[i].cognito_id !== currentUser.cognito_id && (
          <>
            <DeleteEmployee
              onClick={() => {
                setEditEmployee(false);
                setDeleteEmployeeState(true);
                setEmployeeSelectedState(employees[i]);
              }}
            >
              Eliminar
            </DeleteEmployee>
            <EditEmployee
              onClick={() => {
                setEditEmployee(true);
                setEmployeeSelectedState(employees[i]);
              }}
            >
              Actualizar
            </EditEmployee>
          </>
        )}
        <TableCell title="--" width="10%"></TableCell>
        <TableCell title={employees[i].fullname} width="28%"></TableCell>
        <TableCell title={employees[i].job_title} width="14%"></TableCell>
        <TableCell title="Administrador" width="14%"></TableCell>
        <TableCell title={employees[i].email} width="17%"></TableCell>
        <TableCell title={employees[i].phone_number} width="17%"></TableCell>
      </TableRow>
    );
  }

  const deleteEmployee = async () => {
    setLoading(true);
    await axios({
      method: "delete",
      url: process.env.REACT_APP_EMPLOYEE_ENDPOINT,
      data: {
        cognito_id: employeeSelected.cognito_id,
      },
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });

    setLoading(false);
    setDeleteEmployeeState(false);
    setProcessStatus(true);
    setNewEmployeeCreatedState(true);
  };

  return (
    <MainContainer>
      {editEmployee && (
        <EmployeeRegistration
          title="Editar colaborador"
          employeeSelected={employeeSelected}
          close={() => setEditEmployee(!editEmployee)}
          callback={() => {
            setEditEmployee(!editEmployee);
          }}
          setEmployeesState={setEmployeesState}
        ></EmployeeRegistration>
      )}
      {newEmployeeActivated && (
        <EmployeeRegistration
          title="Nuevo colaborador"
          close={() => {
            setNewEmployeeState(!newEmployeeActivated);
          }}
          callback={() => setNewEmployeeCreatedState(true)}
          setEmployeesState={setEmployeesState}
        ></EmployeeRegistration>
      )}
      {deleteEmployeeActivated && (
        <DeletePopup
          callback={deleteEmployee}
          title="Eliminar colaborador"
          close={() => setDeleteEmployeeState(false)}
          message={`Está a punto de eliminar al colaborador ${employeeSelected.fullname}, ¿está seguro de querer continuar?. Esta acción no se puede revertir.`}
        ></DeletePopup>
      )}
      {loading && <Loading />}
      {completed && (
        <Completed
          type="SUCCESS"
          close={() => {
            setProcessStatus(false);
          }}
        />
      )}
      {permissionToAccess && (
        <>
          <HeaderContainer>
            <button onClick={() => setNewEmployeeState(true)}>Añadir</button>
          </HeaderContainer>
          <Table>
            <LabelsContainer>
              <p style={{ width: "10%" }}>Foto</p>
              <p style={{ width: "28%" }}>Nombre</p>
              <p style={{ width: "14%" }}>Puesto</p>
              <p style={{ width: "14%" }}>Rol</p>
              <p style={{ width: "17%" }}>Email</p>
              <p style={{ width: "17%" }}>Teléfono</p>
            </LabelsContainer>
            <EmployeesContainer>{cells}</EmployeesContainer>
          </Table>
        </>
      )}
    </MainContainer>
  );
}
