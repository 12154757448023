import { Base64 } from "js-base64";
import Fuse from "fuse.js";

export const perfectSpaceForArticles = (
  elementsClassName,
  parentContainer,
  elementSize,
  tableActivated
) => {
  // Execute only when square view is activated
  if (tableActivated) return;

  // Execute once new view is loaded
  setTimeout(() => {
    const element = elementSize
      ? elementSize
      : document.getElementsByClassName(elementsClassName);
    if (elementSize || element.length > 0) {
      const elementWidth = elementSize ? elementSize : element[0].clientWidth;
      const parentPadding = 17;
      const freeSpace =
        document.getElementById(parentContainer).clientWidth -
        parentPadding * 2;
      const elements = Math.floor(freeSpace / elementWidth);
      const margin = Math.floor((freeSpace / elements - elementWidth) / 2);
      document.getElementById(parentContainer).style.padding =
        parentPadding + "px";
      if (margin < 7) {
        perfectSpaceForArticles(
          elementsClassName,
          parentContainer,
          elementWidth + 4,
          tableActivated
        );
      } else {
        const elementsPainted =
          document.getElementsByClassName(elementsClassName);
        for (let i = 0; i < elementsPainted.length; i++) {
          elementsPainted[i].style.margin = margin + "px";
        }
      }
    }
  }, 0);
};

export const perfectSpaceForWarehouse = (
  elementsClassName,
  parentContainer
) => {
  const element = document.getElementsByClassName(elementsClassName);
  if (element.length > 0) {
    const elementWidth = element[0].clientWidth;
    const parentPadding = 17;
    const freeSpace =
      document.getElementById(parentContainer).clientWidth - parentPadding * 2;
    const elements = Math.floor(freeSpace / elementWidth);
    const margin = Math.floor((freeSpace / elements - elementWidth) / 2);
    document.getElementById(parentContainer).style.padding =
      parentPadding + "px";
    const elementsPainted = document.getElementsByClassName(elementsClassName);
    for (let i = 0; i < elementsPainted.length; i++) {
      elementsPainted[i].style.margin = margin - 0.5 + "px";
    }
  }
};

export const isElementClicked = (evt, element) => {
  let flyoutElement;
  try {
    flyoutElement = document.getElementById(element);
  } catch (err) {}

  let targetElement = evt.target;

  do {
    if (targetElement === flyoutElement) {
      return true;
    }
    // Go up the DOM
    targetElement = targetElement.parentNode;
  } while (targetElement);

  return false;
};

export const getImage = (articleImage, width, height) => {
  try {
    const imageLocation = articleImage.location;
    return (
      "https://d954wi4n8olec.cloudfront.net/" +
      Base64.encode(
        JSON.stringify({
          bucket: "wallavi-inventory-storage",
          key: imageLocation,
          edits: {
            resize: {
              width: width,
              height: height,
              fit: "contain",
            },
          },
        })
      )
    );
  } catch (err) {
    return "/addNew.svg";
  }
};

export const fuseConf = (dropdownValues) => {
  // String matcher
  let fuse;
  const options = {
    includeScore: true,
    threshold: 0.3,
    distance: 500,
  };
  if (dropdownValues) {
    fuse = new Fuse(dropdownValues, options);
  }

  return fuse;
};

export const filterDropdownValues = (fuse, event) => {
  try {
    let result = fuse.search(event.target.value);
    result = result.map((element) => element.item);
    return result;
  } catch (err) {
    //Values not available yet
  }
};

export const validateInputValues = (inputsValues) => {
  let index = [];
  inputsValues.forEach((element, i) => {
    if (!element) {
      index.push(i);
    }
  });
  return index;
};

export const listenerEnter = (data) => {
  if (data.event.code === "Enter" || data.event.code === "NumpadEnter") {
    if (validateInputValues(data.array).length === 0) {
      data.actionSuccess();
    } else {
      data.actionFailure();
    }
  }
};

export const listenerEnterForSteps = (data) => {
  if (data.event.code === "Enter" || data.event.code === "NumpadEnter") {
    let temp = [];
    for (let i = 0; i < data.inputsRequired.length; i++) {
      temp.push(document.getElementById(data.inputsRequired[i]).value);
    }
    if (validateInputValues(temp).length === 0) {
      data.actionSuccess();
    } else {
      data.actionFailure();
    }
  }
};

export const perfectSpaceForPointsOfSale = (
  elementsClassName,
  parentContainer
) => {
  const element = document.getElementsByClassName(elementsClassName);
  if (element.length > 0) {
    const elementWidth = element[0].clientWidth;
    const parentPadding = 17;
    const border = 2;
    const freeSpace =
      document.getElementById(parentContainer).clientWidth -
      parentPadding * 2 -
      border;
    const elements = Math.floor(freeSpace / elementWidth);
    const margin = Math.floor((freeSpace / elements - elementWidth) / 2);
    document.getElementById(parentContainer).style.padding =
      parentPadding + "px";
    for (let i = 0; i < element.length; i++) {
      element[i].style.margin = margin - 0.5 + "px";
    }
  }
};

export const getUnitMeasure = (unit_measure) => {
  switch (unit_measure) {
    case "KILOGRAM":
      return "Kilogramo";
    case "PIECE":
      return "Pieza";
    case "METER":
      return "Metro";
    case "LITER":
      return "Litro";
    default:
      return "Pieza";
  }
};

export const getArticleCurrency = (currency) => {
  switch (currency) {
    case "USD":
      return {
        currencySign: "$",
        currency,
      };
    case "MXN":
      return {
        currencySign: "$",
        currency,
      };
    case "EUR":
      return {
        currencySign: "€",
        currency,
      };
    case "GBP":
      return {
        currencySign: "£",
        currency,
      };
    case "JPY":
      return {
        currencySign: "¥",
        currency,
      };
    case "CNY":
      return {
        currencySign: "¥",
        currency,
      };
    default:
      return {
        currencySign: "$",
        currency: "MXN",
      };
  }
};

export const getMonthName = (monthNumber) => {
  switch (monthNumber) {
    case 1:
      return "Enero";
    case 2:
      return "Febrero";
    case 3:
      return "Marzo";
    case 4:
      return "Abril";
    case 5:
      return "Mayo";
    case 6:
      return "Junio";
    case 7:
      return "Julio";
    case 8:
      return "Agosto";
    case 9:
      return "Septiembre";
    case 10:
      return "Octubre";
    case 11:
      return "Noviembre";
    case 12:
      return "Diciembre";
    default:
      return;
  }
};

export const getDetailedTime = (time) => {
  const timeCopy = time;

  const date = {};
  date.timeUTC = time;
  date.timestamp = new Date(date.timeUTC).getTime();
  date.localTime = new Date(timeCopy.toLocaleString());
  date.time = {
    date: new Date(date.localTime).getDate(),
    month: new Date(date.localTime).getMonth() + 1,
    year: new Date(date.localTime).getFullYear(),
    hours: new Date(date.localTime).getHours(),
    minutes: new Date(date.localTime).getMinutes().toString().padStart(2, "0"),
    seconds: new Date(date.localTime).getSeconds(),
    milliseconds: new Date(date.localTime).getMilliseconds(),
  };

  date.timestampUntilDate = new Date(
    date.time.year,
    date.time.month,
    date.time.date
  ).getTime();

  // setup order hour and minute
  date.timeFormatted = `${
    date.time.hours > 12 ? date.time.hours - 12 : date.time.hours
  }:${date.time.minutes} ${date.time.hours > 11 ? "PM" : "AM"}`;

  date.shortFormatted = `${date.time.date} de ${getMonthName(
    date.time.month
  )} de ${date.time.year}`;

  date.fullFormatted = `${date.shortFormatted} ${date.timeFormatted}`;

  return date;
};
