import React from "react";
import styled from "styled-components";

// components
import TableRow from "./TableRow";

// svg
import profileImgDefault from "../../../assets/img/default-profile-img.svg";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  overflow-y: scroll;
  margin-left: 15px;
  box-sizing: border-box;
  .main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
`;

const Row = styled.p`
  width: 100%;
  padding: 0 10px;
  height: 37px;
  margin-bottom: 30px;
  box-sizing: border-box;
  background: #fbfdff;
  border: 1px solid #e9eef5;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  color: #8894ac;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
`;

const Responsable = styled.figure`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  div:nth-child(2) {
    padding-left: 15px;
    flex-grow: 1;
    .name {
      font-weight: 500;
      color: #0e2c5e;
      margin-bottom: 8px;
    }
    .date {
      font-weight: 400;
      color: #707070;
    }
  }
  figure {
    height: 25px;
    width: 25px;
    margin-left: 18px;
    border-radius: 160px;
    border: solid 1px #707070;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const Circle = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 4px;
  background-color: #323946;
  margin-right: 10px;
`;

const ProductsContainer = styled.div`
  width: 100%;
  margin-bottom: 15px;
  border-radius: 10px;
  background: #fbfdff;
  border: 1px solid #e9eef5;
  box-sizing: border-box;
  padding: 15px 20px;
  > div {
    display: flex;
    justify-content: space-between;
    > p {
      font-size: 14px;
      color: #8894ac;
      font-weight: 500;
    }
  }
`;

const FinalReview = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #e9eef5;
  background: #fbfdff;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 25px;
  .align {
    display: flex;
  }
  p {
    font-size: 14px;
  }
`;

const Cancel = styled.p`
  background: #323946;
  color: #fff;
  padding: 15px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 100px;
`;

const Counter = styled.div`
  margin-left: 30px;
  margin-bottom: ${(props) => (props.margin ? "30px" : null)};
  .title {
    font-weight: 500;
    color: #8894ac;
    padding-bottom: 10px;
  }
  .value {
    font-weight: 500;
    color: #0e2c5e;
  }
  .income {
    padding: 8px 20px;
    color: #0e2c5e;
    font-weight: 700;
    background: rgba(225, 229, 237, 1);
    border-radius: 10px;
  }
`;

export default (props) => {
  return (
    <MainContainer isCancel={props.order.status?.completed === 0}>
      {props.order.articles !== undefined && (
        <div className="main">
          <Row>Estado de la orden</Row>
          <Responsable>
            <Circle />
            <div>
              <p className="name">Vendedor: {props.order.responsable}</p>
              <p className="date">
                Completada {props.order.date.fullFormatted}
              </p>
            </div>
            <figure>
              <img
                src={
                  props.order.status.picture
                    ? props.order.status.picture
                    : profileImgDefault
                }
                alt={props.order.status.responsable}
              />
            </figure>
          </Responsable>
          {props.order.status.completed === 0 && (
            <Responsable>
              <Circle />
              <div>
                <p className="name">
                  Vendedor: {props.order.status.responsable}
                </p>
                <p className="date">
                  Cancelada {props.order.status.date.fullFormatted}
                </p>
              </div>
              <figure>
                <img
                  src={
                    props.order.status.picture
                      ? props.order.status.picture
                      : profileImgDefault
                  }
                  alt={props.order.status.responsable}
                />
              </figure>
            </Responsable>
          )}
          <ProductsContainer>
            <div>
              <p>Productos</p>
              <p>Unidades vendidas</p>
            </div>
            {props.order.articles.map((e) => (
              <TableRow key={e.id} element={e} />
            ))}
          </ProductsContainer>
          <FinalReview>
            <div className="align">
              <Counter margin="true">
                <p className="title">Total de productos</p>
                <p className="value">
                  {parseFloat(props.order.articles.length).toFixed(2)}
                </p>
              </Counter>
              <Counter margin="true">
                <p className="title">Total de unidades</p>
                <p className="value">
                  {parseFloat(props.order.unitsSold).toFixed(2)}
                </p>
              </Counter>
            </div>
            <div className="align">
              <Counter>
                <p className="title">Total</p>
                <p className="income">
                  ${parseFloat(props.order.total).toFixed(2)} MXN
                </p>
              </Counter>
              <Counter>
                <p className="title">Subtotal</p>
                <p className="income">
                  ${parseFloat(props.order.subtotal).toFixed(2)} MXN
                </p>
              </Counter>
            </div>
          </FinalReview>
          {props.order.status.completed === 1 && (
            <Cancel onClick={() => props.order.id && props.setPopup(true)}>
              Cancelar
            </Cancel>
          )}
        </div>
      )}
    </MainContainer>
  );
};
