import React from "react";
import { ArticleSquareView, ArticleTableView } from "./_ArticleSquareTableView";

export const getArticleStock = (article) => {
  let response = {
    stock: 0,
    stockAlert: false,
  };
  try {
    response.stock = article.stock.map((element) => {
      return parseFloat(element.stock);
    });
    response.stock.forEach((element) => {
      if (
        (article.minimum_amount === 0 || article.minimum_amount) &&
        element < article.minimum_amount
      ) {
        response.stockAlert = true;
      }
    });
    response.stock = response.stock.reduce((a, b) => a + b);
    return response;
  } catch (err) {
    return { ...response, stock: 0 };
  }
};

export const getArticleCurrency = (currency) => {
  switch (currency) {
    case "USD":
      return {
        currencySign: "$",
        currency,
      };
    case "MXN":
      return {
        currencySign: "$",
        currency,
      };
    case "EUR":
      return {
        currencySign: "€",
        currency,
      };
    case "GBP":
      return {
        currencySign: "£",
        currency,
      };
    case "JPY":
      return {
        currencySign: "¥",
        currency,
      };
    case "CNY":
      return {
        currencySign: "¥",
        currency,
      };
    default:
      return {
        currencySign: "$",
        currency: "MXN",
      };
  }
};

export const getUnitMeasure = (unit_measure) => {
  switch (unit_measure) {
    case "KILOGRAM":
      return "Kilogramo";
    case "PIECE":
      return "Pieza";
    case "METER":
      return "Metro";
    case "LITER":
      return "Litro";
    default:
      return "Pieza";
  }
};

export const formatPrice = (currencySign, price, currency) => {
  const quantity =
    Math.round(parseFloat(price ? price : 0)) !== parseFloat(price ? price : 0)
      ? parseFloat(price ? price : 0).toFixed(2)
      : parseFloat(price ? price : 0);
  return `${currencySign}${quantity
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${currency}`;
};

export const formatStock = (stock, unitMeasure) => {
  const quantity = Math.round(stock) !== stock ? stock.toFixed(3) : stock;
  if (quantity === 0) {
    return "Sin stock";
  }
  return `${quantity
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${unitMeasure.toLowerCase()}${
    quantity > 1 || quantity < -1 ? "s" : ""
  }`;
};

export const getArticleComponent = (
  articles,
  tableActivated,
  setArticleData
) => {
  const components = [];
  for (let i = 0; i < articles.length; i++) {
    if (tableActivated) {
      components.push(
        <ArticleTableView
          key={i}
          article={articles[i]}
          i={i}
          setArticleData={setArticleData}
        ></ArticleTableView>
      );
    } else {
      components.push(
        <ArticleSquareView
          key={i}
          article={articles[i]}
          setArticleData={setArticleData}
        ></ArticleSquareView>
      );
    }
  }

  return components;
};
