import React, { useState } from "react";
import styled from "styled-components";

import help from "../../../assets/icons/helpButton.svg";

const ContainerCustomRole = styled.div`
  position: fixed;
  left: 55%;
  right: 0;
  height: 100vh;
  overflow-y: scroll;
  background-color: #fff;
  padding: 40px 35px;
  font-family: "Roboto";
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: "Roboto";
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 50px;
  tr {
    :first-child {
      margin-bottom: 30px;
      font-weight: 500;
    }
    > th,
    td {
      font-weight: 500;
      text-align: center;
      width: 17%;
      border: 1px solid #99a7bf;
      box-sizing: border-box;
      :first-child {
        padding-left: 20px;
        text-align: left;
        width: 30%;
      }
      > div {
        margin: auto;
      }
    }
    th {
      border-width: 0;
    }
  }
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 30px;
  margin-left: 20px;
  & span {
    font-size: 15px;
    font-weight: 500;
  }
`;

const Row = styled.tr`
  height: 60px;
  .disabled {
    background-color: #99a7bf;
  }
`;

const Selected = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 24px;
  background-color: #57e28f;
`;

const ItemGroup = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  margin-left: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  color: #323946;
  & p {
    font-weight: 500;
  }
`;

const Checkbox = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 16px;
  border: ${(props) => (props.selected ? "" : "solid 2px #323946")};
  background-color: ${(props) => (props.selected ? "#5590ff" : "#FFF")};
  border-radius: 5px;
  box-sizing: border-box;
`;

const Description = styled.p`
  color: #99a7bf;
  margin-bottom: 150px;
`;

const Help = styled.div`
  cursor: pointer;
  width: 80px;
  padding: 10px 20px;
  background: #5590ff;
  border-radius: 100px;
  position: absolute;
  top: 25px;
  right: 20px;
  border: 1px solid #5590ff;
  & figure {
    width: 25px;
    height: 25px;
    & img {
      width: 100%;
      height: 100%;
    }
  }
  & p {
    padding-bottom: 2px;
    color: white;
    font-weight: 500;
  }
  &:hover {
    background: white;
    & p {
      color: #5590ff;
    }
  }
  a {
    justify-content: space-between;
    display: flex;
    align-items: center;
  }
`;

export default function CustomRole(props) {
  //Los nuevos permisos que tienen que actualizan la tabla
  const [newPermissions, setNewPersmissions] = useState(props.permissions);
  //Una copia con los permisos editables despues de cada update
  let tempNewPermissions = newPermissions.slice();

  //Hacer los cambios en la variable editable y sustituir a la que actualiza la tabla
  const changeValue = (index, attr) => {
    /* index =>
      0: articles
      1: warehouse
      2: movements
      3: POS
      4: orders
      5: employees*/
    let flagRead = false;
    tempNewPermissions[index]["read"] && attr === "read" && (flagRead = true);

    if (attr === "read" && (index === 0 || index === 1)) {
      if (tempNewPermissions[0]["read"] && tempNewPermissions[1]["read"]) {
        if (!tempNewPermissions[2]["read"] && !tempNewPermissions[3]["read"]) {
          if (index === 0) {
            tempNewPermissions[0]["read"] = true;
            tempNewPermissions[1]["read"] = false;
          } else {
            tempNewPermissions[0]["read"] = false;
            tempNewPermissions[1]["read"] = true;
          }
        }
      }
    }

    tempNewPermissions[index][attr] = !tempNewPermissions[index][attr];

    if (flagRead) {
      // if read is activated and clicked again desactive the others
      tempNewPermissions[index]["create"] = false;
      tempNewPermissions[index]["update"] = false;
      tempNewPermissions[index]["delete"] = false;
    }
    // si tiene permiso de lectura de movimientos y POS
    if (tempNewPermissions[2]["read"] || tempNewPermissions[3]["read"]) {
      // read movements and POS
      tempNewPermissions[0]["read"] = true;
      tempNewPermissions[1]["read"] = true;
      tempNewPermissions[5]["read"] = true;
    } else if (
      !tempNewPermissions[2]["read"] &&
      !tempNewPermissions[3]["read"]
    ) {
      if (
        tempNewPermissions[0]["read"] &&
        tempNewPermissions[1]["read"] &&
        tempNewPermissions[5]["read"]
      ) {
        tempNewPermissions[0]["read"] = false;
        tempNewPermissions[1]["read"] = false;
        tempNewPermissions[5]["read"] = false;
      }
    }
    // si tiene permiso de lectura de ordenes
    if (tempNewPermissions[4]["read"]) {
      // read orders
      tempNewPermissions[0]["read"] = true;
      tempNewPermissions[1]["read"] = true;
      tempNewPermissions[3]["read"] = true;
      tempNewPermissions[5]["read"] = true;
    }
    if (tempNewPermissions[0]["read"] && !tempNewPermissions[1]["read"]) {
      tempNewPermissions[1]["read"] = true;
    }
    if (tempNewPermissions[1]["read"] && !tempNewPermissions[0]["read"]) {
      tempNewPermissions[0]["read"] = true;
    }
    // si cualquiera, sin contar read, esta activado activar permiso read
    for (let i = 0; i < 6; i++) {
      if (
        tempNewPermissions[i]["create"] ||
        tempNewPermissions[i]["update"] ||
        tempNewPermissions[i]["delete"]
      ) {
        tempNewPermissions[i]["read"] = true;
      }
    }
    setNewPersmissions(tempNewPermissions);
  };

  return (
    <ContainerCustomRole id="CustomRoleContainer">
      <Help>
        <a
          href="https://depotcenter.medium.com/c%C3%B3mo-tener-una-colaboraci%C3%B3n-efectiva-dentro-de-tu-empresa-731f1e14ec3f"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>Ayuda</p>
          <figure>
            <img src={help} alt="Helpbox"></img>
          </figure>
        </a>
      </Help>
      <Title>
        Permisos: aplicación ERP <span>(aplicación actual)</span>
      </Title>
      <p>
        Todos los usuarios deben tener accesos para poder trabajar, existen
        permisos correlacionados que dependen el uno del otro para poder
        funcionar. Registrar a un usuario sin ningún permiso significa no
        dejarlo acceder a ninguna funcionalidad dentro de la aplicación.
      </p>
      <Table>
        <tbody>
          <Row>
            <th></th>
            <th>Leer</th>
            <th>Crear</th>
            <th>Actualizar</th>
            <th>Eliminar</th>
          </Row>
          {newPermissions.map((e, i) => {
            return (
              e !== true && (
                <Row key={i}>
                  <td>{e.name}</td>
                  <td onClick={() => changeValue(i, "read")}>
                    {e.read && <Selected />}
                  </td>
                  <td
                    onClick={() => {
                      if (e.name !== "Órdenes" && e.name !== "Órdenes") {
                        changeValue(i, "create");
                      }
                    }}
                    className={`${e.name === "Órdenes" && "disabled"}`}
                  >
                    {e.create && <Selected />}
                  </td>
                  <td
                    onClick={() => {
                      if (e.name !== "Movimientos") {
                        changeValue(i, "update");
                      }
                    }}
                    className={`${e.name === "Movimientos" && "disabled"}`}
                  >
                    {e.update && <Selected />}
                  </td>
                  <td
                    onClick={() => {
                      if (e.name !== "Movimientos" && e.name !== "Órdenes") {
                        changeValue(i, "delete");
                      }
                    }}
                    className={`${
                      (e.name === "Movimientos" || e.name === "Órdenes") &&
                      "disabled"
                    }`}
                  >
                    {e.delete && <Selected />}
                  </td>
                </Row>
              )
            );
          })}
        </tbody>
      </Table>
      <Title>Permisos: aplicación Punto de Venta</Title>
      <ItemGroup
        onClick={(event) => {
          event.preventDefault();
          props.setAccessPOS(!props.accessPOS);
        }}
      >
        <Checkbox selected={props.accessPOS} />
        <p>Poder utilizar la aplicación</p>
      </ItemGroup>
      <Description>
        Nota: La aplicación Punto de Venta de Depot, permite crear y editar
        órdenes que son visibles desde el ERP.
      </Description>
    </ContainerCustomRole>
  );
}
