import { Auth } from "aws-amplify";
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import styled, { css } from "styled-components";
import { useHistory } from "react-router-dom";

import CustomInput from "./CustomInput";
import { Loading, Completed } from "./GenericPopup";
import { listenerEnter } from "./_utils/helper";
import { getAccountData } from "./_utils/data";

const CenteredContainer = styled.div`
  width: 435px;
  max-width: 100%;
  padding: 35px;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;

  ${(props) =>
    props.error === true &&
    css`
      animation-name: errorAlert;
      animation-duration: 0.3s;
      animation-iteration-count: 3;
    `}
`;

const SectionTitle = styled.p`
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
`;

const SubmitButton = styled.input`
  align-self: flex-start;
  margin-top: 15px;
  padding: 11px 20px;
  border-radius: 100px;
  background: #323946;
  color: white;
  border: none;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  outline: none;
  cursor: pointer;
`;

const CenterElementsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Description = styled.p`
  font-size: 14px;
  color: #323946;
  font-weight: 300;
  max-width: 350px;
  text-align: center;
  margin-bottom: 15px;
`;

const EmptySpace = styled.div`
  width: 25px;
  flex-shrink: 0;
`;

export function RegisterCompanyData(props) {
  const history = useHistory();
  const [error, setError] = useState(false);

  const onSubmit = useCallback(() => {
    const companyData = {
      name: document.getElementById("company_name").value,
      address: document.getElementById("company_address").value,
      activity: document.getElementById("company_activity").value,
      phone: document.getElementById("company_phone").value,
      website: document.getElementById("company_website").value,
    };

    props.setNewUserState({ ...props.data, companyData: companyData });
    history.push({
      pathname: "/register/admin",
    });
  }, [history, props]);

  useEffect(() => {
    setTimeout(() => setError(false), 1000);
  }, [error]);

  useEffect(() => {
    const listener = (event) => {
      listenerEnter({
        event: event,
        array: [
          document.getElementById("company_name").value,
          document.getElementById("company_address").value,
          document.getElementById("company_activity").value,
        ],
        actionSuccess: () => onSubmit(),
        actionFailure: () => setError(true),
      });
    };
    document.addEventListener("keydown", listener);
    return () => document.removeEventListener("keydown", listener);
  }, [onSubmit]);

  return (
    <CenteredContainer id="container" error={error}>
      <SectionTitle>La empresa</SectionTitle>
      <Description>
        Entender tu negocio nos ayuda a ofrecerte herramientas personalizadas.
      </Description>
      <form onSubmit={() => onSubmit()}>
        <CustomInput
          id="company_name"
          placeholder="Nombre de la empresa"
          type="text"
          required={true}
        ></CustomInput>
        <CustomInput
          id="company_address"
          placeholder="Dirección"
          type="text"
          required={true}
        ></CustomInput>
        <CenterElementsContainer>
          <CustomInput
            id="company_activity"
            placeholder="Giro comercial"
            type="text"
            required={true}
          ></CustomInput>
          <EmptySpace></EmptySpace>
          <CustomInput
            id="company_phone"
            placeholder="Teléfono (Opcional)"
            type="text"
          ></CustomInput>
        </CenterElementsContainer>
        <CustomInput
          id="company_website"
          placeholder="Página web (Opcional)"
          type="text"
        ></CustomInput>
        <SubmitButton type="submit" value="Continuar"></SubmitButton>
      </form>
    </CenteredContainer>
  );
}

export function RegisterAdminData(props) {
  const [loading, setLoading] = useState(false);
  const [completed, setProcessStatus] = useState(false);
  const [completedMessage, setProcessStatusMessage] = useState(null);
  const history = useHistory();

  if (props.data.companyData === null) {
    history.push({
      pathname: "/register",
    });
  }

  const [error, setError] = useState(false);

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      setLoading(true);
      const userData = {
        name: document.getElementById("user_name").value,
        jobTitle: document.getElementById("user_job_title").value,
        email: document.getElementById("user_email").value,
        pass: document.getElementById("user_pass").value,
      };

      props.setNewUserState({ ...props.data, userData: userData });

      try {
        await signUp({
          userEmail: userData.email,
          userPassword: userData.pass,
          company_name: props.data.companyData.name,
          website: props.data.companyData.website,
          phone: props.data.companyData.phone,
          comercial_activity: props.data.companyData.activity,
          address: props.data.companyData.address,
          email: userData.email,
          fullname: userData.name,
          job_title: userData.jobTitle,
          code: props.data.code,
        });

        history.push({
          pathname: "/register/send-code",
        });
      } catch (err) {
        setLoading(false);
        setProcessStatus(true);
        if (err.code === "UsernameExistsException") {
          setProcessStatusMessage(
            "Fallo en el registro, el correo seleccionado ha sido registrado previamente"
          );
        } else if (err.code === "InvalidPasswordException") {
          setProcessStatusMessage(
            "La contraseña seleccionada no cumple con el formato requerido (8 caracteres)"
          );
        }
      }
    },
    [history, props]
  );

  useEffect(() => {
    setTimeout(() => setError(false), 1000);
  }, [error]);

  useEffect(() => {
    const listener = (event) => {
      listenerEnter({
        event: event,
        array: [
          document.getElementById("user_name").value,
          document.getElementById("user_job_title").value,
          document.getElementById("user_email").value,
          document.getElementById("user_pass").value,
        ],
        actionSuccess: () => onSubmit(event),
        actionFailure: () => setError(true),
      });
    };
    document.addEventListener("keydown", listener);
    return () => document.removeEventListener("keydown", listener);
  }, [onSubmit]);

  return (
    <CenteredContainer id="container" error={error}>
      {loading && <Loading />}
      {completed && (
        <Completed
          message={completedMessage}
          close={() => setProcessStatus(false)}
        />
      )}
      <SectionTitle>El administrador</SectionTitle>
      <Description>
        Será la cuenta maestra dentro de la compañía (puede ser cambiada
        posteriormente).
      </Description>
      <form onSubmit={async (event) => onSubmit(event)}>
        <CustomInput
          id="user_name"
          placeholder="Nombre"
          type="text"
          required={true}
        ></CustomInput>
        <CustomInput
          id="user_job_title"
          placeholder="Puesto"
          type="text"
          required={true}
        ></CustomInput>
        <CustomInput
          id="user_email"
          placeholder="Email"
          type="email"
          required={true}
        ></CustomInput>
        <CustomInput
          id="user_pass"
          placeholder="Contraseña (mínimo 8 caracteres)"
          type="password"
          required={true}
          pattern="^.{8,}$"
        ></CustomInput>
        <SubmitButton type="submit" value="Registrar"></SubmitButton>
      </form>
    </CenteredContainer>
  );
}

export function RegisterValidateCode(props) {
  const [loading, setLoading] = useState(false);
  const [completed, setProcessStatus] = useState(false);
  const [completedMessage, setProcessStatusMessage] = useState(null);
  const history = useHistory();

  const [error, setError] = useState(false);

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setLoading(true);
      try {
        await Auth.confirmSignUp(
          props.data.userData.email,
          document.getElementById("verification_code").value
        );

        const session = await Auth.signIn(
          props.data.userData.email,
          props.data.userData.pass
        );
        sessionStorage.setItem("auth", true);

        await getAccountData(session);

        history.push({
          pathname: process.env.REACT_APP_DASHBOARD_ROUTE,
        });
      } catch (error) {
        setLoading(false);
        setProcessStatus(true);
        if (error.code === "NotAuthorizedException") {
          setProcessStatusMessage("Correo o contraseña incorrectos");
          alert("Correo o contraseña incorrectos");
        } else if (error.code === "UserNotConfirmedException") {
          setProcessStatusMessage("Correo no ha sido validado");
        } else if (error.code === "CodeMismatchException") {
          setProcessStatusMessage(
            "El código de verificación ingresado es inválido, favor de intentar nuevamente"
          );
        }
      }
    },
    [history, props.data.userData.email, props.data.userData.pass]
  );

  useEffect(() => {
    setTimeout(() => setError(false), 1000);
  }, [error]);

  useEffect(() => {
    const listener = (event) => {
      listenerEnter({
        event: event,
        array: [document.getElementById("verification_code").value],
        actionSuccess: () => onSubmit(event),
        actionFailure: () => setError(true),
      });
    };
    document.addEventListener("keydown", listener);
    return () => document.removeEventListener("keydown", listener);
  }, [onSubmit]);

  return (
    <CenteredContainer id="container" error={error}>
      {loading && <Loading />}
      {completed && (
        <Completed
          message={completedMessage}
          close={() => setProcessStatus(false)}
        />
      )}
      <SectionTitle>Código de verificación</SectionTitle>
      <Description>
        Hemos enviado un código de verificación al correo especificado con la
        finalidad de validar tu cuenta.
      </Description>
      <form onSubmit={(event) => onSubmit(event)}>
        <CustomInput
          id="verification_code"
          placeholder="Código de verificación"
          type="text"
          required={true}
          pattern="^.{6,}$"
        ></CustomInput>
        <SubmitButton type="submit" value="Verificar cuenta"></SubmitButton>
      </form>
    </CenteredContainer>
  );
}

async function signUp(params) {
  let email = params.userEmail;
  let username = params.userEmail;
  let password = params.userPassword;

  const user = await Auth.signUp({
    username,
    password,
    attributes: {
      email,
    },
  });

  await axios.post(process.env.REACT_APP_ACCOUNT_ENDPOINT, {
    company_name: params.company_name,
    website: params.website,
    phone: params.phone,
    comercial_activity: params.comercial_activity,
    address: params.address,
    cognito_id: user.userSub,
    email: params.email,
    fullname: params.fullname,
    job_title: params.job_title,
    code: params.code,
  });
}
