import React, { useEffect, useState } from "react";
import styled from "styled-components";

import articleIcon from "../../assets/icons/articlesMetrics.svg";
import movementIcon from "../../assets/icons/movementsMetrics.svg";
import ordersIcon from "../../assets/icons/ordersMetrics.svg";
import canceledOrdersIcon from "../../assets/icons/canceledOrdersMetrics.svg";

const CounterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const Counter = styled.div`
  width: 185px;
  height: 120px;
  border-radius: 10px;
  border: 1px solid #e9eef5;
  background: #fbfdff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CounterIcon = styled.figure`
  position: absolute;
  left: 15px;
  top: 15px;
  width: 32px;
  height: 32px;
  background: white;
  border-radius: 100px;
  img {
    width: 100%;
    height: 100%;
  }
`;

const Value = styled.p`
  font-weight: 600;
  font-size: 20px;
  color: #0e2c5e;
`;

const CounterDescription = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: #8894ac;
  position: absolute;
  bottom: 10px;
  left: 10px;
`;

export default (props) => {
  return (
    <CounterContainer>
      <Counter>
        <CounterIcon>
          <img src={articleIcon} alt="Icon" />
        </CounterIcon>
        <Value>{props.metrics?.lowStockProductsAmount || "--"}</Value>
        <CounterDescription>Productos con stock bajo</CounterDescription>
      </Counter>
      <Counter>
        <CounterIcon>
          <img src={movementIcon} alt="Icon" />
        </CounterIcon>
        <Value>{props.metrics?.movementsAmount || "--"}</Value>
        <CounterDescription>Movimientos registrados</CounterDescription>
      </Counter>
      <Counter>
        <CounterIcon>
          <img src={ordersIcon} alt="Icon" />
        </CounterIcon>
        <Value>{props.metrics?.completedOrdersAmount || "--"}</Value>
        <CounterDescription>Órdenes completadas</CounterDescription>
      </Counter>
      <Counter>
        <CounterIcon>
          <img src={canceledOrdersIcon} alt="Icon" />
        </CounterIcon>
        <Value>{props.metrics?.cancelledOrdersAmount || "--"}</Value>
        <CounterDescription>Órdenes canceladas</CounterDescription>
      </Counter>
    </CounterContainer>
  );
};
