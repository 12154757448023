import React from "react";
import styled from "styled-components";

// svg
import emptyOrders from "../../../assets/icons/emptyOrders.svg";

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    cursor: pointer;
    padding: 15px 30px;
    border-radius: 5px;
    color: white;
    background: #5590ff;
    margin-top: 30px;
  }
  figure {
    width: 80px;
    height: 80px;
    margin-bottom: 30px;
  }
`;

export default () => {
  return (
    <MainContainer>
      <figure>
        <img src={emptyOrders} alt="Sin productos" />
      </figure>
      <p>No hay órdenes registradas</p>
      <button
        onClick={() => window.open(process.env.REACT_APP_POS_APP_ENDPOINT)}
      >
        Registrar orden
      </button>
    </MainContainer>
  );
};
