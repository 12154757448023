import React from "react";
import styled from "styled-components";

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #323946;
  width: 45px;
  height: 100%;
  border-left: 1px solid #99a7bf;
  border-bottom: 1px solid #99a7bf;
  box-sizing: border-box;
  display: none;
  & input {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
`;

const CheckboxTool = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  background: white;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.2);
  position: fixed;
  height: 70px;
  bottom: 30px;
  left: 50%;
  transform: translate(-70%, 0);
  overflow: hidden;
`;

const SelectedElementsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: fit-content;
`;

const QtyElementsSelected = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100%;
  font-size: 20px;
  font-weight: 600;
  background: #5590ff;
  color: white;
`;

const ElementsSelectedLabel = styled.p`
  color: black;
  margin-left: 25px;
`;

const CheckboxToolActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 100px;
`;

const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
  & p {
    font-size: 12px;
    font-weight: 500;
  }
  & figure {
    width: 30px;
    margin-bottom: 5px;
  }
`;

export const showCheckbox = (event) => {
  const checkbox = event.target.parentNode.getElementsByClassName("checkbox");
  try {
    checkbox[0].style.display = "flex";
  } catch (err) {}
};

export const hideCheckbox = (event) => {
  let checkboxes = document.getElementsByClassName("cb");
  const checked = [];
  for (let i = 0; i < checkboxes.length; i++) {
    if (checkboxes[i].checked) {
      checked.push(checkboxes[i]);
    }
  }

  checkboxes = document.getElementsByClassName("checkbox");
  if (checked.length === 0) {
    const checkbox = event.target.parentNode.getElementsByClassName("checkbox");
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].style.display = "none";
    }
    try {
      checkbox[0].style.display = "none";
    } catch (err) {}
  }
};

export const checkboxSelected = (props) => {
  if (!props.activated) {
    props.callback();
  }

  let checkboxes = document.getElementsByClassName("cb");
  const checked = [];
  for (let i = 0; i < checkboxes.length; i++) {
    if (checkboxes[i].checked) {
      checked.push(checkboxes[i]);
    }
  }

  const checkboxDiv = document.getElementsByClassName("checkbox");
  if (checked.length > 0) {
    for (let i = 0; i < checkboxDiv.length; i++) {
      checkboxDiv[i].style.display = "flex";
    }
  } else {
    for (let i = 0; i < checkboxDiv.length; i++) {
      checkboxDiv[i].style.display = "none";
    }
  }

  if (props.activated && checked.length === 0) {
    props.callback();
  }
};

export function Checktool(props) {
  return props.selected ? (
    <CheckboxTool>
      <SelectedElementsContainer>
        <QtyElementsSelected>1</QtyElementsSelected>
        <ElementsSelectedLabel>
          Elemento(s) seleccionado(s)
        </ElementsSelectedLabel>
      </SelectedElementsContainer>
      <CheckboxToolActions>
        <Action>
          <figure>
            <img src="../edit.svg" alt="Edit"></img>
          </figure>
          <p>Mover</p>
        </Action>
        <Action>
          <figure>
            <img src="../delete.svg" alt="Edit"></img>
          </figure>
          <p>Eliminar</p>
        </Action>
      </CheckboxToolActions>
    </CheckboxTool>
  ) : null;
}

export function Checkbox(props) {
  return (
    <CheckboxContainer className="checkbox">
      <input
        className="cb"
        type="checkbox"
        onChange={() => {
          checkboxSelected(props);
        }}
      />
    </CheckboxContainer>
  );
}
