import React from "react";
import styled from "styled-components";

const MainContainer = styled.div`
  width: 100%;
  position: fixed;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
`;

const CenteredContainer = styled.div`
  width: 330px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 5px;
  & figure {
    width: 35px;
    height: 35px;
    margin-bottom: 20px;
  }
  & img {
    width: 100%;
    height: 100%;
  }
  & p {
    text-align: center;
    padding: 0 20px;
  }
`;

const LoadingImageContainer = styled.figure`
  margin: 0;
  border-radius: 5px;
  width: 65px;
  height: 65px;
`;

export function Loading() {
  return (
    <MainContainer id="popup_background">
      <LoadingImageContainer>
        <img src="/loading.gif" alt="Loading"></img>
      </LoadingImageContainer>
    </MainContainer>
  );
}

export function Completed(props) {
  let message;
  if (props.message) {
    message = props.message;
  } else if (props.type === "SUCCESS") {
    message = "Completado con éxito";
  } else {
    message = "Lo sentimos, ha habido un error";
  }

  return (
    <MainContainer
      id="popup_background"
      onClick={(event) => {
        if (event.target === document.getElementById("popup_background")) {
          props.close();
        }
      }}
    >
      <CenteredContainer>
        <figure>
          <img
            src={props.type === "SUCCESS" ? "/checked.svg" : "/close.svg"}
            alt="Popup Type"
          ></img>
        </figure>
        <p>{message}</p>
      </CenteredContainer>
    </MainContainer>
  );
}
