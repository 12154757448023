import React, { useState, useEffect } from "react";
import axios from "axios";
import styled, { keyframes } from "styled-components";
import { useHistory } from "react-router-dom";

import TableInput from "../SmartInputs/TableInput.js";
import TableCell from "../SmartInputs/TableCell.js";
import { Loading } from "../GenericPopup";
import { getMovementData } from "../_utils/data.js";

import emptyOrders from "../../assets/icons/emptyMovements.svg";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 115px;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  flex-shrink: 0;
  box-sizing: border-box;
  & form {
    width: fit-content;
  }
  & button {
    width: fit-content;
  }
`;

const Table = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 500px;
  padding: 15px 30px;
  box-sizing: border-box;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TableHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  border: 1px solid #e9eef5;
  border-radius: 28px;
`;

const MovementsContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #e9eef5;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  p {
    color: #0e2c5e;
  }
`;

const animate = keyframes`
 0% { right: -400px}
 10% { right: 30px}
 90% { right: 30px}
 100% { right: -400px}
`;

const MissingValuesWarning = styled.div`
  z-index: 100;
  background: #323946;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 350px;
  min-height: 60px;
  position: fixed;
  top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  animation-name: ${animate};
  animation-duration: 4s;
  animation-iteration-count: 1;
  right: -400px;
  & p {
    color: white;
    text-align: center;
    margin: 10px 0 0 0;
  }
`;

const Mode = styled.p`
  font-weight: 500;
  font-size: 22px;
  color: #323946;
  padding: 15px 25px;
  border: 2px solid #323946;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const EmptyContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.figure`
  width: 80px;
  height: 80px;
  margin-bottom: 30px;
`;

const EmptyActionButton = styled.button`
  padding: 15px 30px;
  border-radius: 5px;
  color: white;
  background: #5590ff;
  margin-top: 30px;
`;

export default function Movements() {
  const history = useHistory();
  const movementsPermissions =
    JSON.parse(sessionStorage.getItem("permissions")).find(
      (e) => e.area === "Movimientos"
    ) !== undefined;

  const [movements, setMovements] = useState([]);
  const [typeMode, setTypeModeState] = useState("REGISTRY");
  const [movementsExecution, setMovementsExecutionState] = useState(0);
  const [missingValues, setMissingValuesState] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentUser = JSON.parse(sessionStorage.getItem("user"));
  const inputValuesBasicStructure = () => {
    return {
      article: { inputValue: "" },
      warehouse: { inputValue: "" },
      input_output: {
        type: JSON.parse(sessionStorage.getItem("movement_input_output_type"))
          ? "Entrada"
          : "Salida",
        inputValue: "",
      },
      quantity: { quantity: 1, inputValue: "" },
      responsable: { ...currentUser, inputValue: currentUser.fullname },
      date: { currentDate: true, inputValue: "" },
    };
  };
  const [valuesSelected, setInputValueSelected] = useState(
    inputValuesBasicStructure()
  );

  useEffect(() => {
    if (movementsPermissions && movementsExecution === 0) {
      getMovementData({ force: true }).then((response) => {
        setMovements(response);
        setMovementsExecutionState(movementsExecution + 1);
      });
    }

    if (!movementsPermissions) {
      history.push({
        pathname: process.env.REACT_APP_POSS_ROUTE,
      });
    }

    if (typeMode === "SEARCH") {
      getMovementData({
        query: `?${
          valuesSelected.article.id
            ? `article_id=${valuesSelected.article.id}&`
            : ""
        }${
          valuesSelected.warehouse.id
            ? `warehouse_id=${valuesSelected.warehouse.id}&`
            : ""
        }${
          valuesSelected.input_output.type
            ? `type=${
                valuesSelected.input_output.type === "Entrada"
                  ? "INPUT"
                  : "OUTPUT"
              }&`
            : ""
        }${
          valuesSelected.responsable.cognito_id
            ? `cognito_id=${valuesSelected.responsable.cognito_id}&`
            : ""
        }`,
      }).then((response) => {
        setMovements(response);
      });
    }
  }, [
    history,
    movementsExecution,
    typeMode,
    valuesSelected,
    movementsPermissions,
  ]);

  const registerMovement = async () => {
    if (
      valuesSelected.article.id &&
      (valuesSelected.input_output.type === "Salida" ||
        valuesSelected.input_output.type === "Entrada") &&
      valuesSelected.quantity.quantity >= 0.0001 &&
      valuesSelected.responsable.cognito_id &&
      valuesSelected.warehouse.id
    ) {
      setLoading(true);
      const data = {
        article_id: valuesSelected.article.id,
        article_type: valuesSelected.article.type,
        warehouse_id: valuesSelected.warehouse.id,
        cognito_id: valuesSelected.responsable.cognito_id,
        type: valuesSelected.input_output.type,
        quantity: valuesSelected.quantity.quantity,
      };
      try {
        await axios.post(process.env.REACT_APP_MOVEMENT_ENDPOINT, data);
        setLoading(false);
        getMovementData({ force: true }).then((response) => {
          setMovements(response);
        });
      } catch (err) {
        // TODO IF ERROR: Stop loading, show error message
        setLoading(false);
      }
    } else {
      if (!missingValues) {
        setMissingValuesState(true);
        setTimeout(() => setMissingValuesState(false), 4000);
      }
    }
  };

  const cleanFilters = () => {
    setInputValueSelected({
      article: { inputValue: "" },
      warehouse: { inputValue: "" },
      input_output: { type: null, inputValue: "Todos" },
      quantity: { quantity: 1, inputValue: "" },
      responsable: { inputValue: "" },
      date: { currentDate: true, inputValue: "" },
    });
  };

  const cells = [];
  for (let i = 0; i < movements.length; i++) {
    cells.push(
      <TableRow key={i}>
        <TableCell title={movements[i].article} width="25%"></TableCell>
        <TableCell title={movements[i].warehouse} width="15%"></TableCell>
        <TableCell
          title={movements[i].type === "INPUT" ? "Entrada" : "Salida"}
          width="8%"
        ></TableCell>
        <TableCell
          title={parseFloat(movements[i].quantity)}
          width="10%"
        ></TableCell>
        <TableCell title={movements[i].responsable} width="22%"></TableCell>
        <TableCell
          title={new Date(movements[i].timestamp).toLocaleString()}
          width="20%"
        ></TableCell>
      </TableRow>
    );
  }

  return (
    <MainContainer>
      {missingValues && (
        <MissingValuesWarning>
          <figure>
            <img src="../notFound.svg" alt="Not Found"></img>
          </figure>
          <p>Favor de completar todos los campos</p>
        </MissingValuesWarning>
      )}
      {loading && <Loading />}
      {movementsPermissions && (
        <>
          <HeaderContainer>
            <Mode
              onClick={() => {
                if (typeMode === "REGISTRY") {
                  cleanFilters();
                  setTypeModeState("SEARCH");
                } else {
                  setInputValueSelected(inputValuesBasicStructure());
                  getMovementData({ force: true }).then((response) =>
                    setMovements(response)
                  );
                  setTypeModeState("REGISTRY");
                }
              }}
            >
              {typeMode === "REGISTRY" ? "Modo registro" : "Modo búsqueda"}
            </Mode>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                if (typeMode === "REGISTRY") {
                  registerMovement();
                } else {
                  cleanFilters();
                }
              }}
            >
              <button id="register_movement" type="submit">
                {typeMode === "REGISTRY" ? "Añadir" : "Limpiar filtros"}
              </button>
            </form>
          </HeaderContainer>
          <Table>
            <TableHeader>
              <TableInput
                title="Producto"
                type="text"
                popupType="PRODUCT_SEARCH"
                first={true}
                inputValue={valuesSelected?.article.inputValue}
                saveValue={(value, displayedValue) => {
                  setInputValueSelected((prevState) => ({
                    ...prevState,
                    article: { ...value, inputValue: displayedValue },
                  }));
                  document.getElementById("warehouse_input").focus();
                }}
                width="25%"
              ></TableInput>
              <TableInput
                className="bleft"
                title="Bodega"
                type="text"
                popupType="WAREHOUSE_BOX"
                inputValue={valuesSelected?.warehouse.inputValue}
                saveValue={(value, displayedValue) => {
                  setInputValueSelected((prevState) => ({
                    ...prevState,
                    warehouse: { ...value, inputValue: displayedValue },
                  }));
                  document.getElementById("input_output").focus();
                }}
                width="15%"
              ></TableInput>
              <TableInput
                title="Tipo"
                type="text"
                className="bleft"
                defaultValue={
                  typeMode === "REGISTRY"
                    ? JSON.parse(
                        sessionStorage.getItem("movement_input_output_type")
                      )
                      ? "Entrada"
                      : "Salida"
                    : valuesSelected.input_output.inputValue
                }
                disabled
                popupType="INPUT_OUTPUT"
                inputValue={valuesSelected?.input_output.type}
                saveValue={(value, displayedValue) =>
                  setInputValueSelected((prevState) => ({
                    ...prevState,
                    input_output: { ...value, inputValue: displayedValue },
                  }))
                }
                width="8%"
              ></TableInput>
              <TableInput
                title="Cantidad"
                type="number"
                className="bleft"
                disabled={typeMode === "REGISTRY" ? false : true}
                defaultValue={typeMode === "REGISTRY" ? 1 : null}
                min="0.0001"
                inputValue={valuesSelected?.quantity.inputValue}
                saveValue={(value, displayedValue) =>
                  setInputValueSelected((prevState) => ({
                    ...prevState,
                    quantity: { quantity: value, inputValue: displayedValue },
                  }))
                }
                width="10%"
              ></TableInput>
              <TableInput
                title="Responsable"
                type="text"
                width="22%"
                className="bleft"
                disabled={typeMode === "REGISTRY" ? true : false}
                inputValue={valuesSelected?.responsable.inputValue}
                saveValue={(value, displayedValue) => {
                  setInputValueSelected((prevState) => ({
                    ...prevState,
                    responsable: { ...value, inputValue: displayedValue },
                  }));
                  document.getElementById("register_movement").focus();
                }}
                popupType="EMPLOYEE"
              ></TableInput>
              <TableInput
                title="Fecha"
                type="text"
                last={true}
                className="bleft"
                defaultValue={
                  typeMode === "REGISTRY"
                    ? "Fecha y hora actual"
                    : "Todo el tiempo"
                }
                disabled
                inputValue={valuesSelected?.date.inputValue}
                width="20%"
              ></TableInput>
            </TableHeader>
            {movements.length >= 1 ? (
              <MovementsContainer>{cells}</MovementsContainer>
            ) : (
              <EmptyContent>
                <Icon>
                  <img src={emptyOrders} alt="Sin productos" />
                </Icon>
                <p>No hay movimientos registrados</p>
                <EmptyActionButton
                  onClick={() =>
                    document
                      .getElementById("product_input")
                      .getElementsByTagName("input")[0]
                      .focus()
                  }
                >
                  Registrar movimiento
                </EmptyActionButton>
              </EmptyContent>
            )}
          </Table>
        </>
      )}
    </MainContainer>
  );
}
