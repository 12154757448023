import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { isElementClicked } from "./_utils/helper";

const OverrideButtonValues = styled.button`
  border-radius: 0 !important;
  background: none !important;
  border: none !important;
  font-family: "Roboto" !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  padding: 0 !important;
  border-radius: 0 !important;
`;

const CustomInputContainer = styled(OverrideButtonValues)`
  position: relative;
  margin: 15px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  .z10 {
    z-index: 10;
  }
  & input {
    width: 100%;
    height: 55px;
    border: 1px solid #99a7bf;
    border-radius: 5px;
    padding-left: 20px;
    box-sizing: border-box;
    outline: none;
    font-family: "Roboto";
    font-size: 15px;
    color: #323946;
    padding-right: 10px;
  }
  & textarea {
    width: 100%;
    height: 80px;
    resize: none;
    border: 1px solid #99a7bf;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
    outline: none;
    font-family: "Roboto";
    font-size: 15px;
    color: #323946;
  }
  & > p {
    position: absolute;
    top: 13px;
    left: 20px;
    font-weight: 400;
    color: #99a7bf;
    background: white;
    padding: 5px;
    transition: top 0.15s ease-out;
    z-index: 0;
  }
  & figure {
    position: relative;
    cursor: pointer;
    width: 50px;
    height: 55px;
    padding: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    z-index: 1;
    box-sizing: border-box;
  }
`;

const HelperText = styled.p`
  position: absolute;
  width: max-content;
  max-width: 400px;
  padding: 15px;
  background: #dfe2e6;
  color: #323946;
  box-shadow: -2px 4px 5px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  transform: translate(-90%, 0);
  top: 50px;
  right: -400px;
  font-size: 14px;
  font-weight: 500;
  display: none;
`;

const Dropdown = styled.select`
  width: 100%;
  height: 55px;
  border: 1px solid #99a7bf;
  border-radius: 5px;
  padding-left: 20px;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto";
  font-size: 15px;
  color: #323946;
  padding-right: 10px;
`;

const CustomDropdown = styled.input`
  cursor: default;
  position: relative;
  width: 100%;
  height: 55px;
  background-color: #fff;
  border: 1px solid #99a7bf;
  border-radius: 5px;
  padding-left: 20px;
  box-sizing: border-box;
  font-family: "Roboto";
  font-size: 15px;
  color: #323946;
  padding-right: 10px;
`;

const ContentDropdown = styled.div`
  position: absolute;
  padding: 30px 20px;
  box-sizing: border-box;
  max-height: 200px;
  width: 165%;
  overflow-y: auto;
  top: 100%;
  left: -50%;
  right: 0;
  z-index: 10;
  border-radius: 10px;
  background-color: #323946;
  font-family: Gotham;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  cursor: default;
  > p:first-child {
    width: fit-content;
    text-align: left;
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;
  }
`;

const ItemRole = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  height: 36px;
  box-sizing: border-box;
  color: #fff;
`;

const Checkbox = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 16px;
  border: solid 2px #ffffff;
  background-color: ${(props) => (props.selected ? "#2bde73" : "#FFF")};
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
`;

const PermissionsLabel = styled.p`
  color: white;
  font-size: 11px;
  letter-spacing: 0.5px;
`;

export default function CustomInput(props) {
  const [inputValue, setInputValue] = useState(props.value);
  const [firstExecution, setExecutionState] = useState(true);
  const [focus, setFocus] = useState(false);

  const clickOutsideToClose = useCallback((evt) => {
    if (!isElementClicked(evt, "list")) {
      document.removeEventListener("click", clickOutsideToClose);
      setFocus(false);
    } else if (!isElementClicked(evt, "CustomRoleContainer")) {
      document.removeEventListener("click", clickOutsideToClose);
      setFocus(false);
    }
  }, []);

  useEffect(() => {
    if (firstExecution) {
      onFocus(props.id);
      if (inputValue) {
        onFocus(props.id);
      }
      setExecutionState(false);
    }
  }, [firstExecution, inputValue, props.id]);

  useEffect(() => {
    if (props.type === "rolDropdown") {
      if (focus) {
        document.addEventListener("click", clickOutsideToClose);
      } else {
        document.removeEventListener("click", clickOutsideToClose);
      }
    }
  }, [props.type, focus, clickOutsideToClose]);

  function printPermisos(permisosDelRol) {
    let cadenaPermisos = "";
    if (permisosDelRol !== undefined) {
      let hayPermisos =
        permisosDelRol.read ||
        permisosDelRol.create ||
        permisosDelRol.update ||
        permisosDelRol.delete;
      if (hayPermisos) {
        cadenaPermisos = cadenaPermisos + "(";
        if (permisosDelRol.read) {
          cadenaPermisos = cadenaPermisos + "Leer";
        }
        if (permisosDelRol.create) {
          permisosDelRol.read
            ? (cadenaPermisos = cadenaPermisos + ", Escribir")
            : (cadenaPermisos = cadenaPermisos + "Escribir");
        }
        if (permisosDelRol.update) {
          if (permisosDelRol.create || permisosDelRol.read) {
            cadenaPermisos = cadenaPermisos + ", Actualizar";
          } else {
            cadenaPermisos = cadenaPermisos + "Actualizar";
          }
        }
        if (permisosDelRol.delete) {
          if (
            permisosDelRol.create ||
            permisosDelRol.read ||
            permisosDelRol.update
          ) {
            cadenaPermisos = cadenaPermisos + ", Eliminar";
          } else {
            cadenaPermisos = cadenaPermisos + "Eliminar";
          }
        }
        cadenaPermisos = cadenaPermisos + ")";
      }
    }
    return cadenaPermisos;
  }

  function onFocus(element) {
    document.getElementById(element).focus();
    if (document.getElementById(element).type === "textarea")
      document
        .getElementById(element)
        .parentNode.getElementsByTagName("p")[0].style.top = "-24px";
    else
      document
        .getElementById(element)
        .parentNode.getElementsByTagName("p")[0].style.top = "-12px";
  }

  function onBlur(element) {
    const target = document.getElementById(element);
    try {
      if (!target.value) {
        target.parentNode.getElementsByTagName("p")[0].style.top = "12px";
      } else {
        if (document.getElementById(element).type === "textarea")
          document
            .getElementById(element)
            .parentNode.getElementsByTagName("p")[0].style.top = "-24px";
        else
          document
            .getElementById(element)
            .parentNode.getElementsByTagName("p")[0].style.top = "-12px";
      }
    } catch (err) {
      console.log(err);
    }
  }

  function showHelper() {
    try {
      document.getElementById("helper_text").style.display = "block";
    } catch (err) {
      console.log(err);
    }
  }

  function hideHelper() {
    try {
      document.getElementById("helper_text").style.display = "none";
    } catch (err) {
      console.log(err);
    }
  }

  const textarea = (
    <textarea
      id={props.id}
      pattern={props.pattern}
      required={props.required}
      onChange={(event) => {
        onBlur(props.id);
        setInputValue(event.target.value);
      }}
      value={inputValue}
    ></textarea>
  );
  const input = (
    <input
      id={props.id}
      pattern={props.pattern}
      required={props.required}
      type={props.type}
      onChange={(event) => {
        onBlur(props.id);
        setInputValue(event.target.value);
      }}
      value={inputValue}
      min={props.min}
      step="any"
    ></input>
  );

  const rolDropdown = (
    <CustomDropdown
      id={props.id}
      value={props.value}
      onClick={() => setFocus(!focus)}
    />
  );

  const unitDropdown = (
    <Dropdown
      id={props.id}
      onChange={(event) => {
        onBlur(props.id);
        setInputValue(event.target.value);
      }}
    >
      <option value="PIECE" selected={props.value === "PIECE" ? true : false}>
        Pieza
      </option>
      <option
        value="KILOGRAM"
        selected={props.value === "KILOGRAM" ? true : false}
      >
        Kilo
      </option>
      <option value="LITER" selected={props.value === "LITER" ? true : false}>
        Litro
      </option>
      <option value="METER" selected={props.value === "METER" ? true : false}>
        Metro
      </option>
    </Dropdown>
  );

  const moneyDropdown = (
    <Dropdown
      id={props.id}
      onChange={(event) => {
        onBlur(props.id);
        setInputValue(event.target.value);
      }}
    >
      <option value="MXN" selected={props.value === "MXN" ? true : false}>
        Peso Mexicano
      </option>
      <option value="USD" selected={props.value === "USD" ? true : false}>
        Dólar Americano
      </option>
      <option value="EUR" selected={props.value === "EUR" ? true : false}>
        Euro
      </option>
      <option value="GBP" selected={props.value === "GBP" ? true : false}>
        Libra Esterlina
      </option>
      <option value="JPY" selected={props.value === "JPY" ? true : false}>
        Yen Japonés
      </option>
      <option value="CNY" selected={props.value === "CNY" ? true : false}>
        Yuan Chino
      </option>
    </Dropdown>
  );

  const helper = (
    <figure onMouseEnter={showHelper} onMouseLeave={hideHelper}>
      <img src="../helpButton.svg" alt="Helpbox"></img>
      <HelperText className="z10" id="helper_text">
        {props.helper}
      </HelperText>
    </figure>
  );

  const children = (type) => {
    switch (type) {
      case "textarea":
        return textarea;
      case "unitDropdown":
        return unitDropdown;
      case "currencyDropdown":
        return moneyDropdown;
      case "rolDropdown":
        return rolDropdown;
      default:
        return input;
    }
  };

  const [rolList] = useState([
    { name: "Administrador General", selected: false },
    { name: "Administrador Articulos", selected: false },
    { name: "Administrador Bodegas", selected: false },
    { name: "Administrador Movimientos", selected: false },
    { name: "Administrador Puntos de venta", selected: false },
    { name: "Administrador Órdenes", selected: false },
    { name: "Administrador Colaboradores", selected: false },
  ]);

  return (
    <CustomInputContainer
      onMouseEnter={() => onFocus(props.id)}
      onMouseLeave={() => onBlur(props.id)}
      onFocus={() => onFocus(props.id)}
      onBlur={() => onBlur(props.id)}
      onClick={(event) => event.preventDefault()}
    >
      <p className={`${props.type === "rolDropdown" && "z10"}`}>
        {props.placeholder}
      </p>
      {children(props.type)}
      {props.helper && helper}

      {focus && (
        <ContentDropdown id="list">
          <p
            onClick={() => {
              props.setShowCustomRole(true);
              setFocus(false);
            }}
          >
            Personalizar rol
          </p>
          {rolList.map((e, i) => {
            let permisosDelItem = undefined;
            let hayPermisos = false;
            if (e.name !== "Administrador General") {
              permisosDelItem = props.permissions.find(
                (role) => "Administrador " + role.name === e.name
              );
              hayPermisos =
                permisosDelItem.read ||
                permisosDelItem.create ||
                permisosDelItem.update ||
                permisosDelItem.delete;
            } else {
              hayPermisos = props.AG;
            }
            return (
              <ItemRole
                onClick={() => {
                  props.setRole(i);
                }}
                key={i}
              >
                <Checkbox selected={hayPermisos} />
                <div>
                  <p>{e.name}</p>
                  <PermissionsLabel>
                    {printPermisos(permisosDelItem)}
                  </PermissionsLabel>
                </div>
              </ItemRole>
            );
          })}
        </ContentDropdown>
      )}
    </CustomInputContainer>
  );
}
