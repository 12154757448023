import React, { useState } from "react";
import axios from "axios";

// Components
import StepsContainer from "../../StepsContainer";
import { Loading, Completed } from "../../GenericPopup";
import SelectWarehouse from "./SelectWarehouse";
import Inventory from "./Inventory";
import Employees from "./Employees";
import Payment from "./Payment";

export default function PointsOfSaleEdition(props) {
  const [stepSelected, setSectionSelected] = useState(1);
  const [status, setStatus] = useState(null);
  const [pointSaleData, setPointSaleDataState] = useState(
    props.data
      ? props.data
      : {
          warehouse_id: null,
          articles: [],
          sellers: [],
          payments: [],
          ...props.data,
        }
  );

  const sendInformation = async () => {
    try {
      let data = JSON.parse(sessionStorage.getItem("dataPOS"));
      let originalData = JSON.parse(sessionStorage.getItem("posSelected"));
      if (
        data.articles.length > 0 &&
        data.sellers.length > 0 &&
        data.payments.length > 0
      ) {
        if (props.update) {
          data.pos_id = data.pos;
          data.eliminated = { articles: [], payments: [], sellers: [] };
          originalData.articles.map(
            (e) =>
              data.articles.filter((ele) => ele.id === e.id).length === 0 &&
              data.eliminated["articles"].push(e.id)
          );
          originalData.payments.map(
            (e) =>
              data.payments.filter((ele) => ele.id === e.id).length === 0 &&
              data.eliminated["payments"].push(e.id)
          );
          originalData.sellers.map(
            (e) =>
              data.sellers.filter((ele) => ele.id === e.id).length === 0 &&
              data.eliminated["sellers"].push(e.id)
          );
          data.eliminated.articles.length === 0 &&
            delete data.eliminated.articles;
          data.eliminated.payments.length === 0 &&
            delete data.eliminated.payments;
          data.eliminated.sellers.length === 0 &&
            delete data.eliminated.sellers;
        }
        await axios({
          method: props.update ? "put" : "post",
          url: process.env.REACT_APP_POS_ENDPOINT,
          data: data,
          headers: {
            Authorization: sessionStorage.getItem("jwt"),
          },
        });
        delete data.eliminated;
        delete data.pos_id;
        props.update && props.setPOSSelected(data);
        props.update &&
          sessionStorage.setItem("posSelected", JSON.stringify(data));
        sessionStorage.removeItem("dataPOS");
        if (props.callback) {
          props.callback();
        }
        setStatus("SUCCESS");
        setSectionSelected(6);
      } else {
        sessionStorage.removeItem("dataPOS");
        setStatus("ERROR");
        setSectionSelected(6);
      }
    } catch (err) {
      sessionStorage.removeItem("dataPOS");
      setStatus("ERROR");
      setSectionSelected(6);
    }
  };

  const nameSteps = ["Sucursal", "Inventario", "Vendedores", "Método de pago"];

  let render, title;
  switch (stepSelected) {
    case 1:
      title = "Nuevo punto de venta";
      render = (
        <SelectWarehouse
          {...props}
          data={pointSaleData}
          callback={() => setSectionSelected(2)}
        ></SelectWarehouse>
      );
      break;
    case 2:
      title = "Selección de inventario";
      render = (
        <Inventory
          {...props}
          callback={() => setSectionSelected(3)}
          backStep={() => setSectionSelected(1)}
        ></Inventory>
      );
      break;
    case 3:
      title = "Selección de empleados";
      render = (
        <Employees
          {...props}
          callback={() => setSectionSelected(4)}
          backStep={() => setSectionSelected(2)}
        ></Employees>
      );
      break;
    case 4:
      title = "Método de pago";
      render = (
        <Payment
          {...props}
          callback={() => {
            setSectionSelected(5);
            sendInformation();
          }}
        />
      );
      break;
    case 5:
      render = <Loading />;
      break;
    case 6:
      render = (
        <Completed
          type={status}
          message={
            status === "SUCCESS"
              ? "Punto de venta guardado exitosamente"
              : "Lo sentimos, hubo un error al guardar el punto de venta"
          }
          close={props.close}
        ></Completed>
      );
      break;
    default:
      break;
  }

  return (
    <StepsContainer
      close={props.close}
      stepSelected={stepSelected}
      limitStep={4}
      title={title}
      nameSteps={nameSteps}
      setSectionSelected={setSectionSelected}
      stepFunctions={[]}
      inputsRequired={[]}
      saveData={setPointSaleDataState}
      itemName={`dataPOS`}
      render={render}
    ></StepsContainer>
  );
}
