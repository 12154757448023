import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ContinueButton } from "./styles";
import TableInput from "../../SmartInputs/TableInput.js";

const Container = styled.div`
  width: 100%;
`;

const InventoryDetails = styled.div`
  width: 100%;
  padding: 25px 22px 12px 12px;
  font-weight: 500;
  box-sizing: border-box;
  > div {
    width: 85%;
    display: flex;
    justify-content: space-between;
  }
  p {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 13px;
  }
`;

const Scroll = styled.div`
  overflow-y: auto;
  height: 200px;
  > div:last-child {
    border-width: 1px;
  }
`;

const ItemGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 61px;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #99a7bf;
`;

const SecondaryButton = styled.p`
  margin-left: 30px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  padding: 15px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const Delete = styled.figure`
  width: 15%;
  display: flex;
  justify-content: center;
  > img {
    width: 30px;
    height: 30px;
  }
`;

const Item = styled.div`
  width: 85%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
  > p {
    display: flex;
    align-items: center;
    font-family: "Roboto";
    font-size: 13px;
  }
  > input {
    padding: 3px 5px;
    width: 70px;
    border-bottom: 1px solid lightgray;
    text-align: right;
  }
  > input:focus {
    border-bottom: 2px solid lightblue;
  }
`;

export default (props) => {
  const [inventoryGroup, setInventoryGroup] = useState(
    JSON.parse(sessionStorage.getItem("dataPOS")).articles
  );

  useEffect(() => {
    let temp = JSON.parse(sessionStorage.getItem("dataPOS"));
    temp.articles = inventoryGroup;
    sessionStorage.setItem("dataPOS", JSON.stringify(temp));
  }, [inventoryGroup, props]);

  const saveValue = (value) => {
    if (inventoryGroup.filter((e) => e.id === value.id).length === 0) {
      setInventoryGroup((prevState) => [...prevState, value]);
    }
  };

  return (
    <Container>
      <TableInput
        type="text"
        allBorders
        textCenter
        popupType="PRODUCT_SEARCH"
        design={"v2"}
        inputValue={"Buscar artículo por nombre o SKU"}
        width="100%"
        saveValue={(value) => saveValue(value)}
      ></TableInput>
      <InventoryDetails>
        <div>
          <p>Artículo</p>
          <p>Precio</p>
        </div>
      </InventoryDetails>
      <Scroll>
        {inventoryGroup.map((e, i) => {
          return (
            <>
              <ItemGroup key={i}>
                <Item>
                  <p>{e.name}</p>
                  <input
                    type="number"
                    min="0"
                    value={e.price ? e.price : ""}
                    onChange={(e) => {
                      let temp = inventoryGroup.slice();
                      temp[i].price = e.target.value;
                      setInventoryGroup(temp);
                    }}
                  />
                </Item>
                <Delete
                  onClick={() => {
                    let temp = inventoryGroup.slice();
                    temp.splice(i, 1);
                    setInventoryGroup(temp);
                  }}
                >
                  <img src="../close.svg" alt="Quitar" />
                </Delete>
              </ItemGroup>
            </>
          );
        })}
      </Scroll>
      <ButtonsContainer>
        <ContinueButton onClick={() => props.callback()}>
          Continuar
        </ContinueButton>
        <SecondaryButton onClick={() => props.backStep()}>
          Regresar
        </SecondaryButton>
      </ButtonsContainer>
    </Container>
  );
};
