import React, { useState, useEffect } from "react";
import NavSteps from "./NavSteps";
import { listenerEnterForSteps } from "./_utils/helper";

// Styles
import {
  FullScreenContainer,
  CenteredContainer,
  Title,
} from "./Articles/edition/styles";

export default (props) => {
  return (
    <FullScreenContainer
      id="fullscreen_container"
      onClick={(event) => {
        if (event.target !== document.getElementById("fullscreen_container")) {
          return;
        }
        props.itemName && sessionStorage.removeItem(props.itemName);
        props.close();
      }}
      children={
        props.stepSelected <= props.limitStep ? (
          <Container {...props} />
        ) : (
          props.render
        )
      }
    ></FullScreenContainer>
  );
};

function Container(props) {
  const [error, setError] = useState(false);

  useEffect(() => {
    const stepsContainerListener = (event) => {
      listenerEnterForSteps({
        event: event,
        inputsRequired: props.stepSelected === 1 ? props.inputsRequired : [],
        actionSuccess: () => {
          let action = props.stepFunctions.filter(
            (e) => e.step === props.stepSelected
          )[0];
          action !== undefined && action.action();
          props.setSectionSelected(props.stepSelected + 1);
        },
        actionFailure: () => setError(true),
      });
    };
    const el = document.getElementById("container");
    if (el) {
      el.addEventListener("keydown", stepsContainerListener);
      return () => {
        el.removeEventListener("keydown", stepsContainerListener);
      };
    }
  }, [props]);

  useEffect(() => {
    setTimeout(() => setError(false), 1500);
  }, [error]);

  return (
    <CenteredContainer id="container" error={error}>
      <Title>{props.title}</Title>
      <NavSteps id="parentContainer" {...props} setError={setError} />
      {props.render}
    </CenteredContainer>
  );
}
