import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import styled from "styled-components";

import Dashboard from "./Dashboard/index.js";
import Articles from "./Articles";
import Warehouses from "./Warehouses";
import Movements from "./Movements";
import Employees from "./Employees";
import Profile from "./Profile.js";
import NotFound from "./NotFound.js";
import navItems from "./_utils/navItems";
import PointOfSale from "./Poss";
import Orders from "./Orders/index.js";

// icons
import open from "./../assets/icons/open.svg";

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
`;

const NavContainer = styled.div`
  width: 215px;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #323946;
  padding-bottom: 20px;
  flex-shrink: 0;
  box-sizing: border-box;
  > div {
    width: 100%;
  }
  & a,
  span {
    display: flex;
    width: 100%;
    cursor: pointer;
  }
`;

const NavSection = styled.li`
  display: flex;
  align-items: center;
  height: 50px;
  background: ${(props) =>
    props.background === "SELECTED" && "rgba(43, 222, 115, 0.6)"};
  &:hover {
    background: rgba(43, 222, 115, 0.6);
  }
  > a,
  span {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 40px;
  }
`;

const LogoContainer = styled.figure`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
  height: 60px;
`;

const MenuItem = styled.ul`
  color: #2bde73;
  font-weight: 500;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > div > p {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
    font-weight: 500;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
    > img {
      width: 24px;
      height: 24px;
      transform: ${(props) => (props.open ? "rotate(0deg)" : "rotate(180deg)")};
    }
  }
`;

export default function Main() {
  const [path, setPath] = useState(window.location.pathname);
  const [showMenuItems, setShowItems] = useState(() => {
    let arr = [];
    for (var i = 0; i < navItems.length; i++) {
      arr.push(true);
    }
    return arr;
  });
  const [permissions, setPermissions] = useState();
  const [flag, setFlag] = useState(true);

  const getPermissions = async () => {
    let dict = {};
    JSON.parse(sessionStorage.getItem("permissions")).forEach((e) => {
      if (dict[e.area] === undefined) {
        dict[e.area] = { area: e.area };
      }
    });

    dict = Object.values(dict);

    setPermissions(dict);
    setFlag(false);
  };

  useEffect(() => {
    if (flag) {
      getPermissions();
    }
  }, [flag]);

  useEffect(() => {
    setShowItems(showMenuItems);
  }, [showMenuItems]);

  useEffect(() => {
    setPath(window.location.pathname);
    // eslint-disable-next-line
  }, [window.location.pathname]);

  return (
    <Router>
      <MainContainer>
        <NavContainer>
          <div>
            <LogoContainer>
              <img src="../vertical-logo.svg" alt="Logo"></img>
            </LogoContainer>
            <NavSection
              key="dashboard"
              background={
                window.location.pathname === "/dashboard" ? "SELECTED" : null
              }
            >
              <StyledLink
                to={process.env.REACT_APP_DASHBOARD_ROUTE}
                onClick={() => {
                  setPath(process.env.REACT_APP_DASHBOARD_ROUTE);
                }}
              >
                <p>Panel de control</p>
              </StyledLink>
            </NavSection>
            {navItems.map((e, i) => {
              return (
                <MenuItem key={i} open={showMenuItems[i]}>
                  <div
                    onClick={() => {
                      let a = showMenuItems.slice();
                      a[i] = !a[i];
                      setShowItems(a);
                    }}
                  >
                    <p>
                      {e.name}
                      <img src={open} alt="" />
                    </p>
                  </div>
                  {showMenuItems[i] &&
                    e.items.map((ele, index) => {
                      return (
                        permissions?.filter((perm) => perm.area === ele.name)
                          .length === 1 && (
                          <NavSection
                            key={index}
                            background={
                              path.startsWith(ele.path) ? "SELECTED" : false
                            }
                          >
                            <StyledLink
                              to={ele.path}
                              onClick={() => {
                                setPath(ele.path);
                              }}
                            >
                              <p>{ele.name}</p>
                            </StyledLink>
                          </NavSection>
                        )
                      );
                    })}
                </MenuItem>
              );
            })}
          </div>
          <div>
            <ul>
              {permissions &&
                permissions.filter((perm) => perm.area === "Colaboradores")
                  .length === 1 && (
                  <NavSection
                    background={
                      path === process.env.REACT_APP_EMPLOYEES_ROUTE
                        ? "SELECTED"
                        : false
                    }
                  >
                    <StyledLink
                      to={process.env.REACT_APP_EMPLOYEES_ROUTE}
                      onClick={() =>
                        setPath(process.env.REACT_APP_EMPLOYEES_ROUTE)
                      }
                    >
                      Colaboradores
                    </StyledLink>
                  </NavSection>
                )}
              <NavSection
                background={
                  path === process.env.REACT_APP_PROFILE_ROUTE
                    ? "SELECTED"
                    : false
                }
              >
                <StyledLink
                  to={process.env.REACT_APP_PROFILE_ROUTE}
                  onClick={() => setPath(process.env.REACT_APP_PROFILE_ROUTE)}
                >
                  Perfil
                </StyledLink>
              </NavSection>
            </ul>
          </div>
        </NavContainer>
        <Switch>
          <Route
            path={process.env.REACT_APP_DASHBOARD_ROUTE}
            component={Dashboard}
          />
          <Route
            path={process.env.REACT_APP_ARTICLES_ROUTE}
            component={Articles}
          />
          <Route
            path={process.env.REACT_APP_WAREHOUSES_ROUTE}
            component={Warehouses}
          />
          <Route
            path={process.env.REACT_APP_MOVEMENTS_ROUTE}
            component={Movements}
          />
          <Route
            path={process.env.REACT_APP_EMPLOYEES_ROUTE}
            component={Employees}
          />
          <Route
            path={process.env.REACT_APP_PROFILE_ROUTE}
            component={Profile}
          />
          <Route
            path={process.env.REACT_APP_POSS_ROUTE}
            component={PointOfSale}
          />
          <Route path={process.env.REACT_APP_ORDERS_ROUTE} component={Orders} />
          <Route path="/" component={NotFound} />
        </Switch>
      </MainContainer>
    </Router>
  );
}
