import React from "react";
import styled from "styled-components";
import ReactImageFallback from "react-image-fallback";

import { getImage } from "../_utils/helper";

import CreateOrder from "./IncentivateOrderCreation";

const MainContainer = styled.div`
  width: 100%;
  border: 1px solid #e9eef5;
  background: #fbfdff;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 15px;
`;

const TableTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #0e2c5e;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
`;

const TableCell = styled.p`
  color: ${(props) => (props.color ? props.color : "#8894ac")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "500")};
  display: flex;
  flex-direction: row;
  width: ${(props) => props.width};
`;

const TableCellTypeImage = styled.figure`
  width: ${(props) => props.width};
  overflow: hidden;
  img {
    border-radius: 5px;
    background: #e9eef5;
    border: 1px solid #e9eef5;
    width: 70%;
    height: 70%;
  }
`;

const TableCellTypeColumn = styled.div`
  width: ${(props) => props.width};
  display: flex;
  flex-direction: column;
  p {
    padding: 2px 0;
  }
`;

function HeaderRow() {
  return (
    <TableRow>
      <TableCell width="6.5%">Foto</TableCell>
      <TableCell width="26%">Nombre</TableCell>
      <TableCell width="15%">Precio</TableCell>
      <TableCell width="19%">Unidades vendidas</TableCell>
      <TableCell width="16%">Existencias</TableCell>
      <TableCell width="17.5%">Estatus</TableCell>
    </TableRow>
  );
}

function ProductsRows(props) {
  const products = props.products;
  const rows = [];
  for (let i = 0; i < products.length; i++) {
    const image = products[i].image
      ? getImage({ location: products[i].image }, 50, 50)
      : null;

    rows.push(
      <TableRow>
        <TableCellTypeImage width="6.5%">
          <ReactImageFallback
            src={image}
            fallbackImage="/not-found.svg"
            alt="Product"
          />
        </TableCellTypeImage>
        <TableCellTypeColumn width="26%">
          <TableCell color="#0E2C5E">{products[i].name}</TableCell>
          <TableCell fontSize="12px">SKU: {products[i].sku}</TableCell>
        </TableCellTypeColumn>
        <TableCell width="15%" fontWeight="500" color="#5590ff">
          $
          {products[i].price
            ? parseFloat(products[i].price).toFixed(2)
            : "0.00"}
        </TableCell>
        <TableCell width="19%" color="#0E2C5E">
          {products[i].soldUnits ? products[i].soldUnits : "0"} Unidades
        </TableCell>
        <TableCell width="16%" color="#0E2C5E">
          {products[i].stock ? products[i].stock : "0"} Unidades
        </TableCell>
        <TableCell width="17.5%" color="#0E2C5E">
          {products[i].stockStatus ? products[i].stockStatus : "--"}
        </TableCell>
      </TableRow>
    );
  }

  return rows;
}

export const ProductsSold = (props) => {
  return (
    <MainContainer>
      <TableTitle>{props.title}</TableTitle>
      {props.products?.length > 0 ? (
        <>
          <HeaderRow></HeaderRow>
          <ProductsRows products={props.products}></ProductsRows>
        </>
      ) : (
        <CreateOrder></CreateOrder>
      )}
    </MainContainer>
  );
};
