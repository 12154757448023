import React, { useEffect } from "react";
import styled from "styled-components";

import CustomInput from "../../CustomInput";
import { EmptySpace, ContinueButton } from "./styles";

const CenterInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default (props) => {
  useEffect(() => {
    document.getElementById("article_name").focus();
  }, []);
  return (
    <React.Fragment>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          props.onSubmitFirstStep();
          props.callback();
        }}
      >
        <CustomInput
          id="article_name"
          placeholder="Nombre del artículo"
          type="text"
          required={true}
          value={props.data.name}
        ></CustomInput>
        <CenterInputsContainer>
          <CustomInput
            id="article_sku"
            placeholder="SKU"
            type="text"
            value={props.data.sku}
          ></CustomInput>
          {!props.fictional && (
            <>
              <EmptySpace></EmptySpace>
              <CustomInput
                id="article_min_amount"
                placeholder="Alarma"
                type="number"
                helper="Se emite una alerta al alcanzar la cantidad de unidades mínimas especificadas para el producto"
                value={
                  props.data.minimum_amount ? props.data.minimum_amount : null
                }
              ></CustomInput>{" "}
            </>
          )}
        </CenterInputsContainer>
        <CenterInputsContainer>
          <CustomInput
            id="article_unit_type"
            placeholder="Unidad de medida"
            type="unitDropdown"
            value={props.data.unit_measure}
          ></CustomInput>
          <EmptySpace></EmptySpace>
          <CustomInput
            id="article_currency_type"
            placeholder="Divisa"
            type="currencyDropdown"
            value={props.data.currency}
          ></CustomInput>
        </CenterInputsContainer>
        <CenterInputsContainer>
          <CustomInput
            id="article_price_buy"
            placeholder="Precio de compra"
            type="number"
            min="0"
            value={parseFloat(props.data.cost ? props.data.cost : null)}
          ></CustomInput>
          <EmptySpace></EmptySpace>
          <CustomInput
            id="article_price_sell"
            placeholder="Precio de venta"
            type="number"
            min="0"
            value={parseFloat(props.data.price ? props.data.price : null)}
          ></CustomInput>
        </CenterInputsContainer>
        <CustomInput
          id="article_notes"
          placeholder="Notas"
          type="textarea"
          value={props.data.notes}
        ></CustomInput>
        <ContinueButton type="submit">Continuar</ContinueButton>
      </form>
    </React.Fragment>
  );
};
