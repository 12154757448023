import React from "react";
import styled from "styled-components";

const FullScreenContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  z-index: 1;
`;

const CenteredContainer = styled.div`
  position: fixed;
  overflow-y: auto;
  max-height: 600px;
  width: 400px;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  background: white;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
`;

const Title = styled.p`
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px; ;
`;

const Description = styled.p`
  font-size: 14px;
  color: #323946;
  font-weight: 300;
  max-width: 350px;
  text-align: center;
  margin-bottom: 15px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  margin-top: 50px;
`;

const PrimaryButton = styled.button`
  padding: 12px 25px 14px 25px;
  color: white;
  background: #fb5b5a;
  border-radius: 100px;
  font-weight: 500;
  align-self: flex-start;
`;

const SecondaryButton = styled.p`
  margin-left: 30px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  padding: 15px;
`;

export default function DeletePopup(props) {
  return (
    <FullScreenContainer
      id="fullscreen_container"
      onClick={(event) => {
        if (event.target !== document.getElementById("fullscreen_container")) {
          return;
        }

        props.close();
      }}
    >
      <CenteredContainer>
        <Title>{props.title}</Title>
        <Description>{props.message}</Description>
        <ButtonsContainer>
          <PrimaryButton
            onClick={async () => {
              await props.callback();
            }}
          >
            Eliminar
          </PrimaryButton>
          <SecondaryButton onClick={() => props.close()}>
            Cancelar
          </SecondaryButton>
        </ButtonsContainer>
      </CenteredContainer>
    </FullScreenContainer>
  );
}
