import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ContinueButton } from "./styles";
import TableInput from "../../SmartInputs/TableInput.js";

import imgDefault from "./../../../assets/img/default-profile-img.svg";

const Container = styled.div`
  width: 100%;
`;

const EmployeesTotal = styled.p`
  width: 100%;
  text-align: right;
  padding: 33px 12px 12px;
  box-sizing: border-box;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
`;

const Scroll = styled.div`
  overflow-y: auto;
  height: 200px;
  > div:last-child {
    border-width: 1px;
  }
`;

const ItemEmployee = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 61px;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #99a7bf;
`;

const Picture = styled.figure`
  margin: 15px 12px;
  > img {
    width: 37px;
    height: 37px;
    border-radius: 160px;
    border: solid 1px #707070;
  }
`;

const SecondaryButton = styled.p`
  margin-left: 30px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  padding: 15px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const Delete = styled.figure`
  width: 15%;
  display: flex;
  justify-content: center;
  > img {
    width: 30px;
    height: 30px;
  }
`;

const Item = styled.div`
  width: 85%;
  display: flex;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
`;

export default (props) => {
  const [employeesList, setEmployeesList] = useState(
    JSON.parse(sessionStorage.getItem("dataPOS")).sellers
  );

  useEffect(() => {
    let temp = JSON.parse(sessionStorage.getItem("dataPOS"));
    temp.sellers = employeesList;
    sessionStorage.setItem("dataPOS", JSON.stringify(temp));
  }, [employeesList, props]);

  const saveValue = (value) => {
    if (employeesList.filter((e) => e.id === value.id).length === 0) {
      setEmployeesList((prevState) => [...prevState, value]);
    }
  };

  return (
    <Container>
      <TableInput
        type="text"
        design={"v2"}
        allBorders
        textCenter
        inputValue={"Buscar colaborador"}
        width="100%"
        saveValue={(value) => saveValue(value)}
        popupType="EMPLOYEE"
        onClick={() => {}}
      />
      <EmployeesTotal>{employeesList.length} en total</EmployeesTotal>
      <Scroll>
        {employeesList.map((e, i) => {
          return (
            <>
              <ItemEmployee key={i}>
                <Item>
                  <Picture>
                    <img
                      src={e.picture ? e.picture : imgDefault}
                      alt={e.name}
                    ></img>
                  </Picture>
                  <p>{e.fullname}</p>
                </Item>
                <Delete
                  onClick={() => {
                    let temp = employeesList.slice();
                    temp.splice(i, 1);
                    setEmployeesList(temp);
                  }}
                >
                  <img src="../close.svg" alt="Quitar" />
                </Delete>
              </ItemEmployee>
            </>
          );
        })}
      </Scroll>
      <ButtonsContainer>
        <ContinueButton onClick={() => props.callback()}>
          Continuar
        </ContinueButton>
        <SecondaryButton onClick={() => props.backStep()}>
          Regresar
        </SecondaryButton>
      </ButtonsContainer>
    </Container>
  );
};
