import React, { useState } from "react";
import { Link, Route, Switch } from "react-router-dom";
import styled from "styled-components";

import { PRSendEmail, PRUpdate } from "./PasswordRecovery";
import {
  RegisterCompanyData,
  RegisterAdminData,
  RegisterValidateCode,
} from "./RegisterAccount";
import { SignIn, CompleteNewPassword } from "./SignIn";
import PromotionalCode from "./PromotionalCode";
import bgImg from "../assets/background.svg";
import link from "../assets/icons/link.svg";

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  padding: 30px 40px 0;
  flex-shrink: 0;
  position: fixed;
  background: white;
  /* box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.15); */
  z-index: 1;
  a > p {
    display: flex;
    align-items: center;
    height: 55px;
    border-radius: 28px;
    box-sizing: border-box;
    padding: 10px 20px;
    cursor: pointer;
  }
`;

const LogoSloganContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const LogoContainer = styled.figure`
  margin-right: 15px;
`;

const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
`;

const LoginButton = styled.p`
  background: black;
  color: white;
`;

const Redirectbutton = styled.p`
  background: #5590ff;
  color: white;
  margin-right: 15px;
  img {
    padding-left: 10px;
    width: 25px;
  }
`;

const MainContainer = styled.div`
  min-height: 400px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-image: url(${bgImg});
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: bottom;
  overflow-y: auto;
  padding: 110px 0 50px;
  box-sizing: border-box;
  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 900px) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
`;

const Space = styled.div`
  width: 30px;
  height: 30px;
`;

const loginLinks = {
  "/login": true,
  "/password-recovery": true,
  "/password-recovery/update": true,
  "/password-recovery/send-code": true,
};

export default function LogoutBasicSctructure(props) {
  let path = props.location.pathname;
  const [signInActivated, setLogoutState] = useState(
    loginLinks[path] === true ? true : false
  );
  const [promoCodeSignUp, setPromoCode] = useState(null);
  const [cognitoUserObject, setCognitoUserObjectState] = useState(null);
  const sessionStorageCompanyData = JSON.parse(
    sessionStorage.getItem("company")
  );
  const sessionStorageUserData = JSON.parse(sessionStorage.getItem("user"));
  const [accountData, setNewAccountState] = useState({
    authenticated: sessionStorage.getItem("auth") || false,
    userData: sessionStorageUserData,
    companyData: sessionStorageCompanyData,
  });
  const [passwordRecoveryEmail, setPasswordRecoveryEmailState] = useState(null);
  const showCouponSection =
    path === "/register" || path === "/register/admin" || path === "/";

  return (
    <LoginContainer>
      <HeaderContainer>
        <LogoSloganContainer
          onClick={() =>
            (window.location =
              process.env.REACT_APP_INFORMATIVE_WEBSITE_ENDPOINT)
          }
        >
          <LogoContainer>
            <img src="/horizontal-logo.svg" alt="Logo"></img>
          </LogoContainer>
        </LogoSloganContainer>
        <HeaderRight>
          <a href={process.env.REACT_APP_POS_APP_ENDPOINT}>
            <Redirectbutton>
              <p>Punto de venta</p>
              <img src={link} alt="Link to app"></img>
            </Redirectbutton>
          </a>
          <StyledLink
            to={signInActivated === true ? "/register" : "/login"}
            onClick={() => setLogoutState(!signInActivated)}
            children={
              <LoginButton>
                {signInActivated ? "Regístrate" : "Inicia sesión"}
              </LoginButton>
            }
          ></StyledLink>
        </HeaderRight>
      </HeaderContainer>
      <MainContainer>
        <Switch>
          <Route path="/register/admin">
            <RegisterAdminData
              setNewUserState={setNewAccountState}
              data={{ ...accountData, code: promoCodeSignUp }}
            ></RegisterAdminData>
          </Route>
          <Route path="/register/send-code">
            <RegisterValidateCode data={accountData}></RegisterValidateCode>
          </Route>
          <Route path="/register">
            <RegisterCompanyData
              setNewUserState={setNewAccountState}
              data={accountData}
            ></RegisterCompanyData>
          </Route>
          <Route path="/login/complete-new-password">
            <CompleteNewPassword
              cognitoUserObject={cognitoUserObject}
            ></CompleteNewPassword>
          </Route>
          <Route path="/login">
            <SignIn
              setCognitoUserObjectState={setCognitoUserObjectState}
            ></SignIn>
          </Route>
          <Route path="/password-recovery/update">
            <PRUpdate email={passwordRecoveryEmail}></PRUpdate>
          </Route>
          <Route path="/password-recovery">
            <PRSendEmail
              saveEmail={setPasswordRecoveryEmailState}
            ></PRSendEmail>
          </Route>
          <Route path="/">
            <RegisterCompanyData
              setNewUserState={setNewAccountState}
              data={accountData}
            ></RegisterCompanyData>
          </Route>
        </Switch>
        <Space></Space>
        {showCouponSection && (
          <PromotionalCode
            saveCode={(code) => setPromoCode(code)}
            {...props}
          ></PromotionalCode>
        )}
      </MainContainer>
    </LoginContainer>
  );
}
