import { Auth } from "aws-amplify";
import React, { useState, useEffect, useCallback } from "react";
import styled, { css } from "styled-components";
import { Link, useHistory } from "react-router-dom";

import CustomInput from "./CustomInput";
import { Loading, Completed } from "./GenericPopup";
import { listenerEnter } from "./_utils/helper";
import { getAccountData } from "./_utils/data";

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const MainContainer = styled.div`
  width: 435px;
  padding: 35px;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;

  ${(props) =>
    props.error === true &&
    css`
      animation-name: errorAlert;
      animation-duration: 0.3s;
      animation-iteration-count: 3;
    `}
`;

const SectionTitle = styled.p`
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
`;

const SubmitButton = styled.input`
  align-self: flex-start;
  margin-top: 15px;
  padding: 11px 20px;
  border-radius: 100px;
  background: #323946;
  color: white;
  border: none;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 15px;
  outline: none;
  cursor: pointer;
`;

const DecisionContainer = styled.div`
  margin-top: 30px;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const CancelButton = styled.p`
  margin-top: 13px;
  margin-left: 20px;
  color: #323946;
  font-weight: 500;
`;

const Description = styled.p`
  font-size: 14px;
  color: #323946;
  font-weight: 300;
  max-width: 350px;
  text-align: center;
  margin-bottom: 15px;
`;

export function PRSendEmail(props) {
  const [loading, setLoading] = useState(false);
  const [completed, setProcessStatus] = useState(false);
  const [completedMessage, setProcessStatusMessage] = useState(null);
  const history = useHistory();
  const [error, setError] = useState(false);

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        setLoading(true);
        const email = document.getElementById("recovery_email").value;
        await Auth.forgotPassword(email);
        props.saveEmail(email);

        history.push({
          pathname: "/password-recovery/update",
        });
      } catch (err) {
        if (err.code === "LimitExceededException") {
          setProcessStatusMessage(
            "El límite de intentos ha sido excedido, favor de intentar más tarde"
          );
        }
        setLoading(false);
        setProcessStatus(true);
      }
    },
    [history, props]
  );

  useEffect(() => {
    setTimeout(() => setError(false), 1000);
  }, [error]);

  useEffect(() => {
    const listener = (event) => {
      listenerEnter({
        event: event,
        array: [document.getElementById("recovery_email").value],
        actionSuccess: () => onSubmit(event),
        actionFailure: () => setError(true),
      });
    };
    document.addEventListener("keydown", listener);
    return () => document.removeEventListener("keydown", listener);
  }, [onSubmit]);

  return (
    <MainContainer id="container" error={error}>
      {loading && <Loading />}
      {completed && (
        <Completed
          type="ERROR"
          message={completedMessage}
          close={() => setProcessStatus(false)}
        />
      )}
      <SectionTitle>Recuperación de contraseña</SectionTitle>
      <Description>
        Por motivos de seguridad, te enviaremos un código de verificación a tu
        correo.
      </Description>
      <form onSubmit={async (event) => onSubmit(event)}>
        <CustomInput
          id="recovery_email"
          placeholder="Email"
          type="email"
        ></CustomInput>
        <DecisionContainer>
          <SubmitButton type="submit" value="Enviar código"></SubmitButton>
          <StyledLink to="/login">
            <CancelButton>Cancelar</CancelButton>
          </StyledLink>
        </DecisionContainer>
      </form>
    </MainContainer>
  );
}

export function PRUpdate(props) {
  const [loading, setLoading] = useState(false);
  const [completed, setProcessStatus] = useState(false);
  const [completedMessage, setProcessStatusMessage] = useState(null);
  const history = useHistory();
  const [error, setError] = useState(false);

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setLoading(true);
      const code = document.getElementById("recovery_code").value;
      const newPassword = document.getElementById(
        "recovery_new_password"
      ).value;

      try {
        await Auth.forgotPasswordSubmit(props.email, code, newPassword);
        const session = await Auth.signIn(props.email, newPassword);
        sessionStorage.setItem("auth", true);

        await getAccountData(session);

        history.push({
          pathname: process.env.REACT_APP_ARTICLES_ROUTE,
        });
      } catch (err) {
        setLoading(false);
        setProcessStatus(true);
        if (err.code === "CodeMismatchException") {
          setProcessStatusMessage("El código ingresado es incorrecto");
        }
      }
    },
    [history, props.email]
  );

  useEffect(() => {
    setTimeout(() => setError(false), 1000);
  }, [error]);

  useEffect(() => {
    const listener = (event) => {
      listenerEnter({
        event: event,
        array: [
          document.getElementById("recovery_code").value,
          document.getElementById("recovery_new_password").value,
        ],
        actionSuccess: () => onSubmit(event),
        actionFailure: () => setError(true),
      });
    };
    document.addEventListener("keydown", listener);
    return () => document.removeEventListener("keydown", listener);
  }, [onSubmit]);

  return (
    <MainContainer id="container" error={error}>
      {loading && <Loading />}
      {completed && (
        <Completed
          type="ERROR"
          message={completedMessage}
          close={() => setProcessStatus(false)}
        />
      )}
      <SectionTitle>Recuperación de contraseña</SectionTitle>
      <Description>
        Te hemos enviado un código de verificación, es necesario ingresarlo para
        poder continuar.
      </Description>
      <form onSubmit={async (event) => onSubmit(event)}>
        <CustomInput
          id="recovery_code"
          placeholder="Código de verificación (6 dígitos)"
          type="text"
          required={true}
          pattern="\d{6}"
        ></CustomInput>
        <CustomInput
          id="recovery_new_password"
          placeholder="Nueva contraseña (mínimo 8 caracteres)"
          type="password"
          required={true}
          pattern="^.{8,}$"
        ></CustomInput>
        <DecisionContainer>
          <SubmitButton type="submit" value="Cambiar contraseña"></SubmitButton>
          <StyledLink to="/password-recovery">
            <CancelButton>Cancelar</CancelButton>
          </StyledLink>
        </DecisionContainer>
      </form>
    </MainContainer>
  );
}
