import React, { useState, useEffect } from "react";
import axios from "axios";
import styled, { css } from "styled-components";

import CustomInput from "../../CustomInput";
import CustomRole from "./CustomRole";
import { getEmployeeData } from "../../_utils/data";

const CenteredContainer = styled.div`
  position: fixed;
  overflow-y: auto;
  max-height: 600px;
  width: 400px;
  border-radius: 10px;
  left: ${(props) => (props.left ? props.left : "50%")};
  top: 50%;
  background: white;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
  .row {
    display: flex;
    width: 100%;
    > button:first-child {
      margin-right: 9px;
    }
    > button:last-child {
      margin-left: 9px;
    }
  }
  ${(props) =>
    props.error === true &&
    css`
      animation-name: errorAlertFixed;
      animation-duration: 0.3s;
      animation-iteration-count: 3;
    `}
`;

const Title = styled.p`
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px; ;
`;

const ContinueButton = styled.button`
  padding: 12px 25px 14px 25px;
  color: white;
  background: #323946;
  border-radius: 100px;
  font-weight: 500;
  margin-top: 50px;
  align-self: flex-start;
  outline: none;
  border: none;
`;

const AbortButton = styled.p`
  margin-top: 50px;
  margin-left: 30px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  padding: 15px;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

const onSubmit = async (params) => {
  params.changeSection(2);
  let permissionEliminated = [
    { name: "Articulos" },
    { name: "Bodegas" },
    { name: "Movimientos" },
    { name: "Puntos de venta" },
    { name: "Órdenes" },
    { name: "Colaboradores" },
  ];
  if (params.update) {
    const employees = await getEmployeeData();
    let orignalPermissionsEmployee = employees.filter(
      (e) => e.id === params.employeeSelected.id
    )[0].permission;
    orignalPermissionsEmployee.forEach((e, i) => {
      const dataPermission = params.data.userPermissions["permissions"][i];
      if (dataPermission) {
        e.read !== dataPermission["read"] &&
          (permissionEliminated[i]["read"] = true);
        e.create !== dataPermission["create"] &&
          (permissionEliminated[i]["create"] = true);
        e.update !== dataPermission["update"] &&
          (permissionEliminated[i]["update"] = true);
        e.delete !== dataPermission["delete"] &&
          (permissionEliminated[i]["delete"] = true);
      }
    });
  }

  params.data = {
    ...params.data,
    userPermissions: {
      permissions: params.data.userPermissions.permissions,
      permissionEliminated: permissionEliminated,
    },
  };

  try {
    const cognitoResponse = await axios({
      method: params.update ? "put" : "post",
      url: params.update
        ? process.env.REACT_APP_USER_ENDPOINT
        : process.env.REACT_APP_EMPLOYEE_ENDPOINT,
      data: params.data,
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });
    if (cognitoResponse.data.error) {
      throw cognitoResponse.data;
    } else {
      const response = getEmployeeData({ force: true });
      response.then((response) => {
        params.setEmployeesState(response);
      });
      params.setStatus("SUCCESS");
      params.changeSection(3);
    }
  } catch (error) {
    params.setStatus("ERROR");
    params.changeSection(3);
    if (error.statusCode === 409) {
      params.setStatusMessage(
        "Lo sentimos, el correo utilizado ya fue registrado en la plataforma"
      );
    } else {
      params.setStatusMessage(
        "Lo sentimos, hubo un error al registrar al colaborador"
      );
    }
  }
};

export default function FirstSection(props) {
  const [showCustomRole, setShowCustomRole] = useState(true);
  const [containerPosition, setContainerPosition] = useState("100%");
  const [accessPOS, setAccessPOS] = useState(
    props.employeeSelected ? !!props.employeeSelected.access_pos : false
  );
  const [permissions, setPermissions] = useState([
    {
      name: "Articulos",
      read: false,
      create: false,
      update: false,
      delete: false,
    },
    {
      name: "Bodegas",
      read: false,
      create: false,
      update: false,
      delete: false,
    },
    {
      name: "Movimientos",
      read: false,
      create: false,
      update: false,
      delete: false,
    },
    {
      name: "Puntos de venta",
      read: false,
      create: false,
      update: false,
      delete: false,
    },
    {
      name: "Órdenes",
      read: false,
      create: false,
      update: false,
      delete: false,
    },
    {
      name: "Colaboradores",
      read: false,
      create: false,
      update: false,
      delete: false,
    },
  ]);

  useEffect(() => {
    if (props.employeeSelected) {
      getEmployeeData().then((response) => {
        let permissionsEmployee = response.filter(
          (e) => e.id === props.employeeSelected.id
        )[0].permission;
        permissionsEmployee.forEach((e) => {
          let method = e.method.toLowerCase();
          switch (e.area) {
            case "Articulos":
              setPermissions([...permissions, (permissions[0][method] = true)]);
              break;
            case "Bodegas":
              setPermissions([...permissions, (permissions[1][method] = true)]);
              break;
            case "Movimientos":
              setPermissions([...permissions, (permissions[2][method] = true)]);
              break;
            case "Puntos de venta":
              setPermissions([...permissions, (permissions[3][method] = true)]);
              break;
            case "Órdenes":
              setPermissions([...permissions, (permissions[4][method] = true)]);
              break;
            case "Colaboradores":
              setPermissions([...permissions, (permissions[5][method] = true)]);
              break;
            default:
              break;
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.employeeSelected]);

  useEffect(() => {
    if (showCustomRole) {
      setContainerPosition("27.5%");
    } else {
      setContainerPosition("50%");
    }
  }, [showCustomRole]);

  //FINAL UPDATE DE PERMISOS POR DROPDOWN
  const [fullname, setFullname] = useState(
    props.employeeSelected ? props.employeeSelected.fullname : ""
  );
  const [jobTitle, setJobTitle] = useState(
    props.employeeSelected ? props.employeeSelected.job_title : ""
  );
  const [email, setEmail] = useState(
    props.employeeSelected ? props.employeeSelected.email : ""
  );

  return (
    <>
      <CenteredContainer
        id="container"
        error={props.error}
        left={containerPosition}
      >
        <Title>{props.title}</Title>
        <CustomInput
          id="fullname"
          placeholder="Nombre completo"
          type="text"
          required={true}
          value={fullname}
          onChange={(e) => setFullname(e.target.value)}
        ></CustomInput>
        <CustomInput
          id="job_title"
          placeholder="Puesto"
          type="text"
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
        ></CustomInput>
        <CustomInput
          id="email"
          placeholder="Correo electrónico"
          type="email"
          required={true}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></CustomInput>
        <ActionButtons>
          <ContinueButton
            onClick={async () => {
              onSubmit({
                changeSection: props.changeSection,
                data: {
                  fullname: document.getElementById("fullname").value,
                  job_title: document.getElementById("job_title").value,
                  email: document.getElementById("email").value,
                  company_id: JSON.parse(sessionStorage.getItem("company")).id,
                  accessPOS,
                  userPermissions: { permissions: permissions },
                  id_user: props.employeeSelected
                    ? props.employeeSelected.id
                    : null,
                },
                employeeSelected: props.employeeSelected
                  ? props.employeeSelected
                  : null,
                update: props.employeeSelected ? true : false,
                setEmployeesState: props.setEmployeesState,
                props: { ...props },
                setStatus: props.setStatus,
                setStatusMessage: props.setStatusMessage,
              });
            }}
          >
            {props.employeeSelected ? "Actualizar" : "Registrar"}
          </ContinueButton>
          <AbortButton onClick={() => props.close()}>Abortar</AbortButton>
        </ActionButtons>
      </CenteredContainer>
      {showCustomRole && (
        <CustomRole
          updatePermissions={setPermissions}
          permissions={permissions}
          setShowCustomRole={setShowCustomRole}
          setAccessPOS={setAccessPOS}
          accessPOS={accessPOS}
        />
      )}
    </>
  );
}
