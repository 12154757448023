import React from "react";
import styled from "styled-components";

const TableCellContainer = styled.p`
  height: 55px;
  padding: 0 10px;
  border-top: 1px solid #e9eef5;
  border-left: 1px solid #e9eef5;
  &:last-child {
    border-right: 1px solid #e9eef5;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  overflow-x: auto;
  width: ${(props) => (props.width ? props.width : "10%")};
`;

export default function TableCell(props) {
  return (
    <TableCellContainer
      width={props.width}
      size={props.size}
      className={props.className}
    >
      {props.title}
    </TableCellContainer>
  );
}
