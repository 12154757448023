import React from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";
import timestamp from "time-stamp";

import CreateOrder from "./IncentivateOrderCreation";

const MainContainer = styled.div`
  border-radius: 10px;
  border: 1px solid #e9eef5;
  background: #fbfdff;
  margin-bottom: 15px;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #0e2c5e;
  margin-bottom: 15px;
`;

export default function Graph(props) {
  const sales = [];
  if (props.products) {
    props.products.forEach((element) => {
      sales.push([timestamp(element.timestamp), element.total.toFixed(2)]);
    });
  }

  const state = {
    options: {
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      shortMonths: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
      days: [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ],
      shortDays: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
      chart: {
        id: "basic-bar",
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "13px",
          fontFamily: "Roboto",
          fontWeight: "500",
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "13px",
            fontFamily: "Roboto",
            fontWeight: "400",
          },
        },
      },
      xaxis: {
        type: "datetime",
        tickAmount: 6,
        labels: {
          format: "dd/MMM/yyyy",
          style: {
            fontSize: "11px",
            fontFamily: "Roboto",
            fontWeight: "500",
          },
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2,
        dashArray: 0,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.5,
          opacityFrom: 0.1,
          opacityTo: 0.5,
          stops: [0, 10],
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    series: [
      {
        data: sales,
      },
    ],
  };

  return (
    <MainContainer>
      <Title>Reporte de ventas</Title>
      {props.products?.length > 0 ? (
        <Chart
          options={state.options}
          series={state.series}
          type="area"
          width="100%"
          height="300px"
        />
      ) : (
        <CreateOrder></CreateOrder>
      )}
    </MainContainer>
  );
}
