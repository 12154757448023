import * as React from "react";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import "core-js/modules/es.array.from";
import styled from "styled-components";
import Resizer from "react-image-file-resizer";
import { v4 as uuidv4 } from "uuid";
import { getImage } from "./../../_utils/helper";

const MainContainer = styled.section`
  max-width: 100%;
  margin-top: 15px;
`;

const Description = styled.p`
  text-align: center;
`;

const Banner = styled.p`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 12px 0;
  background-color: #2bde73;
  color: #fff;
  font-family: "Roboto";
  font-weight: 300;
  padding: 5px;
  box-sizing: border-box;
  font-size: small;
`;

const PreviewImage = styled.figure`
  width: 100px;
  height: 100px;
  > img {
    background-color: #000;
    object-fit: scale-down;
    border-radius: 8px;
    box-sizing: border-box;
    border: ${(props) => (props.isNew ? "2px solid #2bde73" : "0")};
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
`;

const ImageContainer = styled.div`
  position: relative;
  margin: 5px;
`;

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      700,
      700,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

const DropZone = (props) => {
  const [myFiles, setMyFiles] = React.useState([]);

  useEffect(() => {
    if (props.update) {
      setMyFiles(JSON.parse(sessionStorage.getItem("articleSelected")).images);
    }
  }, [props.update]);

  const onDrop = React.useCallback(
    async (acceptedFiles) => {
      const resizedImages = [];
      for (let i = 0; i < acceptedFiles.length; i++) {
        const ext = ".jpeg";
        const image = await resizeFile(acceptedFiles[i]);
        resizedImages.push({
          base64: image,
          contentType: "image/jpeg",
          name: uuidv4() + ext,
          path: acceptedFiles[i].path,
        });
      }
      props.saveFiles({ ...props.data, files: [...myFiles, ...resizedImages] });
      setMyFiles([...myFiles, ...resizedImages]);
    },
    [myFiles, props]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    noKeyboard: true,
    maxSize: 20971520,
    multiple: true,
    onDrop,
  });

  const files = myFiles.map((file) => (
    <ImageContainer>
      <PreviewImage isNew={!file.location}>
        <img
          src={file.location ? getImage(file, 200, 200) : file.base64}
          alt="Imagen"
        />
      </PreviewImage>
      {!file.location && <Banner>Nuevo</Banner>}
    </ImageContainer>
  ));

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  return (
    <MainContainer>
      <div {...getRootProps({ className: "dropzone", style: baseStyle })}>
        <input {...getInputProps()} />
        <Description>
          Arrastra las imágenes o da click aquí para seleccionarlas.
        </Description>
      </div>
      {files.length > 0 ? (
        <div>
          <h4>Imágenes</h4>
          <ImagesContainer>{files}</ImagesContainer>
        </div>
      ) : (
        ""
      )}
    </MainContainer>
  );
};

export default DropZone;
