import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { ContinueButton } from "./styles";

import selected from "../../../assets/icons/orderSelected.svg";
import { getWarehouseData } from "../../_utils/data";

const Scroll = styled.div`
  overflow-y: auto;
  height: 375px;
`;

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 160px;
  border-radius: 10px;
  border: 1px solid #99a7bf;
  box-sizing: border-box;
  padding: 33px 18px 32px;
  margin: 25px 0px;
`;

const Logo = styled.figure`
  display: flex;
  margin-right: 20px;
  > img {
    width: 95px;
    height: 95px;
    border-radius: 64px;
  }
`;

const Info = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  > p:first-child {
    font-weight: 500;
    margin-bottom: 12px;
  }
`;

const SelectedIcon = styled.figure`
  position: absolute;
  right: 0;
  width: 41.8px;
  height: 43.2px;
`;

export default (props) => {
  const [warehouseSelected, setWarehouseSelected] = useState(null);
  const [warehouses, setWarehousesState] = useState(null);

  useEffect(() => {
    getWarehouseData().then((warehouses) => {
      setWarehousesState(warehouses);
      setWarehouseSelected(
        props.update
          ? JSON.parse(sessionStorage.getItem("posSelected")).warehouses.id
          : sessionStorage.getItem("dataPOS")
          ? JSON.parse(sessionStorage.getItem("dataPOS")).warehouse_id
          : warehouses[0].id
      );
    });

    if (JSON.parse(sessionStorage.getItem("dataPOS")) === null) {
      sessionStorage.setItem("dataPOS", JSON.stringify(props.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let temp = JSON.parse(sessionStorage.getItem("dataPOS"));
    temp.warehouse_id = warehouseSelected;
    sessionStorage.setItem("dataPOS", JSON.stringify(temp));
  }, [warehouseSelected]);

  return (
    <React.Fragment>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          props.callback();
        }}
      >
        <Scroll>
          {warehouses?.map((e) => (
            <Card
              key={e.id}
              onClick={() => {
                setWarehouseSelected(e.id);
                let temp = JSON.parse(sessionStorage.getItem("dataPOS"));
                temp.warehouse_id = e.id;
                sessionStorage.setItem("dataPOS", JSON.stringify(temp));
              }}
            >
              {warehouseSelected === e.id && (
                <SelectedIcon>
                  <img src={selected} alt="Seleccionado" />
                </SelectedIcon>
              )}
              <Logo>
                <img
                  alt="Static mapbox map"
                  src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+323946(${e.longitude},${e.latitude})/${e.longitude},${e.latitude},15,0.00,0.00/500x500@2x?access_token=pk.eyJ1IjoicmxlZ29ycmV0YSIsImEiOiJjaXYzZDBwY2YwMHprMnpxbm93aW5ubDkxIn0.XvwIpNBJK-ZS3VsiTRNv4w`}
                />
              </Logo>
              <Info>
                <p>{e.name}</p>
                <p>{e.address}</p>
              </Info>
            </Card>
          ))}
        </Scroll>
        <ContinueButton type="submit">Continuar</ContinueButton>
      </form>
    </React.Fragment>
  );
};
