import React, { useCallback, useEffect, useState } from "react";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Transition } from "react-transition-group";

// Components
import Article from "./Article";
import ArticleEdition from "./edition/ArticleEdition";
import CompositeArticleEdition from "./edition/ArticleEditionComposite";
import IncentivateArticleCreation from "./_IncentivateArticleCreation";
import { HeaderRow } from "./_ArticleSquareTableView";
import Menu from "../Menu";

// Styles
import { MenuContainer, SearchBox } from "../styles";

// Helpers
import {
  perfectSpaceForArticles,
  fuseConf,
  filterDropdownValues,
  isElementClicked,
} from "../_utils/helper";
import { getArticleData } from "../_utils/data";

// Images
import tableImg from "../../assets/table.svg";
import squareSelectedImg from "../../assets/squareSelected.svg";
import tableSelectedImg from "../../assets/tableSelected.svg";
import squareImg from "../../assets/square.svg";
import { getArticleComponent } from "./helper";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  transition: all 0.4s ease-in;
  transition-delay: 0.7s;
  opacity: ${(props) => (props.state === "entered" ? 1 : 0)};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 115px;
  align-items: center;
  justify-content: space-between;
  padding: 0 17px; // Same padding than the table view component
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
`;

const ArticlesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow-y: auto;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
`;

const ContainerWithMargin = styled.div`
  width: 100%;
  padding: 0 15px 15px 15px;
  box-sizing: border-box;
  overflow-y: scroll;
  transition: all 0.4s ease;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
`;

const ArticlesContainerAsTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

const VisualizeTool = styled.div`
  padding: 0 20px;
  height: 55px;
  display: ${(props) => (props.state === "entered" ? "flex" : "none")};
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #707070;
  border-radius: 5px;
  margin-right: 30px;
  & p {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 14px;
    color: #99a7bf;
  }
  & figure {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-left: 25px;
  }
  & figure:nth-child(2) {
    margin-left: 20px;
  }
  box-sizing: border-box;
`;

const RightHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default function ArticleController() {
  const [selectedArticleData, setSelectedArticleStatus] = useState(null);
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:articleId`}>
        <Article data={selectedArticleData}></Article>
      </Route>
      <Route path={process.env.REACT_APP_ARTICLES_ROUTE}>
        <Articles setArticleData={setSelectedArticleStatus}></Articles>
      </Route>
    </Switch>
  );
}

function Articles(props) {
  const history = useHistory();
  const storagedView = JSON.parse(localStorage.getItem("tableView"));
  const articlesPermissions =
    JSON.parse(sessionStorage.getItem("permissions")).find(
      (e) => e.area === "Articulos"
    ) !== undefined;

  // States
  const [availableArticlesActivated, setAvailableArticlesActivated] =
    useState(false);
  const [newArticleActivated, setNewArticleStatus] = useState(false);
  const [newCompositeArticleActivated, setNewCompositeArticleStatus] =
    useState(false);
  const [newFictionalArticleActivated, setNewFictionalArticleStatus] =
    useState(false);
  const [newArticleCreated, setNewArticleCreatesState] = useState(false);
  const [tableActivated, setTableActivatedState] = useState(
    storagedView ? storagedView : false
  );
  const [rawArticles, setArticlesState] = useState([]);
  const [rawArticlesFiltered, setRawArticlesFiltered] = useState([]);
  const [firstExecution, setFirstExecutionState] = useState(true);
  const [activateSection, setActivateSection] = useState(false);
  const [flag, setFlagState] = useState(false);
  const [articlesComponents, setArticlesComponent] = useState([]);

  const fuse = fuseConf([
    ...rawArticles?.map((element) => element.name),
    ...rawArticles?.map((element) => element.sku),
  ]);
  const nodeRef = React.useRef(null);
  const articleClassName = "article";
  const parentId = "articles_container";

  const clickOutsideToClose = useCallback((evt) => {
    if (!isElementClicked(evt, "menu")) {
      document.removeEventListener("click", clickOutsideToClose);
      setAvailableArticlesActivated(false);
    }
  }, []);

  useEffect(() => {
    if (availableArticlesActivated) {
      document.addEventListener("click", clickOutsideToClose);
    }

    const listener = () => {
      perfectSpaceForArticles(articleClassName, parentId, null, tableActivated);
    };
    perfectSpaceForArticles(articleClassName, parentId, null, tableActivated);
    window.addEventListener("resize", listener);

    if (flag) {
      const components = getArticleComponent(
        rawArticlesFiltered,
        tableActivated,
        props.setArticleData
      );
      if (components.length >= 1) {
        setArticlesComponent(components);
      }
      setFlagState(false);
    }

    if (rawArticles.length === 0 && firstExecution) {
      setArticlesComponent(
        <IncentivateArticleCreation
          key={0}
          newArticle={() => setNewArticleStatus(!newArticleActivated)}
        />
      );
    }

    if (firstExecution) {
      setActivateSection(true);
    }

    if (articlesPermissions && (firstExecution || newArticleCreated)) {
      getArticleData({ force: true }).then((response) => {
        const articles = response;
        setArticlesState(articles);
        setRawArticlesFiltered(articles);
        setFirstExecutionState(false);
        const components = getArticleComponent(
          articles,
          tableActivated,
          props.setArticleData
        );
        if (components.length >= 1) {
          setArticlesComponent(components);
        }
      });

      setNewArticleCreatesState(false);
    }

    if (!articlesPermissions) {
      history.push({
        pathname: process.env.REACT_APP_WAREHOUSES_ROUTE,
      });
    }

    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [
    tableActivated,
    flag,
    rawArticles.length,
    firstExecution,
    newArticleCreated,
    rawArticlesFiltered,
    props.setArticleData,
    newArticleActivated,
    rawArticles,
    availableArticlesActivated,
    clickOutsideToClose,
    articlesPermissions,
    history,
  ]);

  const articlesAvailable = [
    {
      label: "Nuevo artículo compuesto",
      action: () => {
        setNewCompositeArticleStatus(!newCompositeArticleActivated);
      },
    },
    {
      label: "Nuevo artículo ficticio",
      action: () => {
        setNewFictionalArticleStatus(!newFictionalArticleActivated);
      },
    },
  ];

  return (
    <Transition nodeRef={nodeRef} in={activateSection} timeout={0}>
      {(state) => {
        return (
          <MainContainer state={state}>
            {newArticleActivated && (
              <ArticleEdition
                title="Nuevo artículo"
                close={() => {
                  setNewArticleStatus(!newArticleActivated);
                }}
                callback={() => setNewArticleCreatesState(true)}
              ></ArticleEdition>
            )}
            {newCompositeArticleActivated && (
              <CompositeArticleEdition
                title="Nuevo artículo compuesto"
                close={() => {
                  setNewCompositeArticleStatus(!newCompositeArticleActivated);
                }}
                callback={() => setNewArticleCreatesState(true)}
                data={{ type: "COMPOSITE" }}
              ></CompositeArticleEdition>
            )}
            {newFictionalArticleActivated && (
              <CompositeArticleEdition
                title="Nuevo artículo ficticio"
                close={() => {
                  setNewFictionalArticleStatus(!newFictionalArticleActivated);
                }}
                callback={() => setNewArticleCreatesState(true)}
                data={{ type: "FICTIONAL" }}
              />
            )}
            <HeaderContainer>
              <SearchBox
                placeholder={"Buscar por nombre o SKU"}
                onChange={(event) => {
                  if (event.target.value === "") {
                    if (rawArticles.length !== 0) {
                      // Avoid filtering articles when articles is empty
                      setRawArticlesFiltered(rawArticles);
                      setArticlesComponent(
                        getArticleComponent(
                          rawArticles,
                          tableActivated,
                          props.setArticleData
                        )
                      );
                    }
                  } else {
                    if (rawArticles.length !== 0) {
                      // Avoid filtering articles when articles is empty
                      let matches = filterDropdownValues(fuse, event);
                      const temporalComponentHolder = [];
                      getArticleComponent(
                        rawArticles,
                        tableActivated,
                        props.setArticleData
                      ).forEach((originalValue) => {
                        for (let i = 0; i < matches.length; i++) {
                          if (
                            matches[i] === originalValue.props.article.name ||
                            matches[i] === originalValue.props.article.sku
                          ) {
                            temporalComponentHolder.push(originalValue);
                            break;
                          }
                        }
                      });
                      setRawArticlesFiltered(
                        temporalComponentHolder?.map(
                          (element) => element.props.article
                        )
                      );
                      setArticlesComponent(temporalComponentHolder);
                    }
                  }
                }}
              />
              <RightHeader>
                <VisualizeTool state={state}>
                  <p>Visualización</p>
                  <figure
                    onClick={() => {
                      setTableActivatedState(true);
                      setFlagState(true);
                      localStorage.setItem("tableView", true);
                    }}
                  >
                    <img
                      alt="Table view"
                      src={tableActivated ? tableSelectedImg : tableImg}
                    ></img>
                  </figure>
                  <figure
                    onClick={() => {
                      setTableActivatedState(false);
                      setFlagState(true);
                      localStorage.setItem("tableView", false);
                    }}
                  >
                    <img
                      alt="Square"
                      src={tableActivated ? squareImg : squareSelectedImg}
                    ></img>
                  </figure>
                </VisualizeTool>
                <button
                  onClick={() => setNewArticleStatus(!newArticleActivated)}
                >
                  Añadir
                </button>
                <MenuContainer
                  onClick={() => {
                    setAvailableArticlesActivated(!availableArticlesActivated);
                  }}
                >
                  <img src="/arrow.svg" alt="menu"></img>
                  {availableArticlesActivated && (
                    <Menu
                      items={articlesAvailable}
                      close={() =>
                        setAvailableArticlesActivated(
                          !availableArticlesActivated
                        )
                      }
                    ></Menu>
                  )}
                </MenuContainer>
              </RightHeader>
            </HeaderContainer>
            <Transition nodeRef={nodeRef} in={tableActivated} timeout={0}>
              {(state) => {
                if (tableActivated && rawArticles.length !== 0) {
                  return (
                    <ContainerWithMargin ref={nodeRef} state={state}>
                      <ArticlesContainerAsTable>
                        <HeaderRow></HeaderRow>
                        {articlesComponents}
                      </ArticlesContainerAsTable>
                    </ContainerWithMargin>
                  );
                }
              }}
            </Transition>
            {(!tableActivated || rawArticles.length === 0) && (
              <ArticlesContainer id="articles_container">
                {articlesComponents}
              </ArticlesContainer>
            )}
          </MainContainer>
        );
      }}
    </Transition>
  );
}
