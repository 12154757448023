import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { Checktool, Checkbox } from "../Checktool";
import Menu from "../Menu";
import WarehouseEdition from "./WarehouseEditionV2";
import DeletePopup from "../DeletePopup";
import { isElementClicked } from "../_utils/helper";
import { Loading, Completed } from "../GenericPopup";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 115px;
  align-items: center;
  justify-content: flex-end;
  padding: 0 40px;
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
`;

const MenuContainer = styled.figure`
  width: 30px;
  padding: 5px;
  cursor: pointer;
  position: relative;
`;

const WarehouseContainer = styled.div`
  padding: 20px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-y: scroll;
  margin-bottom: 30px;
`;

const MapContainer = styled.figure`
  width: 500px;
  height: 500px;
  margin-bottom: 30px;
`;

const DescriptionContainer = styled.div`
  & p {
    font-size: 14px;
    font-weight: 500;
    padding: 8px;
  }
  & span {
    font-weight: 400 !important;
  }
`;

const ProductsTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  width: 500px;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & p {
    padding: 15px 0 15px 15px;
  }
`;

const TableHeader = styled(TableRow)`
  border-top: 1px solid #99a7bf;
  & p {
    font-weight: 500;
  }
`;

const ArticleName = styled.p`
  border-left: 1px solid #99a7bf;
  border-bottom: 1px solid #99a7bf;
  border-right: 1px solid #99a7bf;
  flex-grow: 1;
`;

const ArticleUnits = styled.p`
  border-right: 1px solid #99a7bf;
  border-bottom: 1px solid #99a7bf;
  width: 100px;
  text-align: center;
  padding-left: 0 !important;
  border-left: none;
`;

export default function Warehouse(props) {
  const [loading, setLoading] = useState(false);
  const [completed, setProcessStatus] = useState(false);
  const [menuActivated, setMenuState] = useState(false);
  const [checktoolActivated, setChecktoolState] = useState(false);
  const [editWarehouseActivated, setWarehousePopupState] = useState(false);
  const [deleteWarehouseActivated, setWarehouseDeletionPopupState] =
    useState(false);
  const [warehouseExecution, setWarehouseExecutionState] = useState(0);
  const [productsOnDB, setProductsState] = useState([]);
  const warehouseSelected = JSON.parse(
    sessionStorage.getItem("warehouseSelected")
  )?.id;
  const history = useHistory();
  const [errorDelete, setErrorDelete] = useState(false);

  const [warehouseData, setWarehouseData] = useState(() => {
    if (props.data) {
      sessionStorage.setItem("warehouseSelected", JSON.stringify(props.data));
      return props.data;
    } else {
      return JSON.parse(sessionStorage.getItem("warehouseSelected"));
    }
  });

  const clickOutsideToClose = useCallback((evt) => {
    if (!isElementClicked(evt, "menu")) {
      document.removeEventListener("click", clickOutsideToClose);
      setMenuState(false);
    }
  }, []);

  useEffect(() => {
    if (menuActivated) {
      document.addEventListener("click", clickOutsideToClose);
    }

    if (warehouseExecution === 0) {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_STOCK_ENDPOINT}?warehouse_id=${warehouseSelected}`,
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      })
        .then((response) => {
          setProductsState(JSON.parse(response.data.body).stock);
          setWarehouseExecutionState(warehouseExecution + 1);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [
    warehouseExecution,
    warehouseSelected,
    menuActivated,
    clickOutsideToClose,
  ]);

  const menu = [
    {
      label: "Editar bodega",
      action: () => {
        setWarehousePopupState(!editWarehouseActivated);
      },
    },
    {
      label: "Eliminar bodega",
      action: () => {
        setWarehouseDeletionPopupState(!deleteWarehouseActivated);
      },
    },
  ];

  const products = [];
  for (let i = 0; i < productsOnDB.length; i++) {
    products.push(
      <TableRow
        key={i} /*onMouseLeave={hideCheckbox} onMouseEnter={showCheckbox}*/
      >
        <Checkbox
          callback={() => {
            setChecktoolState(!checktoolActivated);
          }}
          activated={checktoolActivated}
        ></Checkbox>
        <ArticleName>{productsOnDB[i].article}</ArticleName>
        <ArticleUnits>{parseFloat(productsOnDB[i].stock)}</ArticleUnits>
      </TableRow>
    );
  }

  const deleteWarehouse = async () => {
    setLoading(true);
    const res = await axios({
      method: "delete",
      url: process.env.REACT_APP_WAREHOUSE_ENDPOINT,
      data: {
        warehouse_id: warehouseSelected,
      },
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });

    if (res.data.statusCode === 403) {
      setErrorDelete(true);
    }

    setLoading(false);
    setWarehouseDeletionPopupState(!deleteWarehouseActivated);
    setProcessStatus(true);
  };

  return (
    <MainContainer>
      {editWarehouseActivated && (
        <WarehouseEdition
          update={true}
          setWarehouseData={setWarehouseData}
          title="Editar bodega"
          close={() => {
            setWarehousePopupState(!editWarehouseActivated);
          }}
          data={warehouseData}
          warehouseSelected={warehouseSelected}
        ></WarehouseEdition>
      )}
      {deleteWarehouseActivated && (
        <DeletePopup
          callback={deleteWarehouse}
          title="Eliminar bodega"
          close={() => {
            setWarehouseDeletionPopupState(!deleteWarehouseActivated);
          }}
          message="Está a punto de eliminar la bodega seleccionada y todo el stock registrado dentro de la misma, ¿está seguro de querer continuar?. Esta acción no se puede revertir."
        ></DeletePopup>
      )}
      {loading && <Loading />}
      {completed ? (
        errorDelete ? (
          <Completed
            type="ERROR"
            close={() => {
              setProcessStatus(false);
            }}
            message={
              "Tienes un punto de venta relacionado con esta bodega, favor de actualizar o eliminar el punto de venta primero."
            }
          />
        ) : (
          <Completed
            type="SUCCESS"
            close={() => {
              setProcessStatus(false);
              history.push({
                pathname: process.env.REACT_APP_WAREHOUSES_ROUTE,
              });
            }}
          />
        )
      ) : null}
      <Checktool selected={checktoolActivated}></Checktool>
      <HeaderContainer>
        <MenuContainer onClick={() => setMenuState(!menuActivated)}>
          <img src="../menu.svg" alt="menu"></img>
          {menuActivated && (
            <Menu
              items={menu}
              close={() => setMenuState(!menuActivated)}
            ></Menu>
          )}
        </MenuContainer>
      </HeaderContainer>
      <WarehouseContainer>
        <DescriptionContainer>
          <MapContainer>
            <img
              alt="Static mapbox map"
              src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+323946(${warehouseData.longitude},${warehouseData.latitude})/${warehouseData.longitude},${warehouseData.latitude},15,0.00,0.00/500x500@2x?access_token=pk.eyJ1IjoicmxlZ29ycmV0YSIsImEiOiJjaXYzZDBwY2YwMHprMnpxbm93aW5ubDkxIn0.XvwIpNBJK-ZS3VsiTRNv4w`}
            />
          </MapContainer>
          <p>
            Nombre: <span>{warehouseData.name}</span>
          </p>
          <p>
            Dirección: <span>{warehouseData.address}</span>
          </p>
          <p>
            Productos en bodega: <span>{productsOnDB.length}</span>
          </p>
        </DescriptionContainer>
        <ProductsTable>
          <TableHeader>
            <ArticleName>Producto</ArticleName>
            <ArticleUnits>Unidades</ArticleUnits>
          </TableHeader>
          {products}
        </ProductsTable>
      </WarehouseContainer>
    </MainContainer>
  );
}
