import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import styled from "styled-components";

import Menu from "./Menu";
import ChangePassword from "./ChangePassword";
import ChangeEmail from "./ChangeEmail";
import { isElementClicked } from "./_utils/helper";
import EditProfile from "./EditProfile";
import { getAccountData, getEmployeeData } from "./_utils/data";

const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 115px;
  padding: 0 40px;
  box-sizing: border-box;
  flex-shrink: 0;
`;

const MenuContainer = styled.figure`
  width: 30px;
  padding: 5px;
  cursor: pointer;
  position: relative;
`;

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProfilePicture = styled.figure`
  margin-bottom: 50px;
  width: 115px;
  height: 115px;
  border-radius: 200px;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.08);
`;

const DataContainer = styled.div`
  width: 480px;
  border-top: 1px solid #e9eef5;
  border-left: 1px solid #e9eef5;
  border-right: 1px solid #e9eef5;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid #e9eef5;
  height: 60px;
  align-items: center;
  & p {
    padding-left: 15px;
  }
`;

const DataTitle = styled.p`
  width: 130px;
  font-weight: 500;
  border-right: 1px solid #e9eef5;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

export default function Profile() {
  const history = useHistory();
  const [menuActivated, setMenuState] = useState(false);
  const [editProfileActivated, setProfileState] = useState(false);
  const [changePasswordActivated, setPasswordState] = useState(false);
  const [changeEmailActivated, setEmailState] = useState(false);
  const [userUpdated, setUserUpdatedState] = useState(false);
  const [userData, setUserDataState] = useState(
    JSON.parse(sessionStorage.getItem("user"))
  );

  const menu = [
    {
      label: "Editar perfil",
      action: () => setProfileState(!editProfileActivated),
    },
    {
      label: "Cerrar sesión",
      action: async () => {
        try {
          await Auth.signOut({ global: true });
          sessionStorage.clear();
          history.push({
            pathname: "/login",
          });
        } catch (error) {
          console.log("error signing out: ", error);
        }
      },
    },
    // {
    //   "label": "Cambiar contraseña",
    //   "action": () => {
    //     setPasswordState(!changePasswordActivated);
    //   }
    // },
    // {
    //   "label": "Cambiar correo",
    //   "action": () => {
    //     setEmailState(!changeEmailActivated);
    //   }
    // }
  ];

  const clickOutsideToClose = useCallback((evt) => {
    if (!isElementClicked(evt, "menu")) {
      document.removeEventListener("click", clickOutsideToClose);
      setMenuState(false);
    }
  }, []);

  useEffect(() => {
    if (menuActivated) {
      document.addEventListener("click", clickOutsideToClose);
    }

    if (userUpdated) {
      const response = Auth.currentAuthenticatedUser();
      response.then((session) => {
        getAccountData(session).then(() =>
          setUserDataState(JSON.parse(sessionStorage.getItem("user")))
        );
        getEmployeeData({ force: true });
      });
      setUserUpdatedState(false);
    }
  }, [userUpdated, menuActivated, clickOutsideToClose]);

  return (
    <MainContainer>
      {changePasswordActivated && (
        <ChangePassword
          title="Cambiar contraseña"
          close={() => {
            setPasswordState(!changePasswordActivated);
          }}
        ></ChangePassword>
      )}
      {changeEmailActivated && (
        <ChangeEmail
          title="Cambiar correo"
          close={() => {
            setEmailState(!changeEmailActivated);
          }}
        ></ChangeEmail>
      )}
      {editProfileActivated && (
        <EditProfile
          title="Editar perfil"
          data={userData}
          close={() => {
            setProfileState(!editProfileActivated);
          }}
          success={() => setUserUpdatedState(true)}
        ></EditProfile>
      )}
      <HeaderContainer>
        <MenuContainer
          onClick={() => {
            setMenuState(!menuActivated);
          }}
        >
          <img src="./menu.svg" alt="Menu"></img>
          {menuActivated && (
            <Menu
              items={menu}
              close={() => setMenuState(!menuActivated)}
            ></Menu>
          )}
        </MenuContainer>
      </HeaderContainer>
      <CenteredContainer>
        <ProfilePicture>
          <img src="/profile.svg" alt="Profile"></img>
        </ProfilePicture>
        <DataContainer>
          <TableRow>
            <DataTitle>Nombre</DataTitle>
            <p>{userData.fullname}</p>
          </TableRow>
          <TableRow>
            <DataTitle>Puesto</DataTitle>
            <p>{userData.job_title}</p>
          </TableRow>
          <TableRow>
            <DataTitle>Rol</DataTitle>
            <p>Administrador</p>
          </TableRow>
          <TableRow>
            <DataTitle>Email</DataTitle>
            <p>{userData.email}</p>
          </TableRow>
          <TableRow>
            <DataTitle>Teléfono</DataTitle>
            <p>{userData.phone_number}</p>
          </TableRow>
        </DataContainer>
      </CenteredContainer>
    </MainContainer>
  );
}
