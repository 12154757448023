import React, { useEffect, useState } from "react";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import styled from "styled-components";
import { perfectSpaceForPointsOfSale } from "../_utils/helper";

// icons
import bankTransfer from "../../assets/icons/bankTransfer.svg";
import cash from "../../assets/icons/cash.svg";
import creditCard from "../../assets/icons/creditCard.svg";
import voucher from "../../assets/icons/voucher.svg";
import profileImgDefault from "../../assets/img/default-profile-img.svg";
import emptyPOS from "../../assets/icons/emptyPOS.svg";

// components
import PointOfSaleEdition from "./edition/PointsOfSaleEdition";
import PointOfSale from "./Pos";
import { Completed } from "../GenericPopup";

// data
import { getArticleData, getWarehouseData, getPOSData } from "../_utils/data";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-end;
  font-family: "Roboto";
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 115px;
  flex-shrink: 0;
  padding: 0 40px;
  box-sizing: border-box;
`;

const ContainerPoints = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 18px;
  box-sizing: border-box;

  width: 100%;
  height: 90vh;
  overflow-y: auto;
`;

const CardPoint = styled.div.attrs(() => ({ className: "cardPoint" }))`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 367px;
  max-width: 550px;
  max-height: 675px;
  border-radius: 10px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
  padding: 34px;
  box-sizing: border-box;
  color: #0e2c5e;
  cursor: pointer;
  > p {
    font-weight: 500;
  }
  > hr {
    width: 100%;
    color: #e3e3e3;
    margin: 7px 0;
  }
  @media (max-width: 890px) {
    width: 90%;
    max-width: 100%;
  }
  @media (min-width: 1250px) {
    width: 30%;
  } ;
`;

const Image = styled.figure`
  margin-bottom: 15px;
  > img {
    border-radius: 64px;
    width: 95px;
    height: 95px;
    object-fit: contain;
  }
`;

const DetailsPoint = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  > p:first-child {
    font-weight: 500;
    margin-bottom: 20px;
  }
  > p:last-child {
    margin-bottom: 20px;
  }
`;

const DetailsGeneral = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  > p:last-child {
    margin-top: 15px;
  }
`;

const DetailsProducts = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
`;

const IconDetail = styled.figure`
  display: flex;
  flex-direction: row;
  align-items: center;
  > img {
    margin: 3px 11px 3px 0;
    width: 37px;
    height: 37px;
    border-radius: 160px;
  }
`;

const EmptyContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.figure`
  width: 80px;
  height: 80px;
  margin-bottom: 30px;
`;

const EmptyActionButton = styled.button`
  padding: 15px 30px;
  border-radius: 5px;
  color: white;
  background: #5590ff;
  margin-top: 30px;
`;

export default function ArticleController() {
  const [selectedPOS, setSelectedPOS] = useState(null);
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:posID`}>
        <PointOfSale data={selectedPOS}></PointOfSale>
      </Route>
      <Route path={process.env.REACT_APP_POSS_ROUTE}>
        <PointsOfSale setSelectedPOS={setSelectedPOS}></PointsOfSale>
      </Route>
    </Switch>
  );
}

function PointsOfSale(props) {
  const history = useHistory();
  let { url } = useRouteMatch();
  const [newPointOfSale, setNewPointOfSaleStatus] = useState(false);
  const possPermissions =
    JSON.parse(sessionStorage.getItem("permissions")).find(
      (e) => e.area === "Puntos de venta"
    ) !== undefined;
  const [posExecution, setPOSExecutionState] = useState(0);
  const [incompletedPosRequirements, setIncompletedPosRequirements] =
    useState(false);
  const [pointSales, setPointSales] = useState([]);
  const [canCreatePos, setPosValidationState] = useState(false);

  const setPaymentIcon = (id) => {
    switch (id) {
      case 1:
        return cash;
      case 2:
        return creditCard;
      case 3:
        return voucher;
      case 4:
        return bankTransfer;
      default:
        break;
    }
  };

  function clickPOS(history, url, pos) {
    history.push({
      pathname: `${url}/${pos.pos}`,
    });
    sessionStorage.setItem("posSelected", JSON.stringify(pos));
    props.setSelectedPOS(pos);
  }

  const setProducts = (array) => {
    var cad = "";
    var arraySize = array.length;
    if (arraySize > 0) {
      let i = 0;
      do {
        if ((cad + array[i].name).length < 85) {
          cad += array[i].name;
          if (array[i + 1]) {
            cad += ", ";
          }
        } else break;
        i++;
      } while (cad.length <= 80 && i <= arraySize - 1);
      if (i < arraySize) {
        cad = cad.slice(0, -2);
        cad += " y " + (arraySize - i) + " productos más.";
      } else {
        cad = cad.replace(
          ", " + array[i - 1].name,
          " y " + array[i - 1].name + "."
        );
      }
    } else cad = "No hay productos registrados.";
    return cad;
  };

  useEffect(() => {
    if (possPermissions && posExecution === 0) {
      getPOSData({ force: true }).then((response) => {
        setPointSales(response);
        setPOSExecutionState(posExecution + 1);
      });

      Promise.all([getArticleData(), getWarehouseData()]).then((response) => {
        const articles = response[0].length;
        const warehouses = response[1].length;
        setPosValidationState(warehouses > 0 && articles > 0);
      });
    }

    if (!possPermissions) {
      history.push({
        pathname: process.env.REACT_APP_ORDERS_ROUTE,
      });
    }

    const listener = () => {
      perfectSpaceForPointsOfSale("cardPoint", "pointsContainer");
    };
    perfectSpaceForPointsOfSale("cardPoint", "pointsContainer");
    window.addEventListener("resize", listener);
  }, [pointSales, posExecution, newPointOfSale, possPermissions, history]);

  return (
    <Container>
      {newPointOfSale && (
        <PointOfSaleEdition
          close={() => setNewPointOfSaleStatus(false)}
          callback={() => setPOSExecutionState(0)}
        />
      )}
      {incompletedPosRequirements && (
        <Completed
          type={"ERROR"}
          message="Es necesario tener registrado por lo menos un artículo y una bodega para poder registrar un punto de venta"
          close={() => setIncompletedPosRequirements(false)}
        />
      )}
      {possPermissions && (
        <>
          <HeaderContainer>
            <button
              enabled={canCreatePos}
              onClick={() => {
                if (canCreatePos) {
                  setNewPointOfSaleStatus(true);
                } else {
                  setIncompletedPosRequirements(true);
                }
              }}
            >
              Añadir
            </button>
          </HeaderContainer>
          <ContainerPoints id="pointsContainer">
            {pointSales.length > 0 ? (
              pointSales.map((e, i) => (
                <CardPoint key={i} onClick={() => clickPOS(history, url, e)}>
                  <Image>
                    <img
                      alt="Static mapbox map"
                      src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+323946(${e.warehouses.longitude},${e.warehouses.latitude})/${e.warehouses.longitude},${e.warehouses.latitude},15,0.00,0.00/500x500@2x?access_token=pk.eyJ1IjoicmxlZ29ycmV0YSIsImEiOiJjaXYzZDBwY2YwMHprMnpxbm93aW5ubDkxIn0.XvwIpNBJK-ZS3VsiTRNv4w`}
                    />
                  </Image>
                  <DetailsPoint>
                    <p>{e.warehouses.name}</p>
                    <p>{e.warehouses.address}</p>
                  </DetailsPoint>
                  <hr />
                  <DetailsGeneral>
                    {e.payments.map(
                      (ele, ind) =>
                        ind < 2 && (
                          <IconDetail key={ind}>
                            <img src={setPaymentIcon(ele.id)} alt={ele.name} />
                            <p>{ele.name}</p>
                          </IconDetail>
                        )
                    )}
                    {e.payments.length > 2 && (
                      <p>Y {e.payments.length - 2} más</p>
                    )}
                  </DetailsGeneral>
                  <hr />
                  <DetailsGeneral>
                    {e.sellers.map(
                      (ele, ind) =>
                        ind < 2 && (
                          <IconDetail key={ind}>
                            <img
                              src={
                                ele.picture ? ele.picture : profileImgDefault
                              }
                              alt={ele.fullname}
                            />
                            <p>{ele.fullname}</p>
                          </IconDetail>
                        )
                    )}
                    {e.sellers.length > 2 && (
                      <p>Y {e.sellers.length - 2} más</p>
                    )}
                  </DetailsGeneral>
                  <hr />
                  <DetailsProducts>
                    <p>{setProducts(e.articles)}</p>
                  </DetailsProducts>
                </CardPoint>
              ))
            ) : (
              <EmptyContent>
                <Icon>
                  <img src={emptyPOS} alt="Sin puntos de venta" />
                </Icon>
                <p>No hay puntos de venta registrados</p>
                <EmptyActionButton
                  onClick={() => {
                    if (canCreatePos) {
                      setNewPointOfSaleStatus(true);
                    } else {
                      setIncompletedPosRequirements(true);
                    }
                  }}
                >
                  Registrar punto de venta
                </EmptyActionButton>
              </EmptyContent>
            )}
          </ContainerPoints>
        </>
      )}
    </Container>
  );
}
