import React, { useEffect, useState } from "react";
import ReactImageFallback from "react-image-fallback";
import styled from "styled-components";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Transition } from "react-transition-group";

// Components
import TableCell from "../SmartInputs/TableCell";

// Helpers
import {
  getArticleStock,
  getArticleCurrency,
  getUnitMeasure,
  formatPrice,
  formatStock,
} from "./helper";
import { getImage } from "../_utils/helper";

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 270px;
  height: fit-content;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  box-sizing: border-box;
  margin: 7px; // This make animation smoother
  transition: all 0.4s ease;
  transition-delay: 0.2s;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 150px;
  margin: 0;
  padding: 0;
  background: #dfe2e6;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  & img {
    overflow: hidden;
    object-fit: contain;
  }
`;

const DescriptionContainer = styled.div`
  padding: 15px;
  & p {
    font-size: 14px;
    padding: 3px 0;
    color: #707070;
  }
  & div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ArticleName = styled.p`
  font-size: 15px;
  font-weight: 500;
  color: black !important;
  white-space: nowrap;
  overflow-x: hidden;
`;

const StockAlert = styled.p`
  position: absolute;
  top: ${(props) => (props.table ? "11px" : "-15px")};
  left: ${(props) => (props.table ? "65%" : null)};
  right: ${(props) => (props.table ? null : "-3px")};
  background: #fb5b5a;
  color: white !important;
  padding: 7px 10px;
  border-radius: 100px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
  margin-left: -90px;
`;

const TableRow = styled.div`
  position: relative;
  width: 100%;
  height: 55px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: #0e2c5e;
  &:last-child {
    border-bottom: 1px solid #e9eef5;
    box-sizing: border-box;
  }
  & > div {
    width: 7%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #e9eef5;
    border-left: 1px solid #e9eef5;
  }
  & figure {
    width: 40px;
    height: 40px;
    background: #dfe2e6;
    object-fit: contain;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    overflow: hidden;
  }
  & img {
    overflow: hidden;
    object-fit: contain;
  }
  :hover {
    background: #e9eef5;
  }
`;

function clickArticle(history, url, article, props) {
  history.push({
    pathname: `${url}/${article.id}`,
  });
  sessionStorage.setItem("articleSelected", JSON.stringify(article));
  props.setArticleData(article);
}

export function ArticleSquareView(props) {
  const article = props.article;
  const history = useHistory();
  let { url } = useRouteMatch();
  const nodeRef = React.useRef(null);

  const image = getImage(article.images[0], 270, 150);
  const { stock, stockAlert } = getArticleStock(article);
  const { currencySign, currency } = getArticleCurrency(article.currency);
  const priceFormatted = formatPrice(currencySign, article.price, currency);
  const stockFormatted = formatStock(
    stock,
    getUnitMeasure(article.unit_measure)
  );
  const [appear, setAppearState] = useState(false);

  useEffect(() => {
    setAppearState(true);
  }, []);

  return (
    <Transition nodeRef={nodeRef} in={appear} timeout={0}>
      {(state) => {
        return (
          <ArticleContainer
            ref={nodeRef}
            state={state}
            className="article"
            onClick={() => clickArticle(history, url, article, props)}
          >
            {stockAlert && <StockAlert>Stock bajo</StockAlert>}
            <ImageContainer
              onClick={() => clickArticle(history, url, article, props)}
            >
              <ReactImageFallback
                src={image}
                fallbackImage="/not-found.svg"
                alt="Product"
              />
            </ImageContainer>
            <DescriptionContainer>
              <ArticleName>{article.name}</ArticleName>
              <div>
                <p>{stockFormatted}</p>
                <p>{priceFormatted}</p>
              </div>
            </DescriptionContainer>
          </ArticleContainer>
        );
      }}
    </Transition>
  );
}

export function HeaderRow() {
  return (
    <TableRow key={"headerCell"}>
      <TableCell title="Imagen" width="7%"></TableCell>
      <TableCell title="Nombre de producto" width="27.5%"></TableCell>
      <TableCell title="SKU" width="12.5%"></TableCell>
      <TableCell title="Cantidad en bodegas" width="18%"></TableCell>
      <TableCell title="Precio" width="12.5%"></TableCell>
      <TableCell title="Fecha de registro" width="22.5%"></TableCell>
    </TableRow>
  );
}

export function ArticleTableView(props) {
  const history = useHistory();
  let { url } = useRouteMatch();
  const article = props.article;
  const image = getImage(article.images[0], 40, 40);
  const { stock, stockAlert } = getArticleStock(article);
  const { currencySign, currency } = getArticleCurrency(article.currency);
  const priceFormatted = formatPrice(currencySign, article.price, currency);
  const stockFormatted = formatStock(
    stock,
    getUnitMeasure(article.unit_measure)
  );

  return (
    <React.Fragment>
      <TableRow
        stockAlert={stockAlert}
        onClick={() => clickArticle(history, url, article, props)}
      >
        {stockAlert && <StockAlert table>Stock bajo</StockAlert>}
        <div>
          <figure>
            <ReactImageFallback
              src={image}
              fallbackImage="/not-found.svg"
              alt="Product"
            />
          </figure>
        </div>
        <TableCell title={article.name} width="27.5%"></TableCell>
        <TableCell title={article.sku} width="12.5%"></TableCell>
        <TableCell title={stockFormatted} width="18%"></TableCell>
        <TableCell title={priceFormatted} width="12.5%"></TableCell>
        <TableCell
          title={new Date(article.created_date).toLocaleString()}
          width="22.5%"
        ></TableCell>
      </TableRow>
    </React.Fragment>
  );
}
