import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import axios from "axios";

import CustomInput from "../CustomInput";
import { Loading, Completed } from "../GenericPopup";
import { listenerEnterForSteps } from "../_utils/helper";

const FullScreenContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  z-index: 1;
`;

const CenteredContainer = styled.div`
  position: fixed;
  overflow-y: scroll;
  max-height: 600px;
  width: 400px;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  background: white;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;

  ${(props) =>
    props.error === true &&
    css`
      animation-name: errorAlertFixed;
      animation-duration: 0.3s;
      animation-iteration-count: 3;
    `}
`;

const Title = styled.p`
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px; ;
`;

const CenterInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const EmptySpace = styled.div`
  width: 25px;
  flex-shrink: 0;
`;

const ContinueButton = styled.button`
  padding: 12px 25px 14px 25px;
  color: white;
  background: #323946;
  border-radius: 100px;
  font-weight: 500;
  margin-top: 50px;
  align-self: flex-start;
  outline: none;
  border: none;
`;

const Description = styled.p`
  font-size: 14px;
  color: #323946;
  font-weight: 300;
  max-width: 350px;
  text-align: center;
  margin-bottom: 15px;
`;

const AbortButton = styled.p`
  margin-top: 50px;
  margin-left: 30px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  padding: 15px;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

export default function WarehouseEdition(props) {
  const [stepSelected, setSectionSelected] = useState(1);
  const [status, setStatus] = useState(null);
  const data = { company_id: JSON.parse(sessionStorage.getItem("company")).id };
  const [error, setError] = useState(false);

  async function onSubmit(props) {
    props.event.preventDefault();
    props.changeSection(2);

    try {
      await axios({
        method: props.props.update ? "put" : "post",
        url: process.env.REACT_APP_WAREHOUSE_ENDPOINT,
        data: { ...props.data, warehouse_id: props.data.id },
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      props.props.setWarehouseData(props.data);
      sessionStorage.setItem("warehouseSelected", JSON.stringify(props.data));
      props.setStatus("SUCCESS");
      props.changeSection(3);
    } catch (error) {
      console.log(error);
      props.setStatus("ERROR");
      props.changeSection(3);
    }
  }

  useEffect(() => {
    setTimeout(() => setError(false), 1000);
  }, [error]);

  const idInputsRequired = [
    "warehouse_name",
    "warehouse_address",
    "warehouse_lat",
    "warehouse_lng",
  ];
  useEffect(() => {
    const listener = (event) => {
      listenerEnterForSteps({
        event: event,
        inputsRequired: idInputsRequired,
        actionSuccess: () =>
          onSubmit({
            event: event,
            changeSection: setSectionSelected,
            data: {
              ...props.data,
              name: document.getElementById("warehouse_name").value,
              address: document.getElementById("warehouse_address").value,
              latitude: document.getElementById("warehouse_lat").value,
              longitude: document.getElementById("warehouse_lng").value,
            },
            props: { ...props },
            setStatus: setStatus,
          }),
        actionFailure: () => setError(true),
      });
    };
    document.addEventListener("keydown", listener);
    return () => document.removeEventListener("keydown", listener);
  }, [data, props, idInputsRequired]);

  let render;
  switch (stepSelected) {
    case 1:
      render = (
        <FirstSection
          update={props.update}
          warehouseSelected={props.warehouseSelected}
          data={props.data}
          {...props}
          changeSection={setSectionSelected}
          setStatus={setStatus}
          onSubmit={onSubmit}
        ></FirstSection>
      );
      break;
    case 2:
      render = <Loading></Loading>;
      break;
    case 3:
      render = (
        <Completed
          type={status}
          message={
            status === "SUCCESS"
              ? "Bodega guardada exitosamente"
              : "Lo sentimos, hubo un error al guardar la bodega"
          }
          close={props.close}
        ></Completed>
      );
      break;
    default:
      break;
  }

  return (
    <FullScreenContainer
      id="fullscreen_container"
      onClick={(event) => {
        if (event.target !== document.getElementById("fullscreen_container")) {
          return;
        }
        props.close();
      }}
      children={render}
    ></FullScreenContainer>
  );
}

function FirstSection(props) {
  return (
    <CenteredContainer id="container" error={props.error}>
      <Title>{props.title}</Title>
      <Description>
        Las bodegas sirven para organizar tu inventario referenciando
        ubicaciones reales como sucursales o almacenes.
      </Description>
      <form
        onSubmit={async (event) => {
          props.onSubmit({
            event: event,
            changeSection: props.changeSection,
            data: {
              ...props.data,
              name: document.getElementById("warehouse_name").value,
              address: document.getElementById("warehouse_address").value,
              latitude: document.getElementById("warehouse_lat").value,
              longitude: document.getElementById("warehouse_lng").value,
            },
            props: { ...props },
            setStatus: props.setStatus,
          });
        }}
      >
        <CustomInput
          id="warehouse_name"
          placeholder="Nombre de la bodega"
          type="text"
          required={true}
          value={props.data.name}
        ></CustomInput>
        <CustomInput
          id="warehouse_address"
          placeholder="Dirección"
          type="text"
          required={true}
          value={props.data.address}
        ></CustomInput>
        <CenterInputsContainer>
          <CustomInput
            id="warehouse_lat"
            placeholder="Latitud"
            type="text"
            required={true}
            value={props.data.latitude}
          ></CustomInput>
          <EmptySpace></EmptySpace>
          <CustomInput
            id="warehouse_lng"
            placeholder="Longitud"
            type="text"
            required={true}
            value={props.data.longitude}
          ></CustomInput>
        </CenterInputsContainer>
        <ActionButtons>
          <ContinueButton type="submit">Continuar</ContinueButton>
          <AbortButton onClick={() => props.close()}>Abortar</AbortButton>
        </ActionButtons>
      </form>
    </CenteredContainer>
  );
}
