import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { isElementClicked, getUnitMeasure } from "../_utils/helper";
import { getArticleData } from "../_utils/data";
import { useHistory } from "react-router-dom";
import axios from "axios";

// components
import Menu from "../Menu";
import DeletePopup from "../DeletePopup";
import { Loading, Completed } from "../GenericPopup";
import PointOfSaleEdition from "./edition/PointsOfSaleEdition";

// icons
import bankTransfer from "../../assets/icons/bankTransfer.svg";
import cash from "../../assets/icons/cash.svg";
import creditCard from "../../assets/icons/creditCard.svg";
import voucher from "../../assets/icons/voucher.svg";
import imgDefault from "../../assets/img/default-profile-img.svg";

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  height: 115px;
  padding: 0 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
`;

const POSDetails = styled.div`
  width: 100%;
  height: 85%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  box-sizing: border-box;
`;

const Warehouse = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 65px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  height: 20px;
  margin-bottom: 15px;
  font-family: "Roboto";
  :last-child {
    margin-bottom: 65px;
  }
  .bold {
    font-weight: 500;
  }
`;

const Map = styled.figure`
  width: 95px;
  height: 95px;
  margin-bottom: 20px;
  > img {
    border-radius: 50%;
  }
`;

const SellersPayments = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  > div {
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
    > p:first-child {
      margin-bottom: 25px;
      font-weight: 500;
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 65px;
  border-width: 1px 1px 0;
  border-style: solid;
  border-color: #99a7bf;
  box-sizing: border-box;
  border-collapse: collapse;
  :last-child {
    border-width: 1px;
    border-collapse: collapse;
  }
  ${({ space }) =>
    space &&
    `
    justify-content: ${space};
  `}
`;

const Image = styled.figure`
  padding: 0 15px;
  width: 40px;
  height: 100%;
  border-right: 1px solid #99a7bf;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  border-collapse: collapse;
  ${({ profile }) =>
    profile &&
    `
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  `}
`;

const Articles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  > p:first-child {
    margin-bottom: 25px;
    font-weight: 500;
  }
`;

const MenuContainer = styled.figure`
  width: 30px;
  padding: 5px;
  cursor: pointer;
  position: relative;
  z-index: 1000;
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: ${(props) => props.width};
  border-right: ${(props) => (props.final ? "0px solid" : "1px solid #99A7BF")};
  & p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export default function PointsOfSale(props) {
  const [posSelected, setPOSSelected] = useState(
    props.data
      ? props.data
      : sessionStorage.getItem("posSelected")
      ? JSON.parse(sessionStorage.getItem("posSelected"))
      : []
  );
  const [menuActivated, setMenuState] = useState(false);
  const [editPOS, setEditPOS] = useState(false);
  const [deletePOS, setDeletePOS] = useState(false);
  const [loading, setLoading] = useState(false);
  const [completed, setProcessStatus] = useState(false);
  const [articles, setArticlesState] = useState([]);
  const history = useHistory();

  const menu = [
    {
      label: "Editar punto de venta",
      action: () => {
        setEditPOS(!editPOS);
      },
    },
    {
      label: "Eliminar punto de venta",
      action: () => {
        setDeletePOS(!deletePOS);
      },
    },
  ];

  const deletepos = async () => {
    setLoading(true);
    await axios({
      method: "delete",
      url: process.env.REACT_APP_POS_ENDPOINT,
      data: {
        pos_id: JSON.parse(sessionStorage.getItem("posSelected")).pos,
      },
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });

    setLoading(false);
    setDeletePOS(!deletePOS);
    setProcessStatus(true);
  };

  const clickOutsideToClose = useCallback((evt) => {
    if (!isElementClicked(evt, "menu")) {
      document.removeEventListener("click", clickOutsideToClose);
      setMenuState(false);
    }
  }, []);

  useEffect(() => {
    if (menuActivated) {
      document.addEventListener("click", clickOutsideToClose);
    }

    getArticleData().then((result) => setArticlesState(result));
  }, [menuActivated, clickOutsideToClose]);

  const setPaymentIcon = (id) => {
    switch (id) {
      case 1:
        return cash;
      case 2:
        return creditCard;
      case 3:
        return voucher;
      case 4:
        return bankTransfer;
      default:
        break;
    }
  };

  return (
    <MainContainer>
      {editPOS && (
        <PointOfSaleEdition
          setPOSSelected={setPOSSelected}
          close={() => setEditPOS(!editPOS)}
          update
          data={posSelected}
        />
      )}
      {deletePOS && (
        <DeletePopup
          callback={deletepos}
          title="Eliminar punto de venta"
          close={() => {
            setDeletePOS(!deletePOS);
          }}
          message={`Está a punto de eliminar este punto de venta, ¿Está seguro de querer continuar?. Esta acción no se puede revertir.`}
        />
      )}
      {loading && <Loading />}
      {completed && (
        <Completed
          type="SUCCESS"
          close={() => {
            setProcessStatus(false);
            history.push({
              pathname: process.env.REACT_APP_POSS_ROUTE,
            });
          }}
        />
      )}
      <Header>
        <MenuContainer onClick={() => setMenuState(!menuActivated)}>
          <img src="../menu.svg" alt="menu"></img>
          {menuActivated && (
            <Menu
              items={menu}
              close={() => setMenuState(!menuActivated)}
            ></Menu>
          )}
        </MenuContainer>
      </Header>
      <POSDetails>
        <Warehouse>
          <div>
            <Map>
              <img
                alt="Static mapbox map"
                src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+323946(${posSelected.warehouses.longitude},${posSelected.warehouses.latitude})/${posSelected.warehouses.longitude},${posSelected.warehouses.latitude},15,0.00,0.00/250x250@2x?access_token=pk.eyJ1IjoicmxlZ29ycmV0YSIsImEiOiJjaXYzZDBwY2YwMHprMnpxbm93aW5ubDkxIn0.XvwIpNBJK-ZS3VsiTRNv4w`}
              />
            </Map>
          </div>
          <Item>
            <p className="bold">{posSelected.warehouses.name}</p>
          </Item>
          <Item>
            <p>{posSelected.warehouses.address}</p>
          </Item>
        </Warehouse>

        <SellersPayments>
          <div>
            <p>Vendedores</p>
            {posSelected.sellers.map((e) => (
              <Row key={`seller${e.id}`}>
                <Image profile>
                  <img src={e.image ? e.image : imgDefault} alt={e.fullname} />
                </Image>
                {e.fullname}
              </Row>
            ))}
          </div>
          <div>
            <p>Métodos de pago</p>
            {posSelected.payments.map((e) => (
              <Row key={`payment${e.id}`}>
                <Image>
                  <img src={setPaymentIcon(e.id)} alt={e.name} />
                </Image>
                {e.name}
              </Row>
            ))}
          </div>
        </SellersPayments>

        <Articles>
          <p>Artículos disponibles</p>
          {posSelected.articles.map((e) => (
            <Row space={"space-between"} key={`article${e.id}`}>
              <Cell width="30%">
                <Image profile>
                  <img src={imgDefault} alt={e.name} />
                </Image>
                <p>{e.name}</p>
              </Cell>
              <Cell width="37%">
                <p>{articles.filter((el) => el.id === e.id)[0]?.notes}</p>
              </Cell>
              <Cell width="14%">
                <p>
                  {getUnitMeasure(
                    articles.filter((el) => el.id === e.id)[0]?.unit_measure
                  )}
                </p>
              </Cell>
              <Cell width="16%" final>
                <p>
                  $ {parseFloat(e.price).toFixed(2)}{" "}
                  {articles.filter((el) => el.id === e.id)[0]?.currency}
                </p>
              </Cell>
            </Row>
          ))}
        </Articles>
      </POSDetails>
    </MainContainer>
  );
}
