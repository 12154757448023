import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ContinueButton } from "./styles";

const ItemGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  margin-left: 10px;
  margin-bottom: 15px;
  box-sizing: border-box;
  color: #323946;
`;

const Checkbox = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 16px;
  border: ${(props) => (props.selected ? "" : "solid 2px #323946")};
  background-color: ${(props) => (props.selected ? "#5590ff" : "#FFF")};
  border-radius: 5px;
  box-sizing: border-box;
`;

export default (props) => {
  const [paymentList, setPaymentList] = useState([
    {
      id: 1,
      name: "Efectivo",
      selected: false,
    },
    {
      id: 2,
      name: "Tarjetas de debito/crédito",
      selected: false,
    },
    {
      id: 3,
      name: "Vales de despensa",
      selected: false,
    },
    {
      id: 4,
      name: "Transferencia bancaria",
      selected: false,
    },
  ]);

  useEffect(() => {
    let payments = JSON.parse(sessionStorage.getItem("dataPOS")).payments;
    if (payments.length > 0) {
      let temp = paymentList.slice();
      payments.forEach((element) => {
        temp[element.id - 1].selected = !temp[element.id - 1].selected;
      });
      setPaymentList(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let temp = JSON.parse(sessionStorage.getItem("dataPOS"));
    temp.payments = paymentList.filter((element) => element.selected);
    sessionStorage.setItem("dataPOS", JSON.stringify(temp));
  }, [paymentList, props]);

  return (
    <React.Fragment>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          props.callback();
        }}
      >
        {paymentList.map((e, i) => {
          return (
            <ItemGroup
              key={i}
              onClick={(event) => {
                event.preventDefault();
                let a = paymentList.slice();
                a[i].selected = !a[i].selected;
                setPaymentList(a);
              }}
            >
              <Checkbox selected={e.selected} />
              <p>{e.name}</p>
            </ItemGroup>
          );
        })}
        <ContinueButton type="submit">Finalizar</ContinueButton>
      </form>
    </React.Fragment>
  );
};
