import React, { useEffect, useState, useCallback } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ReactImageFallback from "react-image-fallback";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import axios from "axios";
import { useHistory } from "react-router-dom";

// Components
import ArticleEdition from "./edition/ArticleEdition";
import ArticleEditionComposite from "./edition/ArticleEditionComposite";
import DeletePopup from "../DeletePopup";
import { Loading, Completed } from "../GenericPopup";
import TableCell from "../SmartInputs/TableCell.js";
import Menu from "../Menu";

// Helpers
import {
  getImage,
  isElementClicked,
  getArticleCurrency,
  getUnitMeasure,
} from "../_utils/helper";
import { formatPrice } from "./helper";
import { getArticleData } from "../_utils/data";

// Styles
import { MenuContainer } from "../styles";

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 115px;
  align-items: center;
  justify-content: flex-end;
  padding: 0 40px;
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
`;

const ArticleContainer = styled.div`
  padding: 20px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  overflow-y: auto;
`;

const DescriptionTitle = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: #707070;
`;

const DescriptionValue = styled.p`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 50px;
`;

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  & > div {
    margin-right: 100px;
  }
`;

const WarehousesImageContainer = styled.div`
  width: 100%;
  & img {
    width: 100%;
    display: block;
    border-bottom: 100px solid rgba(1, 1, 1, 0); /* This border generates an empty space at the bottom of the article */
  }
`;

const LeftSection = styled.div`
  width: 45%;
  padding-right: 70px;
  box-sizing: border-box;
`;

const RightSection = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
`;

const StockTable = styled.div`
  margin: 5px 0px 20px 0px;
`;

const TableHeader = styled.div`
  border-bottom: 1px solid #e9eef5;
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  & p {
    color: ${(props) => (props.stockAlert ? "#FB5B5A" : null)};
    font-family: ${(props) => (props.stockAlert ? "Roboto" : null)};
    font-weight: ${(props) => (props.header ? 500 : 400)};
  }
`;

const StockAlert = styled.p`
  position: absolute;
  top: 13px;
  right: -50px;
  background: #fb5b5a;
  color: white !important;
  padding: 5px 8px;
  border-radius: 100px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
`;

const CompositeTable = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export default function Article(props) {
  const savedArticle = JSON.parse(sessionStorage.getItem("articleSelected"));

  const [loading, setLoading] = useState(false);
  const [completed, setProcessStatus] = useState(false);
  const [menuActivated, setMenuState] = useState(false);
  const [editArticleActivated, setEditArticleStatus] = useState(false);
  const [articleModified, setArticleModifiedStatus] = useState(false);
  const [deleteArticleActivated, setDeleteArticleStatus] = useState(false);
  const articleSelected = JSON.parse(
    sessionStorage.getItem("articleSelected")
  )?.id;
  const [articleData, setArticleData] = useState(
    savedArticle
      ? savedArticle
      : props.data
      ? props.data
      : { images: [], stock: [] }
  );
  const history = useHistory();
  const [articlesRelated, setArticlesRelated] = useState([]);
  const [flag, setFlag] = useState(true);

  const clickOutsideToClose = useCallback((evt) => {
    if (!isElementClicked(evt, "menu")) {
      document.removeEventListener("click", clickOutsideToClose);
      setMenuState(false);
    }
  }, []);

  useEffect(() => {
    if (menuActivated) {
      document.addEventListener("click", clickOutsideToClose);
    }

    if (flag) {
      let temp = [];
      if (articleData.related_articles.length) {
        getArticleData().then((articles) => {
          for (let i = 0; i < articleData.related_articles.length; i++) {
            const articleRelated = articles.find(
              (element) =>
                element.id ===
                articleData.related_articles[i].related_article_id
            );
            if (articleRelated !== undefined) {
              temp.push({
                id: articleData.related_articles[i].related_article_id,
                name: articleRelated.name,
                unit_measure: getUnitMeasure(articleRelated.unit_measure),
                qty: parseFloat(articleData.related_articles[i].quantity),
              });
            }
          }

          setArticlesRelated(temp);
        });
      }
      setFlag(false);
    }

    if (articleModified) {
      getArticleData({
        query: `?article_id=${articleSelected}`,
      }).then((response) => {
        const articleUpdated = response[0];
        if (articleUpdated !== undefined) {
          setArticleData(articleUpdated);
          setArticleModifiedStatus(false);

          let temp = [];
          if (articleUpdated.related_articles.length) {
            getArticleData().then((articles) => {
              for (let i = 0; i < articleUpdated.related_articles.length; i++) {
                const articleToSearch = articleUpdated.related_articles[i];
                const articleRelated = articles.filter(
                  (element) => element.id === articleToSearch.related_article_id
                )[0];
                if (articleRelated) {
                  temp.push({
                    id: articleUpdated.related_articles[i].related_article_id,
                    name: articleRelated.name,
                    unit_measure: getUnitMeasure(articleRelated.unit_measure),
                    qty: parseFloat(
                      articleUpdated.related_articles[i].quantity
                    ),
                  });
                }
              }
              setArticlesRelated(temp);
            });
          }
        }
      });
    }
  }, [
    articleModified,
    articleSelected,
    props.data,
    menuActivated,
    clickOutsideToClose,
    flag,
    articleData.related_articles,
  ]);

  const menu = [
    {
      label: "Editar artículo",
      action: () => {
        setEditArticleStatus(!editArticleActivated);
      },
    },
    {
      label: "Eliminar artículo",
      action: () => {
        setDeleteArticleStatus(!deleteArticleActivated);
      },
    },
  ];

  const images = [];
  if (articleData.images.length === 0) {
    images.push(
      <div>
        <img src="/addNew.svg" alt={"Not uploaded"} />
      </div>
    );
  } else {
    for (let i = 0; i < articleData.images.length; i++) {
      const image = getImage(articleData.images[i], 600, 350);
      images.push(
        <ReactImageFallback
          src={image}
          fallbackImage="/not-found.svg"
          alt="Product"
        />
      );
    }
  }

  let stock;
  try {
    stock = articleData.stock
      .map((element) => {
        return parseFloat(element.stock);
      })
      .reduce((a, b) => a + b);
  } catch (err) {
    stock = "0";
  }

  let stockMarkers = "";
  const warehouseStockDetails = [];
  for (let i = 0; i < articleData.stock.length; i++) {
    stockMarkers += `pin-l-${i + 1}+323946(${articleData.stock[i].longitude},${
      articleData.stock[i].latitude
    })`;

    if (i !== articleData.stock.length - 1) {
      stockMarkers += ",";
    }

    warehouseStockDetails.push(
      <TableRow
        stockAlert={
          typeof articleData.minimum_amount === "number" &&
          articleData.minimum_amount > articleData.stock[i].stock
            ? true
            : false
        }
      >
        {typeof articleData.minimum_amount === "number" &&
          articleData.minimum_amount > articleData.stock[i].stock && (
            <StockAlert>Stock bajo</StockAlert>
          )}
        <TableCell title={i + 1} width="20%"></TableCell>
        <TableCell title={articleData.stock[i].name} width="55%"></TableCell>
        <TableCell
          title={parseFloat(articleData.stock[i].stock)}
          width="25%"
        ></TableCell>
      </TableRow>
    );
  }

  const deleteArticle = async () => {
    setLoading(true);
    await axios({
      method: "delete",
      url: process.env.REACT_APP_ARTICLE_ENDPOINT,
      data: {
        article_id: articleSelected,
      },
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });

    setLoading(false);
    setDeleteArticleStatus(!deleteArticleActivated);
    setProcessStatus(true);
  };

  let unitMeasure = getUnitMeasure(articleData.unit_measure);
  const { currencySign, currency } = getArticleCurrency(articleData.currency);
  const price = formatPrice(currencySign, articleData.price, currency);
  const cost = formatPrice(currencySign, articleData.cost, currency);

  return (
    <MainContainer>
      {deleteArticleActivated && (
        <DeletePopup
          callback={deleteArticle}
          title="Eliminar artículo"
          close={() => {
            setDeleteArticleStatus(!deleteArticleActivated);
          }}
          message={`Está a punto de eliminar el artículo ${articleData.name}, ¿está seguro de querer continuar?. Esta acción no se puede revertir.`}
        ></DeletePopup>
      )}
      {editArticleActivated ? (
        articleData.type === "COMPOSITE" || articleData.type === "FICTIONAL" ? (
          <ArticleEditionComposite
            update={true}
            articleSelected={articleSelected}
            title="Edición de artículo"
            close={() => {
              setEditArticleStatus(!editArticleActivated);
            }}
            callback={() => setArticleModifiedStatus(true)}
            data={articleData}
          ></ArticleEditionComposite>
        ) : (
          <ArticleEdition
            update={true}
            articleSelected={articleSelected}
            title="Edición de artículo"
            close={() => {
              setEditArticleStatus(!editArticleActivated);
            }}
            callback={() => setArticleModifiedStatus(true)}
            data={articleData}
          ></ArticleEdition>
        )
      ) : null}
      {loading && <Loading />}
      {completed && (
        <Completed
          type="SUCCESS"
          close={() => {
            setProcessStatus(false);
            history.push({
              pathname: process.env.REACT_APP_ARTICLES_ROUTE,
            });
          }}
        />
      )}
      <HeaderContainer>
        <MenuContainer onClick={() => setMenuState(!menuActivated)}>
          <img src="../menu.svg" alt="menu"></img>
          {menuActivated && (
            <Menu
              items={menu}
              close={() => setMenuState(!menuActivated)}
            ></Menu>
          )}
        </MenuContainer>
      </HeaderContainer>
      <ArticleContainer>
        <LeftSection>
          <CenteredContainer>
            <div>
              <DescriptionTitle>Nombre</DescriptionTitle>
              <DescriptionValue>{articleData.name}</DescriptionValue>
            </div>
            <div>
              <DescriptionTitle>SKU</DescriptionTitle>
              <DescriptionValue>{articleData.sku}</DescriptionValue>
            </div>
          </CenteredContainer>
          {articleData.type !== "FICTIONAL" && (
            <>
              <CenteredContainer>
                <div>
                  <DescriptionTitle>Cantidad</DescriptionTitle>
                  <DescriptionValue>{stock}</DescriptionValue>
                </div>
                <div>
                  <DescriptionTitle>Unidad de medida</DescriptionTitle>
                  <DescriptionValue>{unitMeasure}</DescriptionValue>
                </div>
              </CenteredContainer>
              <DescriptionTitle>Stock mínimo (por bodega)</DescriptionTitle>
              <DescriptionValue>
                {articleData.minimum_amount || articleData.minimum_amount === 0
                  ? articleData.minimum_amount
                  : "---"}
              </DescriptionValue>
            </>
          )}
          <CenteredContainer>
            <div>
              <DescriptionTitle>Precio de venta</DescriptionTitle>
              <DescriptionValue>{price}</DescriptionValue>
            </div>
            <div>
              <DescriptionTitle>Precio de compra</DescriptionTitle>
              <DescriptionValue>{cost}</DescriptionValue>
            </div>
          </CenteredContainer>
          <DescriptionTitle>Notas</DescriptionTitle>
          <DescriptionValue>{articleData.notes}</DescriptionValue>
          {articleData.type !== "FICTIONAL" && (
            <>
              <DescriptionTitle>Bodegas</DescriptionTitle>
              <WarehousesImageContainer>
                {articleData.stock.length ? (
                  <div>
                    <StockTable>
                      <TableHeader>
                        <TableRow header>
                          <TableCell
                            title="ID de Bodega"
                            width="20%"
                          ></TableCell>
                          <TableCell
                            title="Nombre de Bodega"
                            width="55%"
                          ></TableCell>
                          <TableCell title="Existencia" width="25%"></TableCell>
                        </TableRow>
                        {warehouseStockDetails}
                      </TableHeader>
                    </StockTable>
                    <img
                      alt="Static mapbox map"
                      src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/${stockMarkers}/auto/500x500@2x?access_token=pk.eyJ1IjoicmxlZ29ycmV0YSIsImEiOiJjaXYzZDBwY2YwMHprMnpxbm93aW5ubDkxIn0.XvwIpNBJK-ZS3VsiTRNv4w`}
                    />
                  </div>
                ) : null}
              </WarehousesImageContainer>
            </>
          )}
        </LeftSection>
        <RightSection>
          <Carousel
            id="ca"
            showArrows={true}
            showIndicators={true}
            showThumbs={false}
            showStatus={true}
            swipeable={true}
            useKeyboardArrows={true}
            infiniteLoop={true}
            autoPlay={true}
            emulateTouch={true}
          >
            {images}
          </Carousel>
          {articleData.related_articles.length ? (
            <CompositeTable>
              <TableHeader>
                <TableRow header>
                  {/* <TableCell title="ID" width="10%"></TableCell> */}
                  <TableCell title="Nombre de artículo" width="65%"></TableCell>
                  <TableCell title="Unidad de medida" width="20%"></TableCell>
                  <TableCell title="Cantidad de salida" width="15%"></TableCell>
                </TableRow>
                {articlesRelated.map((e) => (
                  <TableRow>
                    <TableCell title={e.name} width="65%"></TableCell>
                    <TableCell title={e.unit_measure} width="20%"></TableCell>
                    <TableCell
                      title={parseFloat(e.qty)}
                      width="15%"
                    ></TableCell>
                  </TableRow>
                ))}
              </TableHeader>
            </CompositeTable>
          ) : null}
        </RightSection>
      </ArticleContainer>
    </MainContainer>
  );
}
