import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const MainContainer = styled.div`
  align-self: center;
  flex-grow: 1;
`;

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 260px;
  margin: 30px auto;
`;

const Button = styled.button`
  background: #323946;
  color: white;
  border-radius: 5px;
  text-align: center;
  padding: 10px 20px;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto;
`;

const UserDescription = styled.p`
  font-size: 15px;
  color: #323946;
  font-weight: 500;
  text-align: center;
  max-width: 400px;
  margin: 30px auto;
`;

export default function NotFound(props) {
  if (props.type === "LOGGED_OUT") {
    return (
      <MainContainer>
        <ImageContainer>
          <img src="/not-found.svg" alt="Not Found"></img>
        </ImageContainer>
        <UserDescription>
          La página que estás buscando no existe.
        </UserDescription>
        <CenteredContainer>
          <StyledLink to="/login">
            <Button>Inicia sesión</Button>
          </StyledLink>
          <StyledLink to="/register">
            <Button>Regístrate</Button>
          </StyledLink>
        </CenteredContainer>
      </MainContainer>
    );
  } else if (!JSON.parse(sessionStorage.getItem("auth"))) {
    window.location.reload();
  } else {
    return (
      <MainContainer>
        <ImageContainer>
          <img src="/not-found.svg" alt="Not Found"></img>
        </ImageContainer>
        <UserDescription>
          La página que estás buscando no existe, puedes utilizar el menú
          lateral para seguir navegando.
        </UserDescription>
      </MainContainer>
    );
  }
}
