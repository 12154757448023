import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

// Components
import StepsContainer from "../../StepsContainer";
import EditBasicInformation from "./EditBasicInformation";
import EditImages from "./EditImages";
import { Loading, Completed } from "../../GenericPopup";

export default function ArticleEdition(props) {
  const [stepSelected, setSectionSelected] = useState(1);
  const [status, setStatus] = useState(null);
  const [articleData, setArticleDataState] = useState({
    company_id: JSON.parse(sessionStorage.getItem("company")).id,
    name: null,
    cost: null,
    price: null,
    unit_measure: null,
    currency: null,
    minimum_amount: null,
    notes: null,
    files: [],
    type: "BASIC",
    sku: null,
    ...props.data,
  });

  useEffect(() => {
    if (props.update) {
      setArticleDataState({
        ...articleData,
        ...JSON.parse(sessionStorage.getItem("articleSelected")),
      });
    }
    // eslint-disable-next-line
  }, [props.update]);

  const sendInformation = useCallback(async () => {
    try {
      await axios({
        method: props.update ? "put" : "post",
        url: process.env.REACT_APP_ARTICLE_ENDPOINT,
        data: {
          ...articleData,
          article_id: props.articleSelected,
        },
        headers: {
          Authorization: sessionStorage.getItem("jwt"),
        },
      });
      if (props.callback) {
        props.callback();
      }

      setStatus("SUCCESS");
      setSectionSelected(4);
    } catch (err) {
      setStatus("ERROR");
      setSectionSelected(4);
    }
  }, [articleData, props]);

  const nameSteps = ["Articulo", "Imagenes"];

  let render, title;
  switch (stepSelected) {
    case 1:
      title = "Nuevo artículo";
      render = (
        <EditBasicInformation
          {...props}
          data={articleData}
          onSubmitFirstStep={() => onSubmitFirstStep()}
          callback={() => setSectionSelected(2)}
        ></EditBasicInformation>
      );
      break;
    case 2:
      title = "Imágenes del artículo";
      render = (
        <EditImages
          {...props}
          saveFiles={setArticleDataState}
          data={articleData}
          callback={() => {
            setSectionSelected(3);
            sendInformation();
          }}
        ></EditImages>
      );
      break;
    case 3:
      render = <Loading></Loading>;
      break;
    case 4:
      render = (
        <Completed
          type={status}
          message={
            status === "SUCCESS"
              ? "Artículo guardado exitosamente"
              : "Lo sentimos, hubo un error al guardar el artículo"
          }
          close={props.close}
        ></Completed>
      );
      break;
    default:
      break;
  }

  const onSubmitFirstStep = useCallback(() => {
    const data = {
      name: document.getElementById("article_name").value,
      sku: document.getElementById("article_sku").value,
      minimum_amount: document.getElementById("article_min_amount").value || 0,
      unit_measure: document.getElementById("article_unit_type").value,
      currency: document.getElementById("article_currency_type").value,
      cost: document.getElementById("article_price_buy").value,
      price: document.getElementById("article_price_sell").value,
      notes: document.getElementById("article_notes").value,
    };
    setArticleDataState({ ...articleData, ...data });
  }, [articleData]);

  const idInputsRequired = [["article_name", "article_sku"]];

  // métodos que se ejecutarán al dar clic en un NavStep o presionar enter
  // NOTA: al método que envía la información añadir clave "ignoreForStep: true"
  const stepFunctions = [
    { step: 1, action: onSubmitFirstStep },
    { step: 2, action: sendInformation, ignoreForStep: true },
  ];

  return (
    <StepsContainer
      close={props.close}
      stepSelected={stepSelected}
      limitStep={2}
      title={title}
      nameSteps={nameSteps}
      setSectionSelected={setSectionSelected}
      stepFunctions={stepFunctions}
      inputsRequired={idInputsRequired[stepSelected - 1]}
      render={render}
    ></StepsContainer>
  );
}
