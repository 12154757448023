import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { validateInputValues } from "./_utils/helper";

const ContainerSteps = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 0 40px;
  font-family: "Roboto";
`;

const CirculeStep = styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  border-radius: 13px;
  background-color: ${(props) => (props.selected ? "#5590ff" : "#dbdbdb")};
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 10;
  > div:first-child {
    display: flex;
    flex-direction: row;
  }
`;

const ContainerStep = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 400px;
`;

const NameStep = styled.p`
  position: absolute;
  text-align: center;
  left: ${(props) => (props.left ? props.left + "px" : "0")};
  top: ${(props) => (props.top ? props.top + "px" : "0")};
  margin: 0 5px;
  color: #5590ff;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 13px;
`;

const SVG = styled.svg`
  width: 100%;
  height: 5px;
  margin-top: 8px;
  > polyline {
    fill: none;
    stroke-width: 3;
    stroke-linecap: round;
    stroke: #dbdbdb;
  }
`;

export default function NavSteps(props) {
  const [namesSteps, setNamesSteps] = useState([]);
  const [pointsPolygon, setPoints] = useState("");
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    let temp = [];
    if (props.nameSteps !== undefined) {
      for (let i = 0; i < props.nameSteps.length; i++) {
        if (i === props.stepSelected - 1) {
          temp.push({ name: props.nameSteps[i], isActive: true });
        } else {
          temp.push({ name: props.nameSteps[i], isActive: false });
        }
      }
    }
    setNamesSteps(temp);
  }, [props.stepSelected, props.nameSteps]);

  useEffect(() => {
    // set points to back line steps
    let temp = "";
    let element = document.getElementById("step0");
    if (element) {
      let posX =
        element.offsetLeft -
        element.scrollLeft +
        element.clientLeft +
        element.clientWidth / 2;
      let posY = element.offsetTop - element.scrollTop + element.clientTop;
      temp = `${posX},${posY}`;
    }
    let lastStep = namesSteps.length - 1;
    element = document.getElementById(`step${lastStep}`);
    if (element) {
      let posX =
        element.offsetLeft -
        element.scrollLeft +
        element.clientLeft +
        element.clientWidth / 2;
      let posY = element.offsetTop - element.scrollTop + element.clientTop;
      temp += ` ${posX},${posY}`;
    }
    setPoints(temp);
    // set location to name step
    let stepSelected = props.stepSelected - 1;
    element = document.getElementById(`step${stepSelected}`);
    let elementNameStep = document.getElementById(`nameStep${stepSelected}`);
    if (element && elementNameStep) {
      setLeft(
        element.offsetLeft -
          element.scrollLeft +
          element.clientLeft +
          element.clientWidth / 2 -
          elementNameStep.clientWidth / 2
      );
      setTop(
        element.offsetTop -
          element.scrollTop +
          element.clientTop +
          element.clientHeight +
          3
      );
    }
  }, [namesSteps, props.stepSelected]);

  function changeStep(newStep) {
    if (newStep !== props.stepSelected) {
      let action = props.stepFunctions.filter(
        (e) => e.step === props.stepSelected
      )[0];
      if (action !== undefined) {
        let inputsValues = [];
        if (props.inputsRequired !== undefined)
          for (let i = 0; i < props.inputsRequired.length; i++) {
            inputsValues.push(
              document.getElementById(props.inputsRequired[i]).value
            );
          }
        if (validateInputValues(inputsValues).length === 0) {
          !action.ignoreForStep && action.action();
          props.setSectionSelected(newStep);
        } else props.setError(true);
      } else props.setSectionSelected(newStep);
    }
  }

  return (
    <ContainerSteps id="parentContainer" elements={namesSteps.length}>
      {namesSteps.length !== 0 && (
        <>
          <SVG>
            <polyline points={pointsPolygon}></polyline>
          </SVG>
          <ContainerStep>
            {namesSteps.map((e, i) => (
              <React.Fragment key={i}>
                <Step
                  id={`step` + i}
                  selected={e.isActive}
                  onClick={() => changeStep(i + 1)}
                >
                  <div>
                    <CirculeStep selected={e.isActive} />
                  </div>
                </Step>
                {e.isActive && (
                  <NameStep id={`nameStep` + i} left={left} top={top}>
                    {e.name}
                  </NameStep>
                )}
              </React.Fragment>
            ))}
          </ContainerStep>
        </>
      )}
    </ContainerSteps>
  );
}
