import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import axios from "axios";
import { listenerEnterForSteps } from "./_utils/helper";

import CustomInput from "./CustomInput";
import { Loading, Completed } from "./GenericPopup";
// import Dropzone from './Articles/_DropZone'

const FullScreenContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  z-index: 1;
`;

const CenteredContainer = styled.div`
  position: fixed;
  overflow-y: auto;
  max-height: 600px;
  width: 400px;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  background: white;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;

  ${(props) =>
    props.error === true &&
    css`
      animation-name: errorAlertFixed;
      animation-duration: 0.3s;
      animation-iteration-count: 3;
    `}
`;

const Title = styled.p`
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px; ;
`;

const ContinueButton = styled.button`
  padding: 12px 25px 14px 25px;
  color: white;
  background: #323946;
  border-radius: 100px;
  font-weight: 500;
  margin-top: 50px;
  align-self: flex-start;
  outline: none;
  border: none;
`;

const AbortButton = styled.p`
  margin-top: 50px;
  margin-left: 30px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  padding: 15px;
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

export default function EditProfile(props) {
  const [stepSelected, setSectionSelected] = useState(1);
  const [status, setStatus] = useState(null);
  // const [profilePicture, setProfilePictureState] = useState(null);
  const [error, setError] = useState(false);

  let render;
  switch (stepSelected) {
    case 1:
      render = (
        <FirstSection
          data={props.data}
          {...props}
          changeSection={setSectionSelected}
          setStatus={setStatus}
          success={props.success}
          error={error}
        ></FirstSection>
      );
      break;
    case 2:
      render = <Loading close={props.close}></Loading>;
      break;
    case 3:
      render = (
        <Completed
          type={status}
          message={
            status === "SUCCESS"
              ? "Tu perfil se ha actualizado con éxito"
              : "Lo sentimos, hubo un error al actualizar tu perfil"
          }
          close={props.close}
        ></Completed>
      );
      break;
    default:
      break;
  }

  useEffect(() => {
    setTimeout(() => setError(false), 1000);
  }, [error]);

  const idInputsRequired = ["fullname", "job_title"];
  useEffect(() => {
    const listener = (event) => {
      listenerEnterForSteps({
        event: event,
        inputsRequired: idInputsRequired,
        actionSuccess: () =>
          onSubmit({
            props: { ...props },
            data: {
              fullname: document.getElementById("fullname").value,
              job_title: document.getElementById("job_title").value,
              phone_number: document.getElementById("phone_number").value,
            },
            changeSection: setSectionSelected,
            setStatus: setStatus,
            success: props.success,
          }),
        actionFailure: () => setError(true),
      });
    };
    document.addEventListener("keydown", listener);
    return () => document.removeEventListener("keydown", listener);
  }, [props, idInputsRequired]);

  return (
    <FullScreenContainer
      id="fullscreen_container"
      onClick={(event) => {
        if (event.target !== document.getElementById("fullscreen_container")) {
          return;
        }
        props.close();
      }}
      children={render}
    ></FullScreenContainer>
  );
}

const onSubmit = async (props) => {
  props.changeSection(2);
  try {
    await axios({
      method: "put",
      url: process.env.REACT_APP_USER_ENDPOINT,
      data: props.data,
      headers: {
        Authorization: sessionStorage.getItem("jwt"),
      },
    });
    props.setStatus("SUCCESS");
    props.changeSection(3);
    props.success();
  } catch (error) {
    props.setStatus("ERROR");
    props.changeSection(3);
  }
};

const FirstSection = (props) => {
  return (
    <CenteredContainer id="container" error={props.error}>
      <Title>{props.title}</Title>
      {/* <Dropzone saveFiles={props.saveFiles} data={props.data}></Dropzone> */}
      <CustomInput
        id="fullname"
        placeholder="Nombre completo"
        type="text"
        required={true}
        value={props.data.fullname}
      ></CustomInput>
      <CustomInput
        id="job_title"
        placeholder="Puesto"
        type="text"
        value={props.data.job_title}
      ></CustomInput>
      <CustomInput
        id="phone_number"
        placeholder="Teléfono"
        type="text"
        required={false}
        value={props.data.phone_number}
      ></CustomInput>
      <ActionButtons>
        <ContinueButton
          onClick={async () => {
            onSubmit({
              props: { ...props },
              data: {
                fullname: document.getElementById("fullname").value,
                job_title: document.getElementById("job_title").value,
                phone_number: document.getElementById("phone_number").value,
              },
              changeSection: props.changeSection,
              setStatus: props.setStatus,
              success: props.success,
            });
          }}
        >
          Guardar
        </ContinueButton>
        <AbortButton onClick={() => props.close()}>Abortar</AbortButton>
      </ActionButtons>
    </CenteredContainer>
  );
};
