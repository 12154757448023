import React from "react";
import Dropzone from "./_DropZone";

import { ContinueButton } from "./styles";

export default (props) => {
  return (
    <React.Fragment>
      <Dropzone
        saveFiles={props.saveFiles}
        data={props.data}
        {...props}
      ></Dropzone>
      <ContinueButton
        onClick={() => {
          props.callback();
        }}
      >
        Continuar
      </ContinueButton>
    </React.Fragment>
  );
};
